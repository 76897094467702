import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, razorPayKey } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  home: any[];
  section_1: any[];
  section_2: any;
  section_3: any;
  section_4: any;
  section_5: any;
  section_6: any;
  section_7: any;
  neetBscData: any;
  openDialog: boolean;
  paymentType: string;
  batch: string;
  batchList: any;
  showError: boolean;
  enrolled: boolean;
  enrollData: any;
  courseId: string;
  enrolledId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NeetBscController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  homeGetApiCallId: string = "";
  neetBscGetApiCallId: string = "";
  enrollNowApiCallId: string = "";
  updatePaymentStatusApiCallId: string = "";
  home:any[];
  section_1: any[];
  section_2: any;
  section_3: any;
  section_4: any;
  section_5: any;
  section_6: any;
  section_7: any;
  neetBscData: any;
  openDialog: boolean;
  paymentType: string;
  batch: string;
  batchList: any;
  showError: boolean;
  paymentData: any;
  orderId: null;
  enrollData: any;
  courseId: string;
  enrolled: boolean;
  enrolledId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      home: [],
      section_1: [],
      section_2: {},
      section_3: {},
      section_4: {},
      section_5: {},
      section_6: {},
      section_7: {},
      neetBscData: {},
      openDialog: false,
      paymentType: '',
      batch: '',
      batchList: [],
      showError: false,
      enrollData: {},
      courseId: '',
      enrolled: true,
      enrolledId: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.homeGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
          
            //On User Profile Success
            this.section_1 = responseJson?.data?.attributes?.section1?.data;
            this.section_2 = responseJson?.data?.attributes?.section2?.data;
            this.section_3 = responseJson?.data?.attributes?.section3?.data;
            this.section_4 = responseJson?.data?.attributes?.section4?.data;
            this.section_5 = responseJson?.data?.attributes?.section5?.data;
            this.section_6 = responseJson?.data?.attributes?.section6?.data;
            this.section_7 = responseJson?.data?.attributes?.section7;
            this.setState({
              home: this.home,
              section_1: this.section_1,
              section_2: this.section_2,
              section_3: this.section_3,
              section_4: this.section_4,
              section_5: this.section_5,
              section_6: this.section_6,
              section_7: this.section_7,
            });
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.neetBscGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.neetBscData = responseJson.data;
            this.courseId = responseJson.data.id;
            this.batchList = responseJson?.data?.attributes?.batches?.data;
            const EnrolledAccounts = responseJson.data?.attributes?.enrolled_accounts;
            const loginId = localStorage.getItem('loginId')
            if(EnrolledAccounts) {
              EnrolledAccounts.find((ele:any, ind:number) => {
                if(ele == loginId) {
                  this.enrolledId = ele
                }
              })
            }
            this.setState({
              home: this.home,
              neetBscData: this.neetBscData,
              courseId: this.courseId,
              enrolledId: this.enrolledId,
              batchList: this.batchList,
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.enrollNowApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.enrollData = responseJson.data;
            this.enrolledId = responseJson.data?.id
            this.props.showToast({type : 'success', message : 'Enrolled Successfully'})
            this.setState({openDialog: false, enrollData: this.enrollData, enrolled: true, enrolledId: this.enrolledId})
            this.paymentData = responseJson?.data?.attributes?.order?.data?.attributes;
            this.orderId = responseJson?.data?.attributes?.order?.data?.id;
            localStorage.setItem('courseId', this.enrollData.id)
            this.displayRazorpay(this.paymentData)
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.updatePaymentStatusApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success
            this.props.showToast({type : 'success', message : 'Paid Successfully'})
            this.props.history.push('/my-courses')
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Payment failed.'});
              this.props.history.push('/')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    } 
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getHome() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.homeGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetHome
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetHome,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetHome
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getNeetBsc() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.neetBscGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetNeetBsc
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetNeetBsc,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetNeetBsc
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  enrollNow() {
    if(this.state.batchList && this.state.batchList.length>0) {
      if(!this.state.batch){
        this.setState({showError: true, enrolledId: this.enrolledId})
        return
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.enrollNowApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiEnrollNow
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiEnrollNow,
      token: localStorage.getItem('authToken')
    };
    let enroll_course: any;
    if(this.state.batchList && this.state.batchList.length>0) {
      enroll_course = {
        "course_id": JSON.parse(this.state.courseId),
        "batch_id": Number(this.state.batch)
      };
    } else {
      enroll_course = {
        "course_id": JSON.parse(this.state.courseId)
      };
    }

    const httpBody = {
      enroll_course: enroll_course
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiEnrollNow
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  loadScript(src:any) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }
  async displayRazorpay(paymentData: any) {
    const self = this;
    this.props.hideLoader();
    const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }
    const options = {
        key: razorPayKey, // Enter the Key ID generated from the Dashboard
        amount: paymentData?.amount,
        currency: paymentData?.currency,
        name: "Nursing Experts",
        order_id: paymentData?.pay_id,
        handler: function(response: any) {
          if(response) {
            self.updatePaymentStatus();
          }
        },
        prefill: {
            name: localStorage.getItem('userName'),
            email: localStorage.getItem('email'),
            contact: localStorage.getItem('phoneNumber'),
        },
    };
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  }
  onPaymentHandler(response:any) {
    if (response.razorpay_payment_id) {
      this.updatePaymentStatus()
    }
  }
  updatePaymentStatus() {
    const enrolledId = localStorage.getItem('enrolledId')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePaymentStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiRazorPay + `${enrolledId}/update_payment_status`
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiRazorPay,
      token: localStorage.getItem('authToken')
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiRazorPay
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
  
}
