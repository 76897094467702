import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Button,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core"
import { ArrowRightAlt, ArrowBack } from '@material-ui/icons';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { forgotPasswordCap, forgotPasswordImage, forgotPasswordBg, forgotPasswordLogo, leftArrow, loginCap, loginImage, logo } from "./assets";
import { withRouter, RouteComponentProps } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";


export interface IOther {
  messageId: string,
  navigation: {
    navigate: any
  }
}

let othervalues: IOther = {
  messageId: ' ',
  navigation: {
    navigate: ''
  }
}

export interface Props {
  navigation: any;
  id: string;
}

export interface IForgot {
  showLoader: any,
  hideLoader: any
}

export interface S {
  city: string;
  searchState: string;
  messageId: string
}

export interface SS {
  id: any;
}

const useStyles = makeStyles({
  header: {
    paddingBottom: '10px',
    margin: '10px',
    fontSize: '12px'
  },
  input: {
    width: '100%',
    padding: '12px 20px',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxSizing: 'border-box'
  },
  span: {
    // border:'none',
    width: '80%',
    padding: '10px 18px',
    borderRadius: '15px',
    backgroundColor: '#1890ff',
  },
  link: {
    color: '#800080',
    textDecoration: 'underline',
    textAlign: 'right',
    cursor: 'pointer'
  },
  loginBtn: {
    width: '40%',
    backgroundColor: '#D3F5F5',
    padding: '15px',
    color: 'blue',
    border: 'none',
    borderRadius: '15px'
  },
  form: {
    padding: '0px'
  },

  btnheader: {
    padding: '5px',
    fontWeight: 500,
  },
  alignbtn: {
    display: 'flex',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    padding: '5px 15px',
    borderRadius: '10px',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    color: 'white',
    border: 'none',
    backgroundColor: '#1890ff',
    borderRadius: '23px',
    fontSize: 16
  },
  main: {
    backgroundImage: `url(${forgotPasswordBg})`,
    backgroundSize: '100%',
  },
  left: {
    height: '95%',
    backgroundColor: '#4271C9',
    color: '#fff',
    padding: '20px'
  },
  right: {
    height: '95%',
    backgroundColor: 'white',
    padding: '20px'
  },
  wrapper: {
    paddingTop: '5%',
    paddingLeft: '20%',
    paddingRight: '20%',
    height: '60%',
    paddingBottom: '9%',
    display: 'flex',
  },
  error: {
    border: '1px solid red'
  },
  errorMsg: {
    fontSize: '12px',
    color: 'red',
    textAlign: 'left',
    display: 'block',
    fontWeight: 'bold',
    margin: '10px 0',
    fontStyle: 'italic'
  }
})

const block = new BlockComponent<Props, S, SS>(
  {
    navigation: '',
    id: '123'
  }
)


const ForgotPassword = (props: IForgot & RouteComponentProps) => {

  const classes = useStyles();
  const [full_phone_number, setFull_phone_number] = useState('');
  const [error, setError] = useState<any>(false);
  const [errorMsg, setErrorMsg] = useState('');



  useEffect(() => {
    block.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]
    runEngine.attachBuildingBlock(block, block.subScribedMessages);

    block.receive = async (from: string, message: Message) => {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      props.hideLoader();
      if (apiRequestCallId === othervalues.messageId) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.errors) {
          // window.alert(responseJson.errors[0]?.otp || 'Please enter valid number');
          setError({full_phone_number:responseJson.errors[0]?.otp || 'Please enter valid number'});
          setErrorMsg(responseJson.errors[0]?.otp || 'Please enter valid number');
        } else if (responseJson.error) {
          // window.alert("The number  is unverified");
          setError({full_phone_number:'The number  is unverified'});
          setErrorMsg('The number  is unverified');
        }
        else {

          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneOTPMessage)
          );

          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            responseJson.meta?.token
          );
          othervalues.navigation.navigate = props.history.push;

          msg.addData(getName(MessageEnum.NavigationPropsMessage), othervalues);

          msg.addData(
            getName(MessageEnum.AuthTokenEmailMessage),
            ''
          );

          msg.addData(getName(MessageEnum.AuthTokenPhoneNumberMessage), responseJson.data?.attributes?.full_phone_number);

          msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

          block.send(msg);
        }
      }
    }
    return (() => {
      runEngine.unSubscribeFromMessages(block, block.subScribedMessages);
    })
  }, []);



  const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setError({});
    setErrorMsg('');
    let errObj: { [key: string]: string } = {};
    if (!full_phone_number) {
      errObj.full_phone_number = 'Required';
      setError(errObj);
      setErrorMsg('*Phone Number is required');
      return;
    }

    getOTP();
  }

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
    | HTMLSelectElement> = (e) => {
      if(e.target.value.length > 10) {
        setErrorMsg('*Invalid Number');
      } else {
        setErrorMsg('');
      }
      setFull_phone_number(e.target.value);
    }

  const getOTP = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    othervalues.messageId = requestMessage.messageId;
    block.setState({
      city: requestMessage.messageId
    })
    const body = {
      data: {
        attributes: {
          full_phone_number: '91' + full_phone_number
        }
      }
    }
    const httpHeaders = {
      "Content-Type": "application/json"
    };
    props.showLoader();

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      'forgot_password/otp');
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(httpHeaders));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  const numberOnly = (evt: any) => {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  }
  return (
    <div className="login-widget">
            <div className="login">
              <div className="login-left">
                <h3>Welcome to <br /> Nursing Experts</h3>
                <img src={loginImage} alt="" />
              </div>
            <div className="login-right">
              <div>
                <div className="login-logo"><img src={logo} /> </div>
                  {/* Merge Engine UI Engine Code */}
                  <div className="login-info">
                    <h3 className="login-right-heading">Forgot Password</h3>
                    <div className="login-sub-heading">Please Enter your Registered Mobile No.</div>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type='number'
                            placeholder="Enter Mobile Number"
                            className={`${classes.input}  ${(error.full_phone_number ? classes.error : '')}`}
                            name="full_mobile_number"
                            onChange={handleChange}
                            value={full_phone_number}
                            onKeyPress={(e)=>numberOnly(e)}
                          />
                        </div>

                        {errorMsg && <div className={classes.errorMsg}>{errorMsg}</div>}
                        <button type={"submit"} className={classes.button} >
                          <span className={classes.alignbtn}>
                            <span className={classes.btnheader}>Confirm</span>
                            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
                          </span>
                        </button>
                        <View>
                          <Text
                            testID={"backTohome"} //Merge Engine::From BDS
                            style={styles.backTohome} //UI Engine::From Sketch
                            onPress={() => window.location.href = '/login'}//Merge Engine::From BDS - {...this.testIDProps}
                          >
                            <img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />
                            <Text style={styles.backTohomeText}>Back to Home page</Text>
                          </Text>
                        </View>
                    </form>
                  </div>
                  {/* Merge Engine UI Engine Code */}
              </div>
                <span className="cap"><img src={loginCap} alt="image" /></span>
                <img className="login-left-mobile" src={loginImage} alt="" />
              </div>
            </div>
          </div>
    // <div className={classes.main}>
    //   <Grid container className={`${classes.wrapper} registWrapper`} >
    //     <Grid item xs={12} md={3}>
    //       <div className={`${classes.left} registLeft`}>
    //         <div className="fgtPswrdImg">
    //           <h3 style={{ textAlign: 'center' }}>Welcome to <br /> Nursing Experts</h3>
    //           <img src={forgotPasswordImage} alt="" />
    //         </div>
    //       </div>
    //     </Grid>
    //     <Grid item xs={12} md={9} >
    //       <div className={`${classes.right} registRight`} >
    //         <div className="login-logo"><img src={forgotPasswordLogo} /> </div>
    //         <div style={{ textAlign: "center", marginTop: "20%", marginBottom: "20%" }} >
    //           <h1>Forgot Password</h1>
    //           <span className={classes.header}>Please enter your registered Mobile No.</span>
    //           <Grid container>
    //             <Grid md={2}></Grid>
    //             <Grid md={8} xs={12}>
    //               <form className={classes.form} onSubmit={handleSubmit}>
    //                 <input
    //                   type='text'
    //                   placeholder="Enter Mobile Number"
    //                   className={`${classes.input}  ${(error.full_phone_number ? classes.error : '')}`}
    //                   name="full_mobile_number"
    //                   onChange={handleChange}
    //                   value={full_phone_number}
    //                 />

    //                 {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
    //                 <button type={"submit"} className={classes.button} >
    //                   <span className={classes.alignbtn}>
    //                     <span className={classes.btnheader}>Confirm</span>
    //                     <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
    //                   </span>
    //                 </button>
    //               </form>
    //               <div>
    //                 <span
    //                   onClick={() => { window.location.href = '/' }}
    //                 ><p className={classes.link}><img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
    //               </div>
    //             </Grid>
    //             <Grid md={2}></Grid>
    //           </Grid>
    //         </div>
    //       </div>
    //     </Grid>
    //   </Grid>
    // </div >
  )
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    // width: Platform.OS === "web" ? "75%" : "100%",
    // maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  left: {
    backgroundColor: '#4271C9',
    color: 'white',
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    // height: '97%',
  },
  right: {
    backgroundColor: '#fff',
    padding: 10,
    // height: '97%',
    // borderTopRightRadius: '20px',
    // borderBottomRightRadius: '20px'
  },
  wrapper:{
   display:'flex',
   justifyContent:'space-around'
  },
  titleWhySignUp: {
    marginBottom: 25,
    fontSize: 13,
    textAlign: "center",
    marginVertical: 4
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  bgOtherLoginButton: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "center",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    color: "#6200EE",
    fontWeight: "bold"
  },

  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 1 : 1,
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 5,
    includeFontPadding: true,
    padding: 10
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    borderRadius: 5
  },
  passwordShowHide: {
    alignSelf: "center"
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 5,
    paddingLeft: 0,
    borderWidth: Platform.OS === "web" ? 1 : 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10
  },

  labelTitle: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 32,
    textAlign: "center",
    marginVertical: 8,
    color: "#454545",
    fontWeight: "bold"
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},

  forgotPassword: {
    color: "#292C95",
    fontWeight: '500',
    marginBottom: 0,
    zIndex: -1,
    fontStyle: 'italic',
    textDecorationLine: 'underline'
  },
  checkBoxContainerView: {
    flexDirection: "row",
    marginBottom: 10,
    marginLeft: -7,
    zIndex: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rememberMe: {
    color: "#3895FF",
    fontWeight: "500",
    alignSelf: "center",
    zIndex: -1,
  },
  orLabel: {
    color: "#00000000",
    fontWeight: "bold",
    alignSelf: "center",
    margin: 20
  },
  backTohome: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  backTohomeText: {
    color: '#292C95',
    fontStyle: 'italic',
    textDecorationLine: 'underline',
    fontWeight: '500'
  },
  goToSignUp: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  noAccount: {
    paddingBottom: 10,
    color: '#505050'
  },
  loginView: {
    padding: 0,
    minHeight: '100vh'
  },
  errorMessage: {
    fontSize: 14,
    color: '#B86F72',
    fontStyle: 'italic',
    marginTop: 0,
    marginBottom: 15
  },
  errorField: {
    color: '#fff',
    borderWidth: 1,
    borderColor: "#767676",
    borderStyle: 'solid'
  }
});

export default withLoader((withRouter)(ForgotPassword));