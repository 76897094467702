// @ts-nocheck
import React from "react";

// Customizable Area Start
import { leftArrow, loginCap, loginImage, logo } from "./assets";
import { Grid } from "@material-ui/core";

import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Button,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController.web";
import CustomButton from "../../../components/src/customButton.web";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
class EmailAccountLoginBlock extends EmailAccountLoginController {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

async componentDidMount() {
  const mobile = this.getCookie('mobile')
  const password = this.getCookie('password')
  if(mobile) {
    document.getElementById('mobileId').value = mobile
    this.setState({mobile: mobile})
  }
  if(password) {
    document.getElementById('passwordId').value = password
    this.setState({password: password})
  }
  this.setState({classMobile: 'form-control', classPassword: 'form-control'})
}
numberOnly = (evt: any) => {
  if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
  {
      evt.preventDefault();
  }
}
  render() {
    const errorMobile = this.state.errorMobile ? 'error-field' : ''
    // const classMobile = `form-control ${errorMobile}`
    const errorPassword = this.state.errorPassword ? 'error-field' : ''
    // const classPassword = `form-control ${errorPassword}`
    return (
      <div>
        <div className="login-widget">
          <div className="login">
            <div className="login-left">
              <h3>Welcome to <br /> Nursing Experts</h3>
              <img src={loginImage} alt="" />
            </div>
          <div className="login-right">
          <div className="login-logo"><img src={logo} /> </div>
              {/* Merge Engine UI Engine Code */}
              <View>
                {this.isPlatformWeb() ? (
                  <Text style={styles.labelTitle}>{this.labelTitle}</Text>
                  ) : null}

                <Text style={styles.titleWhySignUp}>{this.state.labelHeader}</Text>
              <div className="form-group">
                <input id="mobileId" className={this.state.classMobile} type="number" placeholder="Mobile Number" onKeyDown={(e) => this.enterLogin(e)} onChange={(e)=>this.getMobileNumber(e)} onKeyPress={(e)=>this.numberOnly(e)}/>
              </div>
              <div className="form-group">
                <input id="passwordId" className={this.state.classPassword} type="password" placeholder="Password" onKeyDown={(e) => this.enterLogin(e)}  onChange={(e)=>this.getPassword(e)}/>
              </div>
              {/* <button className="btn-login" onClick={()=>this.doMobileLogIn()}>Login</button> */}

                {/* <TextInput
                  testID="txtInputEmail" //Merge Engine::From BDS
                  style={styles.bgMobileInput} //UI Engine::From Sketch
                  placeholder="Mobile Number" //UI Engine::From Sketch
                  {...this.txtInputEmailProps} //Merge Engine::From BDS - {...this.testIDProps}
                /> */}

                {/* <View style={styles.bgPasswordContainer}>
                  <TextInput
                    testID="txtInputPassword" //Merge Engine::From BDS
                    style={styles.bgPasswordInput} //UI Engine::From Sketch
                    placeholder={this.state.placeHolderPassword} //UI Engine::From Sketch
                    {...this.txtInputPasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                  />

                  <TouchableOpacity
                    testID={"btnPasswordShowHide"} //Merge Engine::From BDS
                    style={styles.passwordShowHide} //UI Engine::From Sketch
                    {...this.btnPasswordShowHideProps} //Merge Engine::From BDS - {...this.testIDProps}
                  >
                    <Image
                      testID={"btnPasswordShowHideImage"} //Merge Engine::From BDS - testIDImage
                      style={styles.imgPasswordShowhide} //UI Engine::From Sketch
                      {...this.btnPasswordShowHideImageProps} //Merge Engine::From BDS - {...this.testIDProps}
                    />
                  </TouchableOpacity>
                </View> */}

                <View style={styles.checkBoxContainerView}>
                  
                  <FormControlLabel className="custom-checkbox"
                    control={
                      <Checkbox className=""
                        checked={this.state.checkedRememberMe}
                        onChange={()=>{this.btnRememberMeProps.onPress()}}
                        // this.btnRememberMeProps.onPress()
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />
                  <Text
                    testID={"btnForgotPassword"} //Merge Engine::From BDS
                    style={styles.forgotPassword} //UI Engine::From Sketch
                    {...this.btnForgotPasswordProps} //Merge Engine::From BDS - {...this.testIDProps}
                    onPress={()=> this.props.history.push('/ForgotPassword')}
                  >
                    Forgot password?
                  </Text>
                </View>
                {
                  (this.state.errorMessage !== '' && (this.state.errorMobile || this.state.errorPassword))
                  ? <View ><Text style={styles.errorMessage}>{this.state.errorMessage}</Text></View>
                  : null
                }
                {/* <View >
                {
                    this.state.errorMobile 
                    ? <Text style={styles.errorMessage}>Invalid mobile number</Text>
                    : null
                  }
                  </View> */}
                <CustomButton testID={"btnEmailLogIn"} className="btn-login2" label={this.labelTitle} isIcon={true}  onClick={()=>{this.btnEmailLogInProps.onPress()}}/>
                {/* <Button
                  testID={"btnEmailLogIn"} //Merge Engine::From BDS
                  title={this.state.btnTxtLogin} //UI Engine::From Sketch
                  {...this.btnEmailLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
                /> */}
                <View>
                  <Text
                    testID={"backTohome"} //Merge Engine::From BDS
                    style={styles.backTohome} //UI Engine::From Sketch
                    onPress={() => this.props.history.push('/')}//Merge Engine::From BDS - {...this.testIDProps}
                  >
                    <img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />
                    <Text style={styles.backTohomeText}>Back to Home page</Text>
                  </Text>
                </View>
                <Text style={styles.orLabel}>{this.state.labelOr}</Text>
                <View style={styles.goToSignUp}>
                  <Text style={styles.noAccount}>Don't have Account?</Text>
                  {/* <Button

                    testID={'btnSignUp'}
                    title="Sign Up"
                    {...this.btnSignUpProps} /> */}
                    <CustomButton testID={"btnSignUp"} color="default" className="btn-signup btn-curve" label="Signup Now" onClick={()=>{this.props.history.push('/signup')}}/>
                </View>
                {/* <Text
                  testID="btnSocialLogin" //Merge Engine::From BDS
                  style={styles.bgOtherLoginButton} //UI Engine::From Sketch
                  {...this.btnSocialLoginProps} //Merge Engine::From BDS - {...this.testIDProps}
                >
                  {this.state.btnTxtSocialLogin}
                </Text> */}
              </View>
              {/* Merge Engine UI Engine Code */}
              <span className="cap"><img src={loginCap} alt="image" /></span>
              <img className="login-left-mobile" src={loginImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    // width: Platform.OS === "web" ? "75%" : "100%",
    // maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  left: {
    backgroundColor: '#4271C9',
    color: 'white',
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    // height: '97%',
  },
  right: {
    backgroundColor: '#fff',
    padding: 10,
    // height: '97%',
    // borderTopRightRadius: '20px',
    // borderBottomRightRadius: '20px'
  },
  wrapper:{
   display:'flex',
   justifyContent:'space-around'
  },
  titleWhySignUp: {
    marginBottom: 25,
    fontSize: 13,
    textAlign: "center",
    marginVertical: 4
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  bgOtherLoginButton: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "center",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    color: "#6200EE",
    fontWeight: "bold"
  },

  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 1 : 1,
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 5,
    includeFontPadding: true,
    padding: 10
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    borderRadius: 5
  },
  passwordShowHide: {
    alignSelf: "center"
  },

  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 5,
    paddingLeft: 0,
    borderWidth: Platform.OS === "web" ? 1 : 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10
  },

  labelTitle: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 32,
    textAlign: "center",
    marginVertical: 8,
    color: "#454545",
    fontWeight: "bold"
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},

  forgotPassword: {
    color: "#292C95",
    fontWeight: '500',
    marginBottom: 0,
    zIndex: -1,
    fontStyle: 'italic',
    textDecorationLine: 'underline'
  },
  checkBoxContainerView: {
    flexDirection: "row",
    marginBottom: 10,
    marginLeft: -7,
    zIndex: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rememberMe: {
    color: "#3895FF",
    fontWeight: "500",
    alignSelf: "center",
    zIndex: -1,
  },
  orLabel: {
    color: "#00000000",
    fontWeight: "bold",
    alignSelf: "center",
    margin: 20
  },
  backTohome: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  backTohomeText: {
    color: '#292C95',
    fontStyle: 'italic',
    textDecorationLine: 'underline',
    fontWeight: '500'
  },
  goToSignUp: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  noAccount: {
    paddingBottom: 10,
    color: '#505050'
  },
  loginView: {
    padding: 0,
    minHeight: '100vh'
  },
  errorMessage: {
    fontSize: 14,
    color: '#B86F72',
    fontStyle: 'italic',
    marginTop: 0,
    marginBottom: 15
  },
  errorField: {
    color: '#fff',
    borderWidth: 1,
    borderColor: "#767676",
    borderStyle: 'solid'
  }
});
const EmailAccountLoginBlockToast = withSnackBar(EmailAccountLoginBlock);
const EmailAccountLoginBlockwithLoader = withLoader(EmailAccountLoginBlockToast);
export default withRouter(EmailAccountLoginBlockwithLoader);
// Customizable Area End