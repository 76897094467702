import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  qaData: any;
  questions: any;
  // Customizable Area End
}

interface SS {
  id: any;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  questionsAndAnswersGetApiCallId: string = "";
  qaData: any;
  questions: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      qaData: {},
      questions: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.questionsAndAnswersGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success


            this.qaData = responseJson.data;
            this.questions = responseJson?.data?.attributes?.questions?.data;
            if(this.questions && this.questions.length>0) {
              this.questions.map((ele:any) => {
                ele['showBox']=''
                ele['isAnswer']= false
                ele['reasonText']= ''
                ele['correctAnswer']= ''
              })
            }
            this.setState({
              qaData: this.qaData,
              questions: this.questions
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  // Customizable Area Start
  getQuestionsAndAnswers() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.questionsAndAnswersGetApiCallId = requestMessage.messageId;
    let _id = window.location.pathname.split('/').pop()

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetQaList + _id
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetQaList,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetQaList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  selectAnswer(event: any,  ele: any, index: number) {
    let questionList = this.state.questions
    questionList[index].showBox = event.target.value
    let correct_opt = ele.attributes.correct_opt;
    let selectedOptionText = `option${correct_opt}`+'_text'
    // debugger
    if((ele.attributes[selectedOptionText]) == event.target.value) {
      // debugger
      questionList[index].correctAnswer = ele.attributes[selectedOptionText]
      questionList[index].isAnswer = true;
      questionList[index].reasonText = ele.attributes.explanation;
    } else {
      questionList[index].isAnswer = false;
      questionList[index].correctAnswer = ele.attributes[selectedOptionText]
    }
    // if(JSON.stringify(ele.attributes.ans_text) == JSON.stringify(event.target.value)) {
    // }
    // questionList[index].attributes.answers.data.map((item: any, ind: number)=>{
    //   if(JSON.parse(JSON.stringify(item.attributes.is_correct))) {
    //     questionList[index].correctAnswer = JSON.stringify(item.attributes.ans_text)
    //   }
    //   if(JSON.stringify(item.attributes.ans_text) == JSON.stringify(event.target.value)) {
    //     questionList[index].isAnswer = JSON.parse(JSON.stringify(item.attributes.is_correct));
    //     questionList[index].reasonText = JSON.stringify(item.attributes.reason);
    //   }
    // })
    this.setState({questions: questionList})
  }
  // Customizable Area End

}
