import React,{useEffect,useState} from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
   data:[]
}

export interface SS {
    id: any;
}


const ContactusController =(showLoader:any, hideLoader:any)=>{

const block = new BlockComponent<Props, S, SS>(
    {
        navigation: '',
        id: '123'
    }
)
const [data , setData]= useState<any>({});

useEffect(() => {
    block.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage)
    ]
    runEngine.attachBuildingBlock(block, block.subScribedMessages);

    block.receive = async (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceMessage)
        )
        // hideLoader();
        var responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                )
            if (responseJson.errors || responseJson.error) {
                //  doing nothing for now
            }else{
                setData(responseJson.data);
            }
        
    }
    return (() => {
        runEngine.unSubscribeFromMessages(block, block.subScribedMessages);
    })
}, []);

const getContactUsData= ()=>{

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const httpHeaders = {
      "Content-Type":"application/json"
    };
    // showLoader();
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      'contact_us/contacts');
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(httpHeaders));
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

return {data,getContactUsData};

}

export default ContactusController;
