// @ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import SignupController, {
  Props,
  configJSON
} from "./SignupController.web"
import Footer from "../../../components/src/Footer.web"
import CustomButton from "../../../components/src/customButton.web"
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from "react-router";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import MyFooterWeb from "./MyFooter.web";
import HeaderWeb from "./Header.web";
import { leftArrow, loginCap, loginImage, logo } from "./assets";
import { getStates } from "../../email-account-registration/src/EmailAccountRegistrationController.web";
// import Dropzone from "react-dropzone";
// import UploadService from "./upload-files.service";
// import "bootstrap/dist/css/bootstrap.min.css";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  },
]
const ourPrograms = [
  {
    image: 'expert.png',
    title: 'PREPERATION FOR GOVERNMENT JOBS',
    color: '#ff0000',
  },
  {
    image: 'expert.png',
    title: 'INTERVIEW PREPARATION',
    color: '#0000ff'
  },
  {
    image: 'expert.png',
    title: 'TEST SERIES (AS PER REAL BASE EXAM)',
    color: '#00ff00'
  },
]
const squareCircles = [
  {title: 'IELTS', bgColor: '#2E81F7', url:'ielts'},
  {title: 'NCLEX RN', bgColor: '#0CBB80', url:'nclex-rn'},
  {title: 'OET', bgColor: '#FFAE3B', url:'oet'},
]
class Signup extends SignupController {
  fileObj = [];
  fileArray = [];
  selectedFiles = [];

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
    this.state = {
      progressInfos: [],
      message: [],

      fileInfos: [],
    };
  }
  uploadMultipleFiles = (e) => {
    this.fileObj.push(e.target.files)
    let _files:any[] = Array.from(e.target.files)
    _files.map((ele, i) => {
      this.selectedFiles.push(ele)
    })
    _files.map((file)=>{
      this.fileArray.push({url:URL.createObjectURL(file), type: file.type})
    })
    this.setState({ files: this.selectedFiles, previewFiles: this.fileArray}, () => { 
    }
    )
  }
  onDeleteFile = (url) => {
    this.state.previewFiles.map((ele, ind)=>{
      if(JSON.stringify(ele.url) == url){
        this.state.previewFiles.splice(ind, 1)
        this.state.files.splice(ind, 1)
        this.setState({previewFiles: this.state.previewFiles, files: this.state.files})
      }
    })
  }
  getYourName = (e) => {
    this.setState({yourName: e.target.value})
  }
  getFatherName = (e) => {
    this.setState({fatherName: e.target.value})
  }
  getGender = (e) => {
    this.setState({gender: e.target.value})
  }
  getDob = (e) => {
    let d = new Date(e.target.value)
    let formatDate = new Intl.DateTimeFormat('en').format(d);
    // let mo = new Intl.DateTimeFormat('en', { month: 'MM' }).format(d);
    // let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    this.setState({dob: formatDate})
  }
  getQualification = (e) => {
    this.setState({qualification: e.target.value})
  }
  getCourseType = (e) => {
    this.setState({courseType: e.target.value})
  }
  getEmail = (e) => {
    this.setState({email: e.target.value})
  }

  getMessage = (e) => {
    this.setState({yourMessage: e.target.value})
  }
  setSelectedQualification = (data: any) => {
    const name_id = data.target.value.split('_');
    const name = data.target.value;
    const id = data;
    if(name_id[0] == 'Other') {
      this.setState({otherQualificationField: true})
    }
    this.setState({qualification: name_id[0], selectedQualificationID: name_id[1]})
  }
  setSelectedCourse = (data: any) => {
    const name_id = data.target.value.split('_');
    const name = data.target.value;
    const id = data;
    if(name_id[0] == 'Other') {
      this.setState({otherInterestedCourseField: true})
    }
    this.setState({courseType: name_id[0], selectedInterestedCourseID: name_id[1]})
  }
  componentDidMount() {
    document.body.scrollTop = 0;
    this.setState({
      classYourName: 'form-control',
      classFatherName: 'form-control',
      classGender: 'form-control custom-radio',
      classDob: 'form-control',
      classQualification: 'form-select',
      classCourseType: 'form-select',
      classEmail: 'form-control',
      classContactNo: 'form-control',
      classYourMessage: 'form-control',
      classState: 'form-select',
      classCity: 'form-select'
    })
    this.getApplyDetails()
    this.getQualifications()
    this.getInterestedCourses()
    this.getStates()
  }
  numberOnly = (evt: any) => {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  }
  getOtherQualification = (e:any) => {
    this.setState({otherQualification: e.target.value})
  }

  getOtherInterestedCourse = (e:any) => {
    this.setState({otherInterestedCourse: e.target.value})
  }

  setSelectedState = (data: any) => {
    const name = data.target.value;
    this.setState({state: name})
    this.citiesProps(name)
    // this.setState({showCity: true})
  }
  setSelectedCity = (data: any) => {
    const name = data.target.value;
    this.setState({city: name})
  }

  citiesProps = (name: any) => {
    this.getCities(name)
  };

  render() {
    const { navigation } = this.props;
    // const _this = this;
    return (
      <div>
      <div className="login-widget">
            <div className="login">
              <div className="login-left">
                <h3>Welcome to <br /> Nursing Experts</h3>
                <img src={loginImage} alt="" />
              </div>
            <div className="login-right">
            <div className="login-logo"><img src={logo} /> </div>
            <div className="form-div">
            <h3 className='login-right-heading'>Signup</h3>
            <div className="login-sub-heading">Create your Account in Simple Steps</div>
                {/* Merge Engine UI Engine Code */}
                <View>
                <div className="sign-up">
                <div className="form-group">
                  <input type="text" className={this.state.classYourName} placeholder="Full Name" onChange={(e)=>this.getYourName(e)} autoComplete="true" />
                </div>
                <div className="form-group">
                  <input type="email" className={this.state.classEmail} placeholder="Email ID" onChange={(e)=>this.getEmail(e)} />
                  {/* {
                    this.state.showErrorEmail && 
                      <Grid item sm={12} xs={12} md={12}>
                        <div className="error">
                          Invalid Email
                        </div>
                      </Grid>
                  } */}
                </div>
                <div className="form-group">
                  <input type="tel" className={this.state.classContactNo} placeholder="Mobile Number" onChange={(e)=>this.getContact(e)} onKeyPress={(e)=>this.numberOnly(e)}/>
                  {
                    this.state.errorMobile && 
                      <Grid item sm={12} xs={12} md={12}>
                        <div className="error">
                          Invalid Mobile
                        </div>
                      </Grid>
                  }
                </div>
                <div className="form-group">
                  <select className={this.state.classQualification}
                    onChange={(e)=>{this.setSelectedQualification(e)}}
                    defaultValue={'DEFAULT'}
                  >
                    <option value="DEFAULT" disabled >Qualification</option>
                    {
                      this.state.qualifications && this.state.qualifications.length > 0 && 
                      this.state.qualifications.map((ele:any, ind:any)=>
                      <option key={ele.id} id={ele.id} value={ele.attributes.name +'_'+ ele.id} >{ele.attributes.name}</option>
                      )
                    }
                  </select>
                </div>
                { this.state.otherQualificationField &&
                    <div className="form-group">
                      <input 
                      type='text' 
                      placeholder="Enter Qualification" 
                      name='other_qualification'
                      onChange={(e)=>this.getOtherQualification(e)}
                      className="form-control"
                      />
                    </div>
                  }
                  <div className="form-group">
                  <select className={this.state.classCourseType}
                    onChange={(e)=>{this.setSelectedCourse(e)}}
                    defaultValue={'DEFAULT'}
                  >
                    <option value="DEFAULT" disabled>Interested Course</option>
                      {
                        this.state.interestedCourseList && this.state.interestedCourseList.length > 0 && 
                        this.state.interestedCourseList.map((ele:any, ind:any)=>
                        <option key={ele.id} value={ele.attributes.name +'_'+ ele.id} >{ele.attributes.name}</option>
                        )
                      }
                  </select>
                </div>
                { this.state.otherInterestedCourseField &&
                  <div className="form-group">
                    <input 
                    type='text' 
                    placeholder="Enter Interested Course" 
                    name='other_interested_course'
                    onChange={(e)=>this.getOtherInterestedCourse(e)}
                    className="form-control"
                    />
                  </div>
                }
                <div className="field-row">
                  <div className="form-group">
                              <select className={this.state.classState}
                                onChange={(e)=>{this.setSelectedState(e)}}
                                defaultValue={'DEFAULT'}
                              >
                                <option value="DEFAULT" disabled>State</option>
                                  {
                                    this.state.states && this.state.states.length > 0 && 
                                    this.state.states.map((ele:any, ind:any)=>
                                    <option key={ele} value={ele} selected={this.state.state == ele}>{ele}</option>
                                    )
                                  }
                              </select>
                            
                  </div>
                  <div className="form-group">
                      <select
                        className={this.state.classCity}
                        onChange={(e)=>{this.setSelectedCity(e)}}
                        defaultValue={'DEFAULT'}
                      >
                        <option value="DEFAULT" disabled>City</option>
                          {
                            this.state.cities && this.state.cities.length > 0 && 
                            this.state.cities.map((ele:any, ind:any)=>
                            <option key={ele} value={ele} selected={this.state.city == ele}>{ele}</option>
                            )
                          }
                      </select>
                  </div>
                </div>
                {
                this.state.showErrorMessage &&
                  <Grid item sm={12} xs={12} md={12}> 
                    <div className="error-message">
                      *Please provide value to all fields
                    </div>
                  </Grid>
                }

          </div>
                  <CustomButton testID={"btnEmailLogIn1"} className="btn-login2" label={'Set Password'} isIcon={true}  onClick={()=>this.signUp()}/>
                  {/* <Button
                    testID={"btnEmailLogIn"} //Merge Engine::From BDS
                    title={this.state.btnTxtLogin} //UI Engine::From Sketch
                    {...this.btnEmailLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
                  /> */}
                  <div className="backTohome">
                    <a 
                      testID={"backTohome"} //Merge Engine::From BDS
                      style={styles.backTohome} //UI Engine::From Sketch
                      onClick={() => this.props.history.push('/')}//Merge Engine::From BDS - {...this.testIDProps}
                    >
                      <img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />
                      <span>Back to Home page</span>
                    </a>
                  </div>
                  <div className="backTologin">
                  <p className="already-account"> Already have an Account? </p>
                  <button className="login-here"
                      onClick={() => this.props.history.push('/login')}
                  >Login Here</button>

                  </div>
                </View>
            </div>
                {/* Merge Engine UI Engine Code */}
                <span className="cap cap-signup"><img src={loginCap} alt="image" /></span>
                <img className="login-left-mobile" src={loginImage} alt="" />
              </div>
            </div>
          </div>

      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default ApplyNow;
const SignupToast = withSnackBar(Signup);
const SignupWithLoader = withLoader(SignupToast);
export default withRouter(SignupWithLoader);
