import React, { useState, useEffect } from 'react';
import { registrationBg, registrationImage, registrationLogo, registrationCap,  leftArrow, loginCap, loginImage, logo } from './assets';
import { makeStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { withRouter,RouteComponentProps } from "react-router";
import { withLoader } from '../../../components/src/withLoader.Web';
import EmailAccountSetPasswordController from './EmailAccountSetPasswordController.web';


const useStyles = makeStyles({
    header: {
        paddingBottom: '10px'
    },
    input: {
        width: '100%',
        padding: '12px 20px',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxSizing: 'border-box',
        marginBottom: '15px'
    },
    button: {
        width: '100%',
        color: 'white',
        border: 'none',
        backgroundColor: '#1890ff',
        borderRadius: '23px',
    },
    span: {
        // border:'none',
        width: '80%',
        padding: '10px 18px',
        borderRadius: '15px',
        backgroundColor: '#1890ff',
    },
    link: {
        textDecoration: 'underline',
        textAlign: 'right',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#292C95',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        margin: '10px'
    },
    loginBtn: {
        width: '40%',
        backgroundolor: '#E3EDF8',
        padding: '15px',
        color: 'blue',
        border: 'none',
        borderRadius: '15px'

    },
    btnheader: {
        padding: '5px',
        fontWeight: 500,
    },
    alignbtn: {
        display: 'flex',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        padding: '5px 10px',
        borderRadius: '10px',
        cursor: 'pointer'
    },
    main: {
        backgroundImage: `url(${registrationBg})`,
        backgroundSize: '100%',
    },
    left: {
        height: '95%',
        backgroundColor: '#4271C9',
        color: '#fff',
        padding: '20px'
    },
    right: {
        height: '95%',
        backgroundColor: 'white',
        padding: '20px'
    },
    wrapper: {
        paddingTop: '5%',
        paddingLeft: '20%',
        paddingRight: '20%',
        height: '60%',
        paddingBottom: '9%',
        display: 'flex',
    },
    error: {
        border: '1px solid red'
    },
    errorMsg: {
        fontSize: '12px',
        color: 'red',
        textAlign: 'left',
        display: 'block',
        fontWeight: 'bold',
        margin: '10px 0',
        fontStyle: 'italic'
    }
})

interface IProps {
    showLoader:any,
    hideLoader:any
}

const EmailAccountSetPassword = (props: IProps & RouteComponentProps) => {
    const classes = useStyles();

    const [formData, setFormData] = useState({
        password: '',
        confirm_password: ''
    })
    const [error, setError] = useState<any>(false);
    const [errorMsg, setErrorMsg] = useState('');

    const { password, confirm_password } = formData;
    const {setPassword}=EmailAccountSetPasswordController({error,errorMsg, setError,setErrorMsg});

    const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLAnchorElement> = (event) => {
        localStorage.setItem("userPassword", password)
        event.preventDefault();
        setErrorMsg('');
        setError({});
        let errorObj:{[key:string]:string}={};
        let pattern=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
        if(!password.match(pattern) || !confirm_password.match(pattern)) {
            setErrorMsg('*Password should have atleast 1 Capital letter, Small letter, Digit, Special symbol and minimum length 8 ');
            return
        }
        if(!password || !confirm_password){
            !password ? errorObj.password='required' :''
            !confirm_password ? errorObj.confirm_password='required' :''
             setError(errorObj);
             setErrorMsg('*Please provide value to all fields');
            return ;
        }
        if (password !== confirm_password) {
            errorObj.password='password not match'; 
            errorObj.confirm_password='password not match';
            setError(errorObj);
            setErrorMsg('*Create password and confirm password should be same');
            return;
        }
        
        setPassword(password, props.history.push, props.showLoader, props.hideLoader);
       
    }

    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
        | HTMLSelectElement> = (e) => {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        }


    return (
<div className="login-widget">
                <div className="login">
                    <div className="login-left">
                        <h3>Welcome to <br /> Nursing Experts</h3>
                        <img src={loginImage} alt="" />
                    </div>
                    <div className="login-right">
                        <div className="login-logo"><img src={logo} /> </div>
                        {/* Merge Engine UI Engine Code */}
                        <div className={`${classes.right} registRight`} >
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <div className="reg-form" style={{ textAlign: "center" }}>
                                            <h3 className='login-right-heading'>Set Password</h3>
                                            <div className="login-sub-heading">Create your Password for Nursing Expert</div>

                                            <form onSubmit={handleSubmit}>
                    <input type ='password' placeholder="Create Password" className={`${classes.input}  ${(error.password? classes.error : '')}`} name="password" onChange={handleChange} value={password} />

                    <input type ='password' placeholder="Confirm Password" className={`${classes.input}  ${(error.confirm_password ? classes.error : '')}`} name="confirm_password" onChange={handleChange} value={confirm_password} />
                    
                    {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
                    <button type ={"submit"} className={classes.button} >
                    <span className={classes.alignbtn}>
                    <span className={classes.btnheader}>Signup</span>
                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
                    </span>
                    </button>
                    </form>
                                            <div>
                                                <span
                                                    onClick={() => { window.location.href = '/' }}
                                                ><p className={classes.link}>
                                                        <img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
                                                <p> Already have an Account? </p>
                                                <button className={classes.loginBtn}
                                                    onClick={() => { props.history.push('login') }}
                                                >Login Here</button>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        {/* Merge Engine UI Engine Code */}
                        <span className="cap"><img src={loginCap} alt="image" /></span>
                        <img className="login-left-mobile" src={loginImage} alt="" />
                    </div>
                </div>
          </div>
        // <div className={classes.main}>
        //     <Grid container className={`${classes.wrapper} registWrapper`} >
        //         <Grid item xs={12} md={3}>
        //             <div className={`${classes.left} registLeft`}>
        //                 <div className="setPswrdImg">
        //                     <h3 style={{ textAlign: 'center' }}>Welcome to <br /> Nursing Experts</h3>
        //                     <img src={registrationImage} alt="" />
        //                 </div>
        //             </div>
        //         </Grid>
        //         <Grid item xs={12} md={9} >

        //             <div className={`${classes.right} registRight`} >

        //             <div className="login-logo"><img src={registrationLogo} /> </div>
        //             <div style={{ textAlign: "center", marginTop: "20%", marginBottom: "20%" }} >
        //             <h1>Set Password</h1>
        //             <div className={classes.header}>Create your Password for Nursing Experts</div>
        //             <Grid container>
        //             <Grid md={2}></Grid>
        //             <Grid md={8} xs={12}>
        //             <form onSubmit={handleSubmit}>
        //             <input type ='password' placeholder="Create Password" className={`${classes.input}  ${(error.password? classes.error : '')}`} name="password" onChange={handleChange} value={password} />

        //             <input type ='password' placeholder="Confirm Password" className={`${classes.input}  ${(error.confirm_password ? classes.error : '')}`} name="confirm_password" onChange={handleChange} value={confirm_password} />
                    
        //             {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
        //             <button type ={"submit"} className={classes.button} >
        //             <span className={classes.alignbtn}>
        //             <span className={classes.btnheader}>Signup</span>
        //             <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='20px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
        //             </span>
        //             </button>
        //             </form>
        //             <div>
        //             <span
        //             onClick={() => { props.history.push('/') }}
        //             ><p className={classes.link}><img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
                    
        //             </div>
        //             </Grid>

        //             <Grid md={2}></Grid>
        //             </Grid>
        //             </div>
        //             </div>
               
        //     </Grid>
        // </Grid>
        // </div >

    )

}

export default (withLoader)(withRouter(EmailAccountSetPassword));