import React, { useEffect } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import ZoomController from './ZoomController.web';
import { myCourses, zoomKey } from './assets';
const config = require('../../../framework/src/config.js');

const Zoom = (props: any) => {
  ZoomController.prototype.getValidateSession()
  setInterval(() => {
    ZoomController.prototype.getValidateSession()
  }, 30000)

  let authEndpoint = `${config.baseURL}/meeting/meeting/get_signature`;
  let meetingNumber = window.location.pathname.split("/").slice(-2)[0];
  let passWord = window.location.pathname.split("/").pop();
  let role = 0;
  let userName = String(localStorage.getItem("userName")).replace(/['"]+/g, "");
  let userEmail = "nursingexperts9278@gmail.com";
  let registrantToken = "";
  let zakToken = "";
  let leaveUrl = myCourses;

  var cssId = 'myCss';  // you could encode the css path itself to generate id..
  if (!document.getElementById(cssId)) {
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.id = cssId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://source.zoom.us/2.18.0/css/bootstrap.css';
    link.media = 'all';
    head.prepend(link);
  }

  ZoomMtg.setZoomJSLib('https://source.zoom.us/2.18.0/lib', '/av')
  ZoomMtg.preLoadWasm();
  ZoomMtg.prepareWebSDK();

  useEffect(() => {
    showZoomDiv()
    generateSignature();
  }, [])

  const generateSignature = async () => {
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }

    try {
      let response = await fetch(`${authEndpoint}?meetingNumber=${meetingNumber}&role=${role}`, options);
      let res = await response.json();
      initiateMeeting(res.signature);
    } catch (error) {
      console.log(error)
    }
  }

  const showZoomDiv = () => {
    const _div = document.getElementById('zmmtg-root');
    if (_div)
      _div.style.display = 'block';
  }

  const initiateMeeting = (signature: string) => {
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      disableInvite: false, //optional
      showPureSharingContent: false, //optional
      isSupportAV: true, //optional,
      isSupportChat: true, //optional,
      isSupportQA: true, //optional,
      isSupportPolling: true, //optional
      isSupportBreakout: true, //optional
      isSupportCC: true, //optional,
      screenShare: true, //optional,
      videoDrag: true, //optional,
      sharingMode: 'both', //optional,
      videoHeader: true, //optional,
      isLockBottom: true, // optional,
      isSupportNonverbal: true, // optional,
      isShowJoiningErrorDialog: true, // optional,
      disablePreview: false, // optional
      disableCORP: true, // optional
      inviteUrlFormat: '', // optional
      success: (success: any) => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: zoomKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,

          success: function (res: any) {
            ZoomMtg.showJoinAudioFunction({
              show: false
            });
          },
          error: function (res: any) {
            console.log(res)
          }
        })
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }
  return (
    <>
      {
        !showZoomDiv && <div id="zmmtg-root"></div>
      }
    </>
  )
}

export default Zoom;