//@ts-nocheck 
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import TestimonialsController, {
  Props,
  configJSON
} from "./TestimonialsController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import CustomButton from "../../../components/src/customButton.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import MyFooterWeb from "./MyFooter.web";
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import HeaderWeb from "./Header.web";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

class Testimonials extends TestimonialsController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount () {
    this.scrollTop()
    this.getTestimonials()
  }

  scrollTop = () => {
    document.body.scrollTop = 0;
  }

  render() {
    const headings = this.state?.testimonials?.heading;
    const testimonials = this.state?.testimonials?.data;
    return (
      <div className="top-gap">
            <HeaderWeb />
            <div className="container w-900">
                <div className="section-heading w-800-center">
                    <h3><span>Our Testimonials</span></h3>
                    <p>{headings?.sub_title}</p>
                </div>
                <ul className="testimonals-list">
                    {(testimonials && testimonials.length > 0)
                    ? testimonials.map((info: any, index: number) =>
                        <li key={info.id}>
                            <div className="testimonals-info">
                                {/* <p className="desc"> */}
                                  {info?.attributes?.note && info?.attributes?.note?.match(/[\r\n|-]+/)
                                    ? info?.attributes?.note?.split(/[\r\n|-]+/).map((i: any, key: any) => {
                                      return <p className="num-gap" key={key}>{i}</p>
                                    })
                                    : <p>"{info?.attributes?.note}"</p>
                                  }
                                  {/* </p> */}
                                  <div className="name">
                                    <h2>{info?.attributes?.name}</h2>
                                    <h4>{info?.attributes?.education}</h4></div>
                                {/* <p className="name">{info?.attributes?.name}</p>
                                <p className="education">{info?.attributes?.education}</p> */}
                            </div>
                            <div className="image">
                                <span className="red"></span>
                                <span className="blue"></span>
                                <span className="orange"></span>
                                <div className="custom-img">
                                  <img src={info.attributes.avatar} />
                                </div>
                            </div>
                        </li>        
                    ): <h3 className="text-center w-100">No records found</h3>
                    }
                </ul>
            </div>

            <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start

// Customizable Area End
// export default MyCourses;
const TestimonialsToast = withSnackBar(Testimonials);
const TestimonialsWithLoader = withLoader(TestimonialsToast);
export default withRouter(TestimonialsWithLoader);