// @ts-nocheck
import React from "react";
import {
    View,
    Text,
    Platform,
    StyleSheet,
    SafeAreaView,
    ScrollView,
    // Customizable Area Start
    // Button
    // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import QuestionBankController, {
    Props,
    configJSON
} from "./QuestionBankController.web"
import Footer from "../../../components/src/Footer.web"
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { withRouter } from "react-router";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MyFooterWeb from "./MyFooter.web";
import HeaderWeb from "./Header.web";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomButton from "../../../components/src/customButton.web";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
class QuestionBank extends QuestionBankController {

    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        document.body.scrollTop = 0;
        this.getQuestionsAndAnswers()
    }

    getVimeo = (videoUrl) => {
        this.isMyScriptLoaded()
       let video_url =  videoUrl.split('/').pop();
        return video_url
      }
      isMyScriptLoaded = () => {
        let url = "https://player.vimeo.com/api/player.js";
        var scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--;) {
            if (scripts[i].src == url) return true;
        }
        return false;
    }

    getId = (url: any) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11)
          ? match[2]
          : null;
      }

    render() {
        const classes: any = this.props.classes;
        const qaData = this.state.qaData
        const questions = this.state.questions
        return (
            <div className="top-gap">
                {/* <HeaderProfileWeb /> */}
                <HeaderWeb />
                <div className="section-heading">
                    <h3 ><span>Question Bank</span></h3>
                </div>
                <div className="container">
                    <div className="question-bank-box">
                        <ul>
                            {(questions && (questions.length > 0))
                                ? questions.map((ele: any, index: number) =>
                                    <li key={index} className="question-bank-block" >
                                        <Grid container alignItems="center" className="question-bank-widget">
                                            <Grid item md={8}>
                                                <p style={{ color: '#3794FF', fontWeight: 900 }}>Question {index + 1}</p>
                                                <p style={{ color: '#454545', fontWeight: 900 }}>
                                                    { ele?.attributes?.question &&
                                                    <div>{ele?.attributes?.question}</div>
                                                    }
                                                    {ele?.attributes?.has_image && 
                                                        <img className="question-image" src={ele?.attributes?.question_image} alt={ele?.attributes?.question} />
                                                    }
                                                    {ele?.attributes?.has_video && 
                                                        <>
                                                        { ele?.attributes?.video.includes('vimeo') && 
                                                            <iframe className="question-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.video)} height="200" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                                        }
                                                        {
                                                            this.getId(ele?.attributes?.video) 
                                                            ?
                                                            <iframe className="question-video" height="200"
                                                                src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.video)}>
                                                            </iframe>
                                                            : null
                                                        }
                                                        </>
                                                    }
                                                </p>
                                                <RadioGroup row aria-label="position" name="position" defaultValue="top"
                                                    onChange={(e) => this.selectAnswer(e, ele, index)}
                                                    name={this.state.selectedSubject + this.state.selectedSubjectId}>
                                                    <Grid container>
                                                        <Grid key={ele?.attributes?.option1_text} item md={(ele?.attributes?.has_option1_image || ele?.attributes?.has_option1_video) ? '12' : '6'}>
                                                            <FormControlLabel value={ele?.attributes?.option1_text} control={<Radio color="primary" />} label={ele?.attributes?.option1_text} />
                                                            {(ele?.attributes?.has_option1_image || ele?.attributes?.has_option1_video) && 
                                                            <div>
                                                                {ele?.attributes?.has_option1_image && 
                                                                    <img className="answer-image" src={ele?.attributes?.option1_image} alt="option1 image" />
                                                                }
                                                                {ele?.attributes?.has_option1_video && 
                                                                    <>
                                                                    {
                                                                        ele?.attributes?.option1_video && 
                                                                        <>
                                                                        {
                                                                            this.getId(ele?.attributes?.option1_video) 
                                                                            ?
                                                                            <iframe className="answer-video" height="150"
                                                                                src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.option1_video)}>
                                                                            </iframe>
                                                                            : null
                                                                        }
                                                                        { ele?.attributes?.option1_video.includes('vimeo') && 
                                                                            <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.option1_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                                                        }
                                                                        </>
                                                                    }
                                                                    </>
                                                                }
                                                            </div>
                                                            }
                                                        </Grid>
                                                        <Grid key={ele?.attributes?.option2_text} item md={(ele?.attributes?.has_option2_image || ele?.attributes?.has_option2_video) ? '12' : '6'}>
                                                            <FormControlLabel value={ele?.attributes?.option2_text} control={<Radio color="primary" />} label={ele?.attributes?.option2_text} />
                                                            {(ele?.attributes?.has_option2_image || ele?.attributes?.has_option2_video) && 
                                                            <div>
                                                                {ele?.attributes?.has_option2_image && 
                                                                    <img className="answer-image" src={ele?.attributes?.option2_image} alt="option1 image" />
                                                                }
                                                                {ele?.attributes?.has_option2_video && 
                                                                    <>
                                                                    {
                                                                        ele?.attributes?.option2_video && 
                                                                        <>
                                                                        {
                                                                            this.getId(ele?.attributes?.option2_video) 
                                                                            ?
                                                                            <iframe className="answer-video" height="150"
                                                                                src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.option2_video)}>
                                                                            </iframe>
                                                                            : null
                                                                        }
                                                                        { ele?.attributes?.option2_video.includes('vimeo') && 
                                                                            <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.option2_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                                                        }
                                                                        </>
                                                                    }
                                                                    </>
                                                                }
                                                            </div>
                                                            }
                                                        </Grid>
                                                        <Grid key={ele?.attributes?.option3_text} item md={(ele?.attributes?.has_option3_image || ele?.attributes?.has_option3_video) ? '12' : '6'}>
                                                            <FormControlLabel value={ele?.attributes?.option3_text} control={<Radio color="primary" />} label={ele?.attributes?.option3_text} />
                                                            {(ele?.attributes?.has_option3_image || ele?.attributes?.has_option3_video) && 
                                                            <div>
                                                                {ele?.attributes?.has_option3_image && 
                                                                    <img className="answer-image" src={ele?.attributes?.option3_image} alt="option1 image" />
                                                                }
                                                                {ele?.attributes?.has_option3_video && 
                                                                    <>
                                                                    {
                                                                        ele?.attributes?.option3_video && 
                                                                        <>
                                                                        {
                                                                            this.getId(ele?.attributes?.option3_video) 
                                                                            ?
                                                                            <iframe className="answer-video" height="150"
                                                                                src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.option3_video)}>
                                                                            </iframe>
                                                                            : null
                                                                        }
                                                                        { ele?.attributes?.option3_video.includes('vimeo') && 
                                                                            <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.option3_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                                                        }
                                                                        </>
                                                                    }
                                                                    </>
                                                                }
                                                            </div>
                                                            }
                                                        </Grid>
                                                        <Grid key={ele?.attributes?.option4_text} item md={(ele?.attributes?.has_option4_image || ele?.attributes?.has_option4_video) ? '12' : '6'}>
                                                            <FormControlLabel value={ele?.attributes?.option4_text} control={<Radio color="primary" />} label={ele?.attributes?.option4_text} />
                                                            {(ele?.attributes?.has_option4_image || ele?.attributes?.has_option4_video) && 
                                                            <div>
                                                                {ele?.attributes?.has_option4_image && 
                                                                    <img className="answer-image" src={ele?.attributes?.option4_image} alt="option1 image" />
                                                                }
                                                                {ele?.attributes?.has_option4_video && 
                                                                    <>
                                                                    {
                                                                        ele?.attributes?.option4_video && 
                                                                        <>
                                                                        {
                                                                            this.getId(ele?.attributes?.option4_video) 
                                                                            ?
                                                                            <iframe className="answer-video" height="150"
                                                                                src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.option4_video)}>
                                                                            </iframe>
                                                                            : null
                                                                        }
                                                                        { ele?.attributes?.option4_video.includes('vimeo') && 
                                                                            <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.option4_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                                                        }
                                                                        </>
                                                                    }
                                                                    </>
                                                                }
                                                            </div>
                                                            }
                                                        </Grid>
                                                        {/* {(ele?.attributes?.answers?.data && (ele?.attributes?.answers?.data.length > 0)) &&
                                                            ele?.attributes?.answers?.data.map((ele, ind) =>
                                                                <Grid key={ele?.id} item md={6}>
                                                                    <FormControlLabel value={ele?.attributes?.ans_text} control={<Radio color="primary" />} label={ele?.attributes?.ans_text} />
                                                                </Grid>
                                                            )
                                                        } */}
                                                    </Grid>
                                                </RadioGroup>
                                            </Grid>
                                            {ele.showBox != '' &&
                                                <Grid item md={4}>
                                                    <div className="answer-box" style={{ border: "2px solid " + ((ele?.isAnswer) ? '#42A080' : '#EA595F'), backgroundColor: ((ele?.isAnswer) ? '#E5F8F2' : '#FFFFFF') }}>
                                                        <p style={{ paddingBottom: '15px', borderBottom: '1px solid' + ((ele?.isAnswer) ? '#42A080' : '#EA595F') }}> {(ele?.isAnswer) ? 'Your Answer is Correct.' : 'The Correct Answer is' + ' ' + ele.correctAnswer}</p>
                                                            <p>Reason:{ele?.reasonText}</p>
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>
                                    </li>

                                )
                                : <h3 className="text-center w-100">No records found</h3>
                            }
                        </ul>
                        <div className="question-bank-footer">
                            <CustomButton label="Back to Home" className="btn-curve btn-danger" onClick={() => this.props.history.push('/')} />
                            {/* <CustomButton label="Finish Exam" className="btn-curve btn-primary" onClick={() => this.props.history.push('/')} /> */}
                        </div>
                    </div>
                </div>



                <MyFooterWeb />
            </div>
        )
    }
}

// export default withStyles(useStyles)(QuestionBank);
const QuestionBankToast = withSnackBar(QuestionBank);
const QuestionBankWithLoader = withLoader(QuestionBankToast);
export default withRouter(QuestionBankWithLoader);