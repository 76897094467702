import React, { PureComponent } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import StorageProvider from "../../framework/src/StorageProvider";

export type Props = RouteProps & {
  role: string;
};
export interface S {
  token: any;
  loading: boolean;
}
export default class ProtedtedRoute extends PureComponent<Props, S> {
  state = {
    token: null,
    loading: true,
  };
  async componentDidMount() {
    let token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
        loading: false
      });
    } else {
        this.setState({loading: false})
    }
  }
  render() {
    return !this.state.loading ? (
       this.state.token === null ? (
        <Redirect to="/login" />
      ) : (
        <Route {...this.props} />
      )
    ) : null;
  }
}