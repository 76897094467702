import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, razorPayKey } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  myCourses: any[];
  joinMeeting: boolean;
  paymentData: any;
  orderId: null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyCoursesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  myCoursesGetApiCallId: string = "";
  updatePaymentApiCallId: string = "";
  updatePaymentStatusApiCallId: string = "";
  myCourses: any[];
  joinMeeting: boolean;
  paymentData: any;
  orderId: null;
  paymentData1: any;
  orderId1: null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      myCourses: [],
      joinMeeting: false,
      paymentData: {},
      orderId: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.myCoursesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.myCourses = responseJson.data;
            this.paymentData = responseJson?.data?.attributes?.order?.data?.attributes?.details;
            this.orderId = responseJson?.data?.attributes?.order?.data?.attributes?.id;
            this.setState({
              myCourses: this.myCourses,
              paymentData: this.paymentData,
              orderId: this.orderId
            });
            this.props.hideLoader();
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.updatePaymentApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            this.props.hideLoader();
            this.props.showToast({type : 'success', message : 'Updated Successfully'});
            this.getMyCourses()
            // if(this.paymentData1 && this.orderId1) {
            //   this.displayRazorpay(responseJson?.data?.attributes?.order?.data?.attributes, responseJson?.data?.attributes?.order?.data?.id)
            // }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.updatePaymentStatusApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success
            this.props.showToast({type : 'success', message : 'Paid Successfully'})
            this.props.history.push('/my-courses')
            window.location.reload()
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Payment failed.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } 
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getMyCourses() {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.myCoursesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetMyCourses
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetMyCourses,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetMyCourses
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updatePayment(id:any) {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePaymentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUpdatePayment + id + '/update_payment_status'
    );

    const header = {
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiUpdatePayment
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  loadScript(src:any) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }
  async displayRazorpay(paymentData: any, enrolledId: any) {
    const self = this;
    this.props.hideLoader();
    const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }
    const options = {
        key: razorPayKey, // Enter the Key ID generated from the Dashboard
        amount: paymentData?.amount,
        currency: paymentData?.currency,
        name: "Nursing Experts",
        order_id: paymentData?.pay_id,
        handler: function(response: any) {
          if(response) {
            self.updatePaymentStatus(enrolledId);
          }
        },
        prefill: {
            name: localStorage.getItem('userName'),
            email: localStorage.getItem('email'),
            contact: localStorage.getItem('phoneNumber'),
        },
    };
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  }

  updatePaymentStatus(enrolledId: any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePaymentStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiRazorPay + `${enrolledId}/update_payment_status`
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiRazorPay,
      token: localStorage.getItem('authToken')
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiRazorPay
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}

