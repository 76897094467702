// @ts-nocheck
import React from 'react';
import HeaderProfileWeb from '../../../components/src/HeaderProfile.web';
import PrivacyPolicyController, { Props } from './PrivacyPolicyController.web';
import { Grid,withStyles } from '@material-ui/core';
import Footer from '../../../components/src/Footer.web';
import MyFooterWeb from './MyFooter.web';
import HeaderWeb from './Header.web';


const useStyles = () => ({
    numbers: {
        position: 'relative',
        padding: '0 10px',
        fontSize: '36px',
        fontWeight: 500,
        display: 'inline-block',
        textAlign: 'center',
        "&:after": {
            content: "no-close-quote",
            height: '50px',
            width: '50px',
            borderRadius: '25px',
            background: '#d0e6ff',
            display: 'inline-block',
            position: 'absolute',
            left: '6px',
            top: '-5px',
            zIndex: "-1",
        },
    },
    circle: {
        position: 'relative',
        padding: '0 10px',
        fontSize: '36px',
        fontWeight: 500,
        display: 'inline-block',
        textAlign: 'center',
        "&:after": {
            content: "no-close-quote",
            height: '20px',
            width: '20px',
            borderRadius: '25px',
            background: '#3895FF',
            display: 'inline-block',
            position: 'absolute',
            left: '0px',
            top: '18px',
            zIndex: "-1",
        }
    },
    block: {
        marginBottom: '10px',
        padding: '2%',
        borderStyle: 'dashed',
        borderWidth: '0px 0px 3px 0px',
        borderColor: '#f0f0f0'
    },
    btnHome: {
        backgroundColor: 'red',
        color: 'white'
    },
    btnFinish: {
        backgroundColor: '#3895FF',
        color: 'white'
    },
    answer: {
        backgroundColor: 'white',
        padding: '3%',
        border: '2px solid red',
        borderRadius: '8px',
        width: '100%'
    },
    container: {
        boxShadow: '2px 2px 2px 5px #f0f0f0',
        borderRadius: '8px'

    },
});


interface QuestionBank {
    classes: { [className in keyof typeof styles]: string }

}

const privacyData = ["Introduction", "Definitions", "Information Collection and Use", "Types of Data Collected", "Use of Data"
,"Retetion of Data" ,"Transfer of Data", "Disclosure of Data", "Security of Data","Your Data Protection Rights Under General Data Protection(GDPR)",
"Your Data Protection Rights Under The Callifornia Privacy Protection Act(CalOPPA)",
"Your Data Protection Rights Under The Callifornia Consumer Privacy Act(CCPPA)",
"Service Provider","Service Provider","Analytics","CI/CD Tools","Behavioral ReMarketing",
"Payments" , "Links to Other Sites",`Children's Privacy`,"Changes to this Privacy Policy","Contact Us"];

class PrivacyPolicy extends PrivacyPolicyController {

    constructor(props: Props & QuestionBank) {
        super(props);
    }

    async componentDidMount () {
        document.body.scrollTop = 0;
        this.getPrivacyData();
      }


    render() {

        const classes: any = this.props.classes;
        return (
            <>
                {/* <HeaderProfileWeb /> */}
                <HeaderWeb />
                <Grid container>

                    <Grid item md={12} style={{ marginTop: '5%', textAlign: 'center' }}>
                    <div className="section-heading">
                            <h3><span>{"Privacy Policy"}</span></h3>
                        </div>
                    </Grid>


                    <Grid container>
                        <Grid item md={2}></Grid>
                        <Grid item md={8}>
                            <Grid container>
                                {this.state.privacyList.map((data:string, index:number) => <>
                                    <Grid md={12}>
                                        {/* <span className={classes.circle}> </span> */}
                                        {/* <div style={{ fontWeight: 900, paddingLeft: '40px' }}>
                                            <h3 style={{color:'black'}}>{index+1}. {data.attributes.name}</h3>
                                             */}
                                             <div dangerouslySetInnerHTML={ { __html: data.attributes.description} }></div>
                                        {/* </div> */}
                                    </Grid>
                                </>)}

                            </Grid>
                        </Grid>
                        <Grid item md={1}></Grid>
                    </Grid>

                </Grid>

                <Grid>
                    <MyFooterWeb />
                </Grid>

            </>
        )
    }




}

export default withStyles(useStyles)(PrivacyPolicy);
