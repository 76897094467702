// @ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import HomeScreenController, {
  Props,
  configJSON
} from "./HomeScreenController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import Modal from '@material-ui/core/Modal';
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import MyFooter from "./MyFooter.web";
import { NavLink } from 'react-router-dom'
import HeaderWeb from "./Header.web";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

// interface Props {
//   navigation: any;
//   id: string;
//   // Customizable Area Start
//   // Customizable Area End
// }

// Customizable Area Start
// interface S { }

// interface SS { }
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  },
]
const whyNursingExperts = [
  { name: 'Multiple Course Options' },
  { name: 'Highly Qualified Faculty' },
  { name: 'Study Materials' },
  { name: 'Placement Support' },
  { name: 'Online Exams' },
  { name: 'Proved Performance' }
]
class HomeScreen extends HomeScreenController {

  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    this.state = {
      openModel: false
    }
  }

  async componentDidMount () {
    document.body.scrollTop = 0;
    this.getSettingsData()
    this.getHome()
    this.getAbout()
    let _hash = window.location.hash
    if(_hash == '#batches') {
      document.body.scrollTop = 600;
    }
  }

  handleOpen = () => {
    this.setState({openModel:true});
  };

  handleClose = () => {
    this.setState({openModel:false});
  };

  generateLink = (link) => {
    let _link = link.toLowerCase().replaceAll(' ', '-')
    if(_link == 'entrance-exam' || _link == 'nursing-officer') {
      _link = 'apply-now'
    } else if (_link == 'question-bank') {
      _link = 'subjects-topics'
    } else if(_link == 'test-series-package') {
      _link = '/course/test-series-package'
    }
    return _link
  }
  
  render() {
    const myColors = ['3895FF', '#0DBD7F', 'red', 'green', 'blue', 'grey', '#ffac3b', '#9649cb' ];
    const randomColor = myColors[Math.trunc(Math.random() * myColors.length)];
    const sliderList = this.state?.section_1
    const courses = this.state?.section_2?.attributes?.tiles
    const newCourses = this.state?.section_3?.attributes?.tile
    const upComingBatches = this.state?.section_4?.attributes?.upcoming_batches?.data
    const whyNursingExperts = this.state?.section_5?.attributes?.tiles
    const successStory = this.state?.section_6?.attributes
    const testimonials = this.state?.section_7
    const directorData = this.state?.section_8?.attributes
    const mentors = this.state?.section_9
    // let section1 = this.state.home?["section1"];
    const { navigation } = this.props;
    const _this = this;
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    }

    const testimonialsSettings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    }
    const settings2 = {
      dots: true,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        {sliderList !== null &&
        <div className="section-1">
        <Slider className="demo-slider-widget" ref={slider => (this.slider = slider)} {...settings}>
              {sliderList && sliderList?.length > 0 &&
                sliderList.map((ele:any) =>
                <div key={ele.id} className="home-slider" >
                  <div className="top-section">
                    <div className="top-section-left">
                        <h1>{ele?.attributes.title}</h1>
                        <p>{ele?.attributes.description}</p>
                        <Button variant="contained" color="primary" onClick={() => this.props.history.push('Contactus')}>CONTACT US</Button>
                    </div>
                    <div className="top-section-right">
                        {/* <img data-lazy={ele?.attributes?.image} src={ele?.attributes?.image} /> */}
                      <div className="home-slide-image">
                        <div className="image">
                          <span className="blue"></span>
                          <span className="orange"></span>
                          <span className="green"></span>
                          <div className="custom-img">
                            <img src={ele?.attributes?.image} />
                          </div>
                        </div>
                      </div>
                    </div>
        </div>
         </div>
                )
              }
            </Slider>
        </div>
        }
        {this.state?.section_2 !== null &&
        <div className=" courses">
          <div className="container">
            <div className="section-heading w-800-center">
              <h3><span>{this.state?.section_2?.attributes?.title}</span></h3>
              <p>{this.state?.section_2?.attributes?.description}</p>
            </div>
            <ul className="courses-list">
              {courses && courses.length > 0 &&
                courses.map((ele:any) =>
                  <li key={ele.title}>
                    <NavLink to={this.generateLink(ele.title)} className="course-info">
                      <span className='course-color-circle' style={{ backgroundColor: ele.color, boxShadow: '0 0 0 10px' + ele.color + '80' }}></span>
                      <h3 className="course-type">{ele.title}</h3>
                      <p className="course-desc">{ele.description}</p>
                    </NavLink>
                  </li>
                )}
                {courses && courses.length > 0 &&
                  <li className="li-imgage">
                    <div className="courses-image">
                      <img src={require('./Group.png')} />
                    </div>
                  </li>
                }
            </ul>
          </div>
        </div>
        }
        {this.state?.section_3 !== null &&
        <>
        <div className="section-heading w-800-center">
          <h3><span>{this.state?.section_3?.attributes?.title}</span></h3>
          <p>{this.state?.section_3?.attributes?.description}</p>
        </div>
        <div className="new-courses">
          <div className="container">
            <Slider className="course-slider-widget" {...settings2}>
              {newCourses && newCourses.length > 0 &&
                newCourses.map((ele:any, ind:any) =>
                  <div className="course-slider" key={ele.title}>
                    <div className="course-content">
                      <h3 style={{color: myColors[ind]}}>{ele.title}</h3>
                      <Button className="btn-curve btn-no-cursor" variant="contained" color="primary" style={{ backgroundColor: myColors[ind], color: '#ffffff',textTransform: 'capitalize' }}> {ele.course}</Button>
                    </div>
                  </div>
                )
              }
            </Slider>
          </div>
        </div>
        </>
        }
          { mentors !== null &&
        <>
        <div className="section-heading w-800-center">
          <h3><span>{mentors?.title}</span></h3>
          <p>{mentors?.description}</p>
        </div>
        <Slider className="course-slider-widget testimonials-slider" {...testimonialsSettings}>
              {mentors?.mentor_details?.data && mentors?.mentor_details?.data.length > 0 &&
                mentors?.mentor_details?.data.map((ele:any, ind:any) =>
                <>
                  <div className="our-testimonials container" key={ele.id}>
                  <div className="our-testimonials-left">
                    {ele?.attributes?.description && ele?.attributes?.description?.match(/[\r\n|-]+/)
                              ? ele?.attributes?.description?.split(/[\r\n|-]+/).map((i: any, key: any) => {
                                return <p className="num-gap" key={key}>{i}</p>
                              })
                              : <p>{ele?.attributes?.description}</p>
                            }
                    <div className="name">
                      <h2>{ele?.attributes?.faculty_name} </h2>
                      <h4>{ele?.attributes?.designation}</h4></div>
                  </div>
                  <div className="our-testimonials-right">
                    {/* <img src={directorData?.thumbnail_image} /> */}
                    <div className="global-study-image">
                      <div className="image">
                        <span className="red"></span>
                        <span className="blue"></span>
                        <span className="orange"></span>
                        <span className="green"></span>
                        <div className="custom-img">
                          <img src={ele?.attributes?.mentor_image} />
                            </div>
                            </div>
                            </div>
                  </div>
                </div>
                </>
                )
              }
            </Slider>
        </>
  }
        {this.state?.section_4 !== null &&
        <div className="container">
          <div className="section-heading-left">
          <h3><span>{this.state?.section_4?.attributes?.title}</span></h3>
          <p>{this.state?.section_4?.attributes?.description}</p>
          </div>
          <div className="upcoming-batches">
            {
              upComingBatches && upComingBatches.length > 0 &&
              upComingBatches.map((ele: any, ind) => 
              <div key={ind} className="batch-info">
                <div className="batch-info-image">
                  <img src={ele.attributes.image} alt="" />
                  </div>
                <h2>{ele.attributes.title}</h2>
                <p>{ele.attributes.description}</p>
                {/* <a>read more</a> */}
              </div>
            )}
          </div>
        </div>
        }
        {this.state?.section_5 !== null &&
        <div className="why-nursing-experts container">
          <div className="section-heading w-800-center">
          <h3><span>{this.state?.section_5?.attributes?.title}</span></h3>
          <p>{this.state?.section_5?.attributes?.description}</p>
          </div>
          <ul>
            {whyNursingExperts && whyNursingExperts.length > 0 &&
              whyNursingExperts.map((ele: any) =>
                <li key={ele.title}>
                  <span className="expert-image">
                    <img src={require('./expert.png')} />
                    </span>
                  <span className="expert-name">{ele.title}</span></li>
              )
            }
          </ul>
        </div>
        }
        {successStory !== null &&
        <div className="container">
          <div className="success-story">
            <div className="success-story-left">
              <h3><span>{successStory?.name}</span></h3>
              {successStory?.bio && successStory?.bio?.match(/[\r\n|-]+/)
                      ? successStory?.bio?.split(/[\r\n|-]+/).map((i: any, key: any) => {
                        return <p className="num-gap" key={key}>{i}</p>
                      })
                      : <p>{successStory?.bio}</p>
                    }
            </div>
            <Modal
              open={this.state.openModel}
              onClose={this.handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="modal-data"
            >
              <div>
                {/* <iframe className="success-story-iframe" width="420" height="315"
                  src={`https://www.youtube.com/embed/${successStory?.video_url.split('=').pop()}?autoplay=1`} allow='autoplay' allowFullScreen="true">
                </iframe> */}
                {
                  successStory?.video_url.includes('vimeo')  
                    ?  <iframe className="success-story-iframe" src={`https://player.vimeo.com/video/` + successStory?.video_url.split('/').pop()+'?autoplay=1'} width="420" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true" allow='autoplay'></iframe>
                    : <>{
                      successStory?.video_url.includes('youtu')
                      ? <>{
                        successStory?.video_url.includes('=')
                        ? <iframe className="success-story-iframe" width="420" height="315"
                        src={`https://www.youtube.com/embed/${successStory?.video_url.split('=').pop()}?autoplay=1`} allow='autoplay' allowFullScreen="true">
                      </iframe>
                      : <iframe className="success-story-iframe" width="420" height="315"
                      src={`https://www.youtube.com/embed/${successStory?.video_url.split('/').pop()}?autoplay=1`} allow='autoplay' allowFullScreen="true">
                    </iframe>
                      } </>
                      : null
                    } </>
                }
              </div>
              {/* <video className="success-story-video" src={successStory?.video_url} controls autoPlay={true} preLoad="auto" loop></video> */}
            </Modal>
            <div className="success-story-right" onClick={this.handleOpen}>
              <span className="rectangle-box green"></span>
              <span className="rectangle-box red"></span>
              <img src={successStory?.thumbnail_image} />
            </div>
          </div>
        </div>
  }
  { testimonials !== null &&
        <>
        <div className="section-heading w-800-center">
          <h3><span>{testimonials?.heading?.title}</span></h3>
          <p>{testimonials?.heading?.sub_title}</p>
        </div>
        <Slider className="course-slider-widget testimonials-slider" {...testimonialsSettings}>
              {testimonials?.content?.data && testimonials?.content?.data.length > 0 &&
                testimonials?.content?.data.map((ele:any, ind:any) =>
                <>
                  <div className="our-testimonials container" key={ele.id}>
                  <div className="our-testimonials-left">
                    {ele?.attributes?.note && ele?.attributes?.note?.match(/[\r\n|-]+/)
                              ? ele?.attributes?.note?.split(/[\r\n|-]+/).map((i: any, key: any) => {
                                return <p className="num-gap" key={key}>{i}</p>
                              })
                              : <p>{ele?.attributes?.note}</p>
                            }
                    <div className="name">
                      <h2>{ele?.attributes?.name} </h2>
                      <h4>{ele?.attributes?.education}</h4></div>
                  </div>
                  <div className="our-testimonials-right">
                    {/* <img src={directorData?.thumbnail_image} /> */}
                    <div className="global-study-image">
                      <div className="image">
                        <span className="red"></span>
                        <span className="blue"></span>
                        <span className="orange"></span>
                        <span className="green"></span>
                        <div className="custom-img">
                          <img src={ele?.attributes?.avatar} />
                            </div>
                            </div>
                            </div>
                  </div>
                </div>
                </>
                )
              }
            </Slider>
        </>
  }
        { directorData !== null &&
        <>
        <div className="section-heading w-800-center headding-mb">
          <h3><span>Director's Message</span></h3>
        </div>
        <div className="our-testimonials container">
          <div className="our-testimonials-left">
            {directorData?.message && directorData?.message?.match(/[\r\n|-]+/)
                      ? directorData?.message?.split(/[\r\n|-]+/).map((i: any, key: any) => {
                        return <p className="num-gap" key={key}>{i}</p>
                      })
                      : <p>{directorData?.message}</p>
                    }
            <div className="name">
              <h2>{directorData?.name} </h2>
              <h4>{directorData?.qualification}</h4></div>
          </div>
          <div className="our-testimonials-right">
            {/* <img src={directorData?.thumbnail_image} /> */}
            <div className="global-study-image">
              <div className="image">
                <span className="red"></span>
                <span className="blue"></span>
                <span className="orange"></span>
                <span className="green"></span>
                <div className="custom-img">
                  <img src={directorData?.thumbnail_image} />
                    </div>
                    </div>
                    </div>
          </div>
        </div>
        </>
  }
        {/* <div className="container">
          <div className="register">
            <img src={require('./strip.png')} />
            <div className="register-info">
              <div className="register-left">
                <h3>Many Reasons to chose Nursing Experts</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
              <div className="register-right">
                <Button variant="contained" className="btn-curve btn-register">Register Now</Button>
              </div>
            </div>
          </div>
        </div> */}
          {/* <Footer /> */}
          <MyFooter />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
const HomeScreenToast = withSnackBar(HomeScreen);
const HomeScreenWithLoader = withLoader(HomeScreenToast);
export default withRouter(HomeScreenWithLoader);
// export default HomeScreen;