// @ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import GlobalStudyCourseController, {
  Props,
  configJSON
} from "./GlobalStudyCourseController.web"
import Footer from "../../../components/src/Footer.web"
import CustomButton from "../../../components/src/customButton.web"
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MyFooterWeb from "./MyFooter.web";
import HeaderWeb from "./Header.web";
// import Dropzone from "react-dropzone";
// import UploadService from "./upload-files.service";
// import "bootstrap/dist/css/bootstrap.min.css";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  },
]
const ourPrograms = [
  {
    image: 'expert.png',
    title: 'PREPERATION FOR GOVERNMENT JOBS',
    color: '#ff0000',
  },
  {
    image: 'expert.png',
    title: 'INTERVIEW PREPARATION',
    color: '#0000ff'
  },
  {
    image: 'expert.png',
    title: 'TEST SERIES (AS PER REAL BASE EXAM)',
    color: '#00ff00'
  },
]
const squareCircles = [
  {title: 'IELTS', bgColor: '#2E81F7', url:'ielts'},
  {title: 'NCLEX RN', bgColor: '#0CBB80', url:'nclex-rn'},
  {title: 'OET', bgColor: '#FFAE3B', url:'oet'},
]
class GlobalStudyCourse extends GlobalStudyCourseController {
  fileObj = [];
  fileArray = [];
  // selectedFiles = [];

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    this.state = {
      openDialog: false
    }
    this.state = {
      progressInfos: [],
      message: [],

      fileInfos: [],
    };
  }
  uploadMultipleFiles = (e) => {
    if(e.target.files.length>0) {
      let fileType = e.target.files[0].name.split('.').pop().toLowerCase()
      // this.fileObj.push(e.target.files)
      // let _files:any[] = Array.from(e.target.files)
      // _files.map((ele, i) => {
      //   this.selectedFiles.push(ele)
      // })
      // _files.map((file)=>{
      //   this.fileArray.push({url:URL.createObjectURL(file), type: file.type})
      // })
      // this.setState({ selectedFile: e.target.files[0], files: this.selectedFiles, previewFiles: this.fileArray}, () => { 
      // }
      // )
      if(fileType == 'pdf' || fileType == 'jpg' || fileType == 'jpeg' || fileType == 'png') {
        this.getAddFormAttachment(e.target.files[0])
        this.setState({errorDoc: false})
      } else {
        this.setState({errorDoc: true})
      }
    }
    e.target.value = ""
  }
  onDeleteFile = (id) => {
    this.setState({openDialog: true, selectedId: id});
    // this.state.previewFiles.map((ele, ind)=>{
    //   if(JSON.stringify(ele.url) == url){
    //     this.state.previewFiles.splice(ind, 1)
    //     this.state.files.splice(ind, 1)
    //     this.setState({previewFiles: this.state.previewFiles, files: this.state.files})
    //   }
    // })
  }
  handleClickOpen = () => {
    this.setState({openDialog: true});
  };

  handleCloseDialog = () => {
    this.setState({openDialog: false});
  };
  getFullName = (e) => {
    this.setState({fullName: e.target.value})
  }
  getDob = (e) => {
    this.setState({dob: e.target.value})
  }
  getEmail = (e) => {
    this.setState({email: e.target.value})
  }
  getDegree = (e) => {
    this.setState({dep_degree: e.target.value})
  }
  getContact = (e) => {
    this.setState({contactNo: e.target.value})
  }
  setSelectedQualification = (data: any) => {
    const name_id = data.target.value.split('_');
    const name = data.target.value;
    const id = data;
    this.setState({qualification: name_id[0], selectedQualificationID: name_id[1]})
  }

  async componentDidMount() {
    document.body.scrollTop = 0;
    this.setState({
      classFullName: 'form-control',
      classDob: 'form-control',
      classQualification: 'form-select',
      classEmail: 'form-control',
      classContactNo: 'form-control',
      classSelectedFiles: 'file-upload-area'
    })
    this.getQualifications()
    this.getGlobalStudyCourses()
  }
  numberOnly = (evt: any) => {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  }
  render() {
    const { navigation } = this.props;
    const _this = this;
    const { globalStudyCourse } = this.state;
    const data = globalStudyCourse
    const squareCircles = globalStudyCourse?.courses?.data
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="container">
          <div className="section-heading">
            <h3><span>{data?.title}</span></h3>
            <p></p>
          </div>
          <Grid container spacing={6}>
            <Grid item sm={12} xs={12} md={6}>
              <div className="address">
                <p>
                  <span className="quote start-quote">
                  <img src={require('./start-quote.png')} alt="" />
                  </span>
                  <span className="address-info">{data?.quote}</span>
                  <span className="quote end-quote">
                    <img src={require('./end-quote.png')} alt="" />
                  </span>
                  </p>
              </div>
              
              <ul className="square-course">
              {squareCircles && 
              squareCircles.map((ele:any)=>
                <li key={ele?.id}>
                  <NavLink to={'course/courses/' + ele?.id}>
                  <div className="square-content">
                  <span className='square-color-circle' style={{ backgroundColor:((ele.attributes?.title == 'IELTS') ? '#2E81F7' : (ele.attributes?.title == 'NCLEX RN') ? '#0CBB80' : '#FFAE3B'), boxShadow: '0 0 0 20px ' + ((ele.attributes?.title == 'IELTS') ? '#2E81F7' : (ele.attributes?.title == 'NCLEX RN') ? '#0CBB80' : '#FFAE3B') + '80' }}></span>
                    <h3>{ele?.attributes?.title}</h3>
                  </div>
                  </NavLink>
                </li>
                )}
              </ul>
            </Grid>
            <Grid item sm={12} md={6}>
              <div className="global-study-image">
                {/* <img src={this.state?.applyDetails?.attributes?.apply_image} alt="" /> */}
              <div className="image">
                <span className="red"></span>
                <span className="blue"></span>
                <span className="orange"></span>
                <span className="green"></span>
                <div className="custom-img">
                  <img src={data?.study_image} />
                </div>
              </div>
              </div>
            </Grid>
          </Grid>
          <div className="registration">
            <h3>Registration for Nursing NCLEX RN / OETS / IELTS</h3>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={6}>
                <div className="form-group">
                  <input type="text" className={this.state.classFullName} placeholder="Full Name" onChange={(e)=>this.getFullName(e)}/>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                <div className="form-group">
                  <input type="date" className={this.state.classFullName} placeholder="Date Of Birth" onChange={(e)=>this.getDob(e)} required />
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                <div className="form-group">
                  <input type="email" className={this.state.classFullName} placeholder="Email ID" onChange={(e)=>this.getEmail(e)}/>
                  {
                    this.state.showErrorEmail && 
                      <div className="error">Invalid Email</div>
                  }
                </div>
              </Grid>
              {/* <Grid item sm={12} xs={12} md={6}>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Diploma / Degree" onChange={(e)=>this.getDegree(e)}/>
                </div>
              </Grid> */}
              <Grid item sm={12} xs={12} md={6}>
                <div className="form-group">
                  <select className={this.state.classQualification}
                    onChange={(e)=>{this.setSelectedQualification(e)}}
                    defaultValue={'DEFAULT'}
                  >
                    <option value="DEFAULT" disabled >Diploma / Degree</option>
                    {
                      this.state.qualifications && this.state.qualifications.length > 0 && 
                      this.state.qualifications.map((ele:any, ind:any)=>
                      <option key={ele.id} id={ele.id} value={ele.attributes.name +'_'+ ele.id} >{ele.attributes.name}</option>
                      )
                    }
                  </select>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                <div className="form-group">
                  <input type="number" className={this.state.classContactNo} placeholder="Phone Number" onChange={(e)=>this.getContact(e)} onKeyPress={(e)=>this.numberOnly(e)}/>
                  {
                    this.state.errorMobile && 
                        <div className="error">
                          Invalid Mobile
                        </div>
                  }
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <div className="form-group">
                  <div className={this.state.classSelectedFiles}>
                    Upload documents
                    <input  type="file" name="" id="uploadedFile" onChange={(e)=>this.uploadMultipleFiles(e)}/>
                  </div>
                  {/* {this.state.selectedFiles && this.state.selectedFiles.length >0 && 
                    this.state.selectedFiles.map((file) => 
                    <div className="selected-file">
                        <img key={file.name} src={require('./'+file.name)} />
                    </div>
                  )} */}
                  <div className="form-group multi-preview">
                    {/* <ul>
                      {this.state.previewFiles && this.state.previewFiles.length > 0 &&
                        this.state.previewFiles.map((ele,ind) => (
                          <li key={ele.url}>
                            { ele.type=='application/pdf'
                              ? <div className="pdf-file"><PictureAsPdfIcon/></div>
                              : (ele.type=="application/vnd.ms-excel.sheet.macroEnabled.12") 
                              ? <div className="pdf-file"><img src={require('./excel.png')} alt="" srcset="" /></div>  
                              : <img src={ele.url} alt="..." />
                            }
                            <a className="delete" onClick={()=>this.onDeleteFile(JSON.stringify(ele.url))}><HighlightOffIcon /></a>
                          </li>
                        ))
                      }
                    </ul> */}
                    <ul>
                      {this.state.selectedFiles && this.state.selectedFiles.length > 0 &&
                        this.state.selectedFiles.map((ele,ind) => (
                          <li key={ele.id} title={ele?.attributes?.document.split('/').pop()}>
                            { ele?.attributes?.document.split('.').pop()=='pdf'
                              ? <div className="upload-div"><div className="pdf-file"><PictureAsPdfIcon/></div></div>
                              : (ele?.attributes?.document.split('.').pop()=="excel") 
                              ? <div className="upload-div"><div className="pdf-file"><img src={require('./excel.png')} alt="" srcset="" /></div></div>  
                              : <div className="upload-div"><img src={ele?.attributes?.document} alt="..." /></div>
                            }
                            <a className="delete" onClick={()=>this.onDeleteFile(ele.id)}><HighlightOffIcon /></a>
                            <div className="doc-name" title={ele?.attributes?.document.split('/').pop()}>{ele?.attributes?.document.split('/').pop()}</div>
                          </li>
                        ))
                      }
                    </ul>
                    <Dialog
              open={this.state.openDialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <span className="dialog-heading">Are you Sure you want to Delete?</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-footer">
                <Button variant="contained" color="secondary" className="btn-logout btn-curve" onClick={()=>this.getDeleteFormAttachment(this.state.selectedId)} color="primary" autoFocus>
                  YES
                </Button>
                <Button variant="contained" color="primary" className="btn-cancel btn-curve" onClick={this.handleCloseDialog} color="primary">
                  NO
                </Button>
              </DialogActions>
            </Dialog>
                </div>
                  {/* <div>
        {progressInfos &&
          progressInfos.map((progressInfo, index) => (
            <div className="mb-2" key={index}>
              <span>{progressInfo.fileName}</span>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  aria-valuenow={progressInfo.percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: progressInfo.percentage + "%" }}
                >
                  {progressInfo.percentage}%
                </div>
              </div>
            </div>
          ))}

        <div className="my-3">
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {selectedFiles &&
                  Array.isArray(selectedFiles) &&
                  selectedFiles.length == 0 &&
                    `Drag and drop files here, or click to select files`
                  }
                </div>
                  {selectedFiles &&
                  Array.isArray(selectedFiles) &&
                  selectedFiles.length ? (
                    <div className="selected-file">
                      {selectedFiles.length > 0 &&
                        selectedFiles.map((file) => 
                        <img src={require('./'+file.name)} />
                        )}
                    </div>
                  ) : null}
                <aside className="selected-file-wrapper">
                  <button
                    className="btn btn-success"
                    disabled={!selectedFiles}
                    onClick={this.uploadFiles}
                  >
                    Upload
                  </button>
                </aside>
              </section>
            )}
          </Dropzone>
        </div>

        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        )}

        {fileInfos.length > 0 && (
          <div className="card">
            <div className="card-header">List of Files</div>
            <ul className="list-group list-group-flush">
              {fileInfos &&
                fileInfos.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    <a href={file.url}>{file.name}</a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div> */}

                </div>
              </Grid>
              {
                this.state.showErrorMessage &&
                <Grid item sm={12} xs={12} md={12}> 
                  <div className="error">
                    Please enter all fields
                  </div>
                </Grid>
              }
              {
                this.state.errorDoc &&
                <Grid item sm={12} xs={12} md={12}> 
                  <div className="error">
                    Please Upload Jpg, Jpeg, Png or Pdf files.
                  </div>
                </Grid>
              }
              <Grid item sm={12} xs={12} md={12}>
                <div className="form-group text-right">
                  <CustomButton label="SUBMIT" className="btn-curve btn-primary" onClick={()=>this.getCourseRegister()}/>
                </div>
              </Grid>
            </Grid>

          </div>
          <div className="documents-required">
            <h3>Documents Required</h3>
            <div dangerouslySetInnerHTML={{ __html: data?.documents_required }}></div>
            {/* <ol>
              <li>Latest CV with Signature</li>
              <li>ID Proof(Passport is neccessary / if not then Adhaar Card)</li>
              <li>Degree or Diploma Certificate</li>
              <li>All Marksheets(10th/12th/BSc Nursing/GNM)</li>
              <li>Registration Certificate</li>
              <li>Internship or Equivalent Certificate(if available)</li>
              <li>Date of Birth Certificate (Not be valid only on the basis of 10th Certificate)</li>
              <li>Experience Certificate if any</li>
              <li>If the Nurse married we need the marriage Certificate.</li>
            </ol> */}
          </div>
        </div>
          <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default GlobalStudyCourse;
const GlobalStudyCourseToast = withSnackBar(GlobalStudyCourse);
export default withRouter(GlobalStudyCourseToast);