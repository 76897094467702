import React, { PureComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import HomeScreen from "../../blocks/ContentManagement/src/HomeScreen.web";
import Vacancy from "../../blocks/ContentManagement/src/Vacancy.web";
import ClassroomCourses from "../../blocks/ContentManagement/src/ClassroomCourses.web";
import OurProgram from "../../blocks/ContentManagement/src/OurProgram.web";
import ScoreCard from "../../blocks/ContentManagement/src/ScoreCard.web";
import RecordedVideos from "../../blocks/ContentManagement/src/RecordedVideos.web";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountSetPassword from "../../blocks/email-account-registration/src/EmailAccountSetPassword.web";
import EmailAccountWelcomeUser from "../../blocks/email-account-registration/src/EmailAccountWelcomeUser.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import ChangePassword from "../../blocks/forgot-password/src/ChangePassword.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import PasswordChanged from "../../blocks/forgot-password/src/PasswordChanged.web";
// import HeaderProfile from "../../components/src/HeaderProfile.web";
// import ScoreCard from "../../components/src/scoreCard.web";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import ProtectedRoute from './ProtectedRoute.web';
import PrivateRoute from './PrivateRoute.web';
import SuccessStories from '../../blocks/ContentManagement/src/SuccessStories.web';
import Testimonials from '../../blocks/ContentManagement/src/Testimonials.web';
import Gallery from "../../blocks/ContentManagement/src/Gallery.web";
import ApplyNow  from "../../blocks/ContentManagement/src/ApplyNow.web";
import CrashCoursePackage  from "../../blocks/ContentManagement/src/CrashCoursePackage.web";
import TestSeriesPackage  from "../../blocks/ContentManagement/src/TestSeriesPackage.web";
import CompleteCoursePackage  from "../../blocks/ContentManagement/src/CompleteCoursePackage.web";
import NeetBsc  from "../../blocks/ContentManagement/src/NeetBsc.web";
import Ielts  from "../../blocks/ContentManagement/src/Ielts.web";
import Oet  from "../../blocks/ContentManagement/src/Oet.web";
import NclexRn  from "../../blocks/ContentManagement/src/NclexRn.web";
import StudyMaterial from "../../blocks/ContentManagement/src/StudyMaterial.web";
import MyCourses from "../../blocks/ContentManagement/src/MyCourses.web";
import GlobalStudyCourse from "../../blocks/ContentManagement/src/GlobalStudyCourse.web";
import PreviousYearQuestionPaper from "../../blocks/ContentManagement/src/PreviousYearQuestionPaper.web";
import TestSeries from "../../blocks/ContentManagement/src/TestSeries.web";
import ReTestSeries from "../../blocks/ContentManagement/src/ReTestSeries.web";
import GeneralTestSeries from "../../blocks/ContentManagement/src/GeneralTestSeries.web";
import SubjectAndTopic from "../../blocks/ContentManagement/src/SubjectAndTopic.web";
import TSubjectAndTopic from "../../blocks/ContentManagement/src/TSubjectAndTopic.web";
import QuestionBank from "../../blocks/ContentManagement/src/QuestionBank.web";
import ResultDetails from "../../blocks/ContentManagement/src/ResultDetails.web";
import RefundAndCancellation from "../../blocks/ContentManagement/src/RefundAndCancellation.web";
import TermsAndCondition from "../../blocks/ContentManagement/src/TermsAndCondition.web";
import PrivacyPolicy from "../../blocks/ContentManagement/src/PrivacyPolicy.web";
import OnlineCourse from "../../blocks/ContentManagement/src/OnlineCourse.web";
import Course from "../../blocks/ContentManagement/src/Course.web";
import AboutUs from "../../blocks/ContentManagement/src/AboutUs.web";
import DigitalNursingLibrary from "../../blocks/ContentManagement/src/DigitalNursingLibrary.web";
import PdfMaterials from "../../blocks/ContentManagement/src/PdfMaterials.web";
import Zoom from '../../blocks/ContentManagement/src/Zoom.web';
import Notifications from '../../blocks/ContentManagement/src/Notifications.web';
import SetsPassword from '../../blocks/ContentManagement/src/SetsPassword.web';
import ContactUs from '../../blocks/ContentManagement/src/ContactUs.web';
import Signup from '../../blocks/ContentManagement/src/Signup.web';

class RouteConfig extends PureComponent {
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Switch>
          <Route path="/" exact>
            <HomeScreen />
          </Route>
          <Route path="/login" exact>
            <EmailAccountLoginBlock />
          </Route>
          {/* <Route path="/signup" exact>
            <EmailAccountRegistration />
          </Route> */}
          <Route path="/signup" exact>
            <Signup />
          </Route>
          <Route path='/vacancies' exact>
            <Vacancy />
          </Route>
          <Route path='/classroom-courses' exact>
            <ClassroomCourses />
          </Route>
          <Route path='/score-card' exact>
            <ScoreCard />
          </Route>
          <Route path='/recorded-videos/:id' exact>
            <RecordedVideos />
          </Route>
          <Route path='/our-programs' exact>
            <OurProgram />
          </Route>
          <Route path='/crash-course-package' exact>
            <CrashCoursePackage />
          </Route>
          <Route path='/course/test-series-package' exact>
            <TestSeriesPackage />
          </Route>
          <Route path='/test-series-package' exact>
            <TestSeriesPackage />
          </Route>
          <Route path='/course/complete-course-package' exact>
            <CompleteCoursePackage />
          </Route>
          <Route path='/course/neet-bsc' exact>
            <NeetBsc />
          </Route>
          <Route path='/course/ielts' exact>
            <Ielts />
          </Route>
          <Route path='/course/oet' exact>
            <Oet />
          </Route>
          <Route path='/course/nclex-rn' exact>
            <NclexRn />
          </Route>
          <ProtectedRoute path="/profile" exact>
            <UserProfileBasicBlock />
          </ProtectedRoute>
         <Route path="/sucess-story" exact>
           <SuccessStories />
         </Route>
         <Route path="/testimonials" exact>
         <Testimonials />
         </Route>
         <Route path="/forgotPassword" exact>
           <ForgotPassword />
         </Route>
         <Route path='/ForgotPasswordOTP' exact>
           <ForgotPasswordOTP />
         </Route>
         <Route path="/set-password" exact>
          <SetsPassword />
         </Route>
         <Route path="/welcome-user" exact>
           <EmailAccountWelcomeUser />
         </Route>
         <Route path='/NewPassword' exact>
           <NewPassword />
         </Route>
         <Route path='/password-changed' exact>
           <PasswordChanged />
         </Route>
         <Route path='/change-password' exact>
           <ChangePassword />
         </Route>
         <Route path='/gallery'>
          <Gallery />
         </Route>
         <Route path='/contactus'>
           <ContactUs />
         </Route>
         <Route path='/apply-now'>
           <ApplyNow />
         </Route>
         <Route path='/study-material'>
           <StudyMaterial />
         </Route>
         <Route path='/my-courses'>
           <MyCourses />
           </Route>
         <Route path='/global-study-package'>
           <GlobalStudyCourse />
         </Route>
         <Route path='/previous-year-question-paper'>
           <PreviousYearQuestionPaper />
         </Route>
         <Route path='/start-test/:id'>
           <TestSeries />
         </Route>
         <Route path='/restart-test/:id'>
           <ReTestSeries />
         </Route>
         <Route path='/start-general-test/:id'>
           <GeneralTestSeries />
         </Route>
         <Route path='/subjects-topics'>
           <SubjectAndTopic />
         </Route>
         <Route path='/tsubjects-topics'>
           <TSubjectAndTopic />
         </Route>
         <Route path='/question-bank/:id'>
           <QuestionBank />
         </Route>
         <Route path='/result-details/:id'>
           <ResultDetails />
         </Route>
         <Route path='/refund-and-cancellation'>
           <RefundAndCancellation />
         </Route>
         <Route path='/terms-and-conditions'>
           <TermsAndCondition />
         </Route>
         <Route path='/privacy-policy'>
          <PrivacyPolicy />
         </Route>
         <Route path='/course/online-courses'>
          <OnlineCourse />
         </Route>
         <Route path='/online-courses'>
          <OnlineCourse />
         </Route>
         <Route path='/course/courses/:id'>
          <Course />
         </Route>
         <Route path='/about-us'>
          <AboutUs />
         </Route>
         <Route path='/digital-nursing-library'>
          <DigitalNursingLibrary />
         </Route>
         <Route path='/study-materials/:id'>
          <PdfMaterials />
         </Route>
         <Route path='/zoom/:id'>
          <Zoom />
         </Route>
         <Route path='/zoom'>
          <Zoom />
         </Route>
         <Route path='/notifications'>
          <Notifications />
         </Route>
        </Switch>
      </Router>
    );
  }
}
export default RouteConfig;
