export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const demoImg = require("./girl.png");
export const pattern = require('../assets/pattern.png');
export const group10 = require('../assets/Group 10.png');
export const fundaMentalNursing = require("../assets/Rectangle Copy 4.png");
export const maternityNursing = require('../assets/Group 11.png');
export const pedriaticNursing = require('../assets/Group 12.png');
export const medicalNursing = require('../assets/Group 114.png');
export const nursing = require('../assets/Group 17.png');
export const communnityNursing = require('../assets/Group 16.png');
export const check= require('../assets/check.png');
export const unchecked= require('../assets/unchecked.png');
export const loginBg = require("../assets/login-bg.png");
export const loginImage = require("../assets/login-image.png");
export const loginCap = require("../assets/cap.png");
export const logo = require("../assets/logo.png");
export const leftArrow= require("../assets/left-arrow.png");
export const MapImage= require("../assets/image_img.png");
export const FooterImage =require('../assets/image_img28.png');
export const headPhones= require('../assets/image_img20.png');
export const headPhonesBack= require('../assets/image_img19.png');
export const emailImg= require('../assets/image_img16.png');
export const emailImgBack= require('../assets/image_img15.png');
export const phoneImg= require('../assets/image_img12.png');
export const phoneImgBack= require('../assets/image_img11.png');
export const cardBack= require('../assets/image_img46.png');
export const emailcard=require('../assets/image_img10.png');
export const bouquet=require('../assets/bouquet.png');
export const folder=require('../assets/folder.png');
export const razorPayKey='rzp_live_Tl7Ie96K6y0b3Q';
export const razorSecret='6w7WEi0BUP4vviF00ZasUQJM';
export const zoomKey='sIJo6jr0zy6ND6YUpNtZUOVZfoo7h3Jv8iD0';
export const myCourses = `https://${window.location.href.split("/")[2]}/my-courses`;

