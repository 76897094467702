//@ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import NotificationsController, {
  Props,
  configJSON
} from "./NotificationsController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import CustomButton from "../../../components/src/customButton.web"
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import MyFooterWeb from "./MyFooter.web";
import HeaderWeb from "./Header.web";
import { withRouter } from "react-router";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const scoreCardList = [
  {
      img: 'course-study.jpeg',
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
]
class Notifications extends NotificationsController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount () {
    document.body.scrollTop = 0;
    this.getNotifications()
    // this.props.navigation.scroll(0,0)
  }
  getFormatDate = (date: any) => {
    let d = new Date(date)
    let formatDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(d);
    return formatDate
  }
  goToPage = (ele: any) => {
    if(String(ele).toLowerCase().includes('vacancies posted')) {
      this.props.history.push('/vacancies')
    } else if(String(ele).toLowerCase().includes('new upcoming batches')) {
      this.props.history.push('/#batches')
    } else {
      this.props.history.push('/my-courses')
    }
  }
  render() {
    const { navigation } = this.props;
    const _this = this;
    const notificationsList =  this.state.notificationsList
    return (
      <div className="top-gap">
      {/* <HeaderProfileWeb /> */}
      <HeaderWeb />
        <div className="container">
          <div className="section-heading">
            <h3><span>Notifications</span></h3>
          </div>
          <div className="scorecard-list notifications-list">
            <ul>
            {(notificationsList && notificationsList.length > 0) ?
                  notificationsList.map((ele, ind) =>
                    <li key={ind}>
                      <div className="notificcations-left" onClick={()=>this.goToPage(ele?.attributes?.headings)}>
                        <span className="scorecard-img">
                            <NotificationsActiveIcon />
                        </span>
                        <div className="scorecard-info">
                            <h3>{ele?.attributes?.headings}</h3>
                            <p style={{margin: 0}}>{ele?.attributes?.contents}</p>
                            <div className="date">{this.getFormatDate(ele?.attributes?.updated_at)}</div>
                        </div>
                      </div>
                      <div className="notificcations-right">
                        <div className="scorecard-right">
                          {!ele?.attributes?.is_read &&
                            <CustomButton className="btn-curve btn-primary2" label="Mark as read" onClick={()=>this.openedNotification(ele?.attributes?.id)}/>
                          }
                          <CustomButton className="btn-curve btn-danger" color="secondary" label="Delete" onClick={()=>this.deleteNotification(ele?.attributes?.id)}/>
                        </div>
                      </div>
                    </li>
                    ) : <h3 className="text-center w-100">No records found</h3>
                }
            </ul>
          </div>
        </div>
          <MyFooterWeb />
      </div>
    )
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default Notifications;
const NotificationsToast = withSnackBar(Notifications);
const NotificationsWithLoader = withLoader(NotificationsToast);
export default withRouter(NotificationsWithLoader);