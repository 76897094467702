import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { forgotPasswordCap, forgotPasswordImage, forgotPasswordLogo, leftArrow } from "./assets";
import { withRouter, RouteComponentProps } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";

export interface IOther {
    messageId: string,
    navigation: {
      navigate: any
    }
  }
  
  
  let othervalues: IOther = {
    messageId: '',
    navigation: {
      navigate: ''
    }
  }

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    city: string;
    searchState: string;
    messageId: string
}

export interface SS {
    id: any;
}

const useStyles = makeStyles({
    header: {
        paddingBottom: '10px',
        margin: '10px',
        fontSize: '12px'
    },
    input: {
        width: '100%',
        padding: '12px 20px',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxSizing: 'border-box',
        marginBottom: '10px'
    },
    button: {
        width: '100%',
        color: 'white',
        border: 'none',
        backgroundColor: '#1890ff',
        borderRadius: '25px',
    },
    span: {
        width: '80%',
        padding: '10px 18px',
        borderRadius: '15px',
        backgroundColor: '#1890ff',
    },
    link: {
        textDecoration: 'underline',
        textAlign: 'right',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#292C95',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        margin: '10px'
    },
    loginBtn: {
        width: '40%',
        backgroundColor: '#D3F5F5',
        padding: '15px',
        color: 'blue',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer'
    },
    form: {
        padding: '0px'
    },

    btnheader: {
        padding: '5px',
        fontWeight: 500,
    },
    alignbtn: {
        display: 'flex',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderRadius: '10px',
        alignItems: 'center',
    },
    error: {
        border: '1px solid red'
    },
    errorMsg: {
        fontSize: '12px',
        color: 'red',
        textAlign: 'left',
        display: 'block',
        fontWeight: 'bold',
        margin: '10px',
        fontStyle: 'italic'
    }
})

const block = new BlockComponent<Props, S, SS>(
    {
        navigation: '',
        id: '123'
    }
)

interface IChangePassword {
    showLoader: any,
    hideLoader: any
}


const ChangePassword = (props: any & RouteComponentProps) => {

    const classes = useStyles();
    const [existingPassword, setExistingPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState<any>(false);
    const [errorMsg, setErrorMsg] = useState('');



    useEffect(() => {
        block.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ]
        runEngine.attachBuildingBlock(block, block.subScribedMessages);


      othervalues.navigation.navigate = props.history.push;

        block.receive = async (from: string, message: Message) => {
            
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)

            )

            if (apiRequestCallId === othervalues.messageId) {
                var responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );

            props.hideLoader();
                if (responseJson.errors || responseJson.errors) {
                    //  doing nothing for now
                    setError({ newPassword: responseJson.errors[0].profile, confirmPassword: responseJson.errors[0].profile});
                    setErrorMsg(responseJson.errors[0].profile);
                }
                else {

                    const userName = responseJson?.data?.attributes?.full_name;
                    localStorage.setItem("userName", userName);
                    const msg = new Message(getName(MessageEnum.NavigationMessage));

                    msg.addData(getName(MessageEnum.NavigationTargetMessage), "password-changed")

                    msg.addData(getName(MessageEnum.NavigationPropsMessage),othervalues);
                    block.send(msg);
                    
                }
            }
        }
        return (() => {
            runEngine.unSubscribeFromMessages(block, block.subScribedMessages);
        })
    }, []);



    const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLAnchorElement> = (event) => {
        event.preventDefault();
        setError({});
        setErrorMsg('');
        let errObj: { [key: string]: string } = {}
        if (!newPassword || !confirmPassword || !existingPassword) {
            !newPassword ? errObj.newPassword = 'Required' : ''
            !confirmPassword ? errObj.confirmPassword = 'Required' : ''
            !existingPassword ? errObj.existingPassword = 'Required' : ''
            setErrorMsg('*Please provide value to all fields');
            setError(errObj);
            return;
        }
        if (newPassword !== confirmPassword) {
            errObj.newPassword = 'Password does not match';
            errObj.confirmPassword='Password does not match';
            setErrorMsg('Password does not match');
            setError(errObj);
            return;
        }
        changePassword();
    }

    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
        | HTMLSelectElement> = (e) => {
            e.target.name === 'existingPassword' ?
                setExistingPassword(e.target.value) :
                e.target.name === 'confirmPassword' ?
                    setConfirmPassword(e.target.value) :
                    setNewPassword(e.target.value);
        }

    const changePassword = () => {

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        othervalues.messageId = requestMessage.messageId;
        const body = {
            data: {
                current_password: existingPassword,
                new_password: newPassword
            }
        }


        const httpHeaders = {
            "Content-Type": "application/json",
            "token": localStorage.getItem('authToken')
        };
        props.showLoader();

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            'profile/password');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(httpHeaders));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    return (
        <div>
            <div className="login-widget">
                <div className="login">
                    <div className="login-left">
                        <h3>Welcome to <br /> Nursing Experts</h3>
                        <img src={forgotPasswordImage} alt="" />
                    </div>
                    <div className="login-right" style={{ padding: '20px' }}>
                        <div className="login-logo"><img src={forgotPasswordLogo} /> </div>
                        <div className="login-info">
                            <h3 className="login-right-heading">Change Password</h3>
                            <div className="login-sub-heading">Create your Password for Nursing Experts</div>
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <input
                                    type='password'
                                    placeholder="Existing Password"
                                    className={`${classes.input}  ${(error.existingPassword ? classes.error : '')}`}
                                    name="existingPassword"
                                    onChange={handleChange}
                                    value={existingPassword}
                                />
                                <input
                                    type='password'
                                    placeholder="Create Password"
                                    className={`${classes.input}  ${(error.newPassword ? classes.error : '')}`}
                                    name="newPassword"
                                    onChange={handleChange}
                                    value={newPassword}
                                />
                                <input
                                    type='password'
                                    placeholder="Confirm Password"
                                    className={`${classes.input}  ${(error.confirmPassword ? classes.error : '')}`}
                                    name="confirmPassword"
                                    onChange={handleChange}
                                    value={confirmPassword}
                                />

                                {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
                                <br />
                                <br />
                                <button type={"submit"} className={classes.button} >
                                    <span className={classes.alignbtn}>
                                        <span className={classes.btnheader}>Submit</span>
                                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
                                    </span>
                                </button>
                            </form>
                            <div>
                                <span
                                    onClick={() => { window.location.href = '/' }}
                                ><p className={classes.link}><img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default withLoader(withRouter(ChangePassword));