//@ts-nocheck 
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import RecordedDataController, {
  Props,
  configJSON
} from "./RecordedDataController.web";
import Footer from "../../../components/src/Footer.web";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import MyFooterWeb from "./MyFooter.web";
import { NavLink } from 'react-router-dom'
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import HeaderWeb from "./Header.web";
import VideocamIcon from '@material-ui/icons/Videocam';
import Modal from '@material-ui/core/Modal';
import { EditAttributes } from "@material-ui/icons";
import {folder} from "./assets";
import HomeIcon from '@material-ui/icons/Home';
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

class RecordedData extends RecordedDataController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount () {
    document.body.scrollTop = 0;
  }
  getIst = (startDate:any) => {
    var s = new Date(startDate).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
    return s
  }
  getDuration = (startDate:any, endDate:any) => {
    var _endDate:any = new Date(endDate);
    var _startDate:any = new Date(startDate);
    var seconds = Math.floor((_endDate - _startDate)/1000);
    var minutes = Math.floor(seconds/60);
    var hours = Math.floor(minutes/60);
    var days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
    var result = hours + 'h ' + minutes + 'm ' + seconds + 's'
    return result
  }
  getRecord = (videoUrl) => {
    window.open(videoUrl, '_blank');
  }
  getVimeo = (videoUrl) => {
   let video_url =  videoUrl.split('/').pop();
    return video_url
  }
  checkRecords = (ele: any) => {
    if(ele.attributes.children && ele.attributes.children.data.length >0) {
      return ele.attributes.children.data.map((element, index)=>
      <span className="folder" key={element.attributes.name}><img width="100px" src={folder} />{element.attributes.name}
      {
            (element.attributes.recordings && element.attributes.recordings.data.length > 0) &&
            element.attributes.recordings.data.map((elem, inde)=>
            <span key={inde}>{elem.attributes.id}</span>
            )
          }
      {(element.attributes.children && element.attributes.children.data.length > 0) &&
        this.checkRecords(element)
      }
      </span>
      )
    }
    // return <span key={ele.attributes.name}>{ele.id}<img width="100px" src={folder} /></span>
  }
  render() {
    const { navigation } = this.props;
    const _this = this;
    return (
      <div className="record-data">
        {/* <ul className="breadcrumb-data">
            {
              (this.props.brdcrumbData.length>0 ) &&
              this.props.brdcrumbData.map((ele, ind)=> 
              <li key={ind} onClick={()=>this.props.choseBrcurmb(ele, ind)}>
                { ind == 0 && <HomeIcon />}
                { ind !== 0 && <span className="divider">/</span>}
                {ele?.attributes?.name} 
              </li>
              
              )
            }
        </ul> */}
        <div className="folders">
          {(this.props.data && this.props.data.length > 0 && !this.props.recordingsData) &&
            this.props.data.map((ele, ind)=>
            <span key={ind} className="folder-widget" onClick={()=>this.props.choseFolder(ele)}>
              <span className="folder" key={ele.title}><img width="100px" src={folder} /><b>{ele.title}</b></span>
              {/* {this.checkRecords(ele)} */}
            {/* {
              (ele.attributes.children && ele.attributes.children.data.length >0) &&
              ele.attributes.children.data.map((element, index)=>
              <span key={index}>{element.attributes.name}<img width="100px" src={folder} /></span>
              )
            } */}
            </span>
          )}
        </div>
          <ul>
        {(this.props.recordingsData && this.props.recordingsData.length > 0) &&
          this.props.recordingsData.map((ele, ind)=>
            <li key={ind} onClick={()=>this.handleOpen(ele?.attributes?.recording_url)}>
                          <NavLink to="#" className="record-content">
                            <span className="record-img">
                              {
                                ele?.attributes?.thumbnail_url 
                                ? <img src={ele?.attributes?.thumbnail_url} />
                                : <VideocamIcon />
                              }
                            </span>
                            <div className="record-info">
                                <h3>{ele?.attributes?.title}</h3>
                                <div className="record-date">Recorded on: {ele?.attributes?.display_recorded_on}</div>
                                <span className="record-time"><AccessTimeIcon /> {ele?.attributes?.display_duration}</span>
                            </div>
                          </NavLink>
                        </li>
        )}
        </ul>
        <Modal
              open={this.state.openModel}
              onClose={this.handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              className="modal-data"
            >
              <div >
                {
                  this.state.iframeUrl.includes('vimeo')  
                    ?  <iframe className="success-story-iframe" src={`https://player.vimeo.com/video/` + this.getVimeo(this.state.iframeUrl) + '?autoplay=1'}  allow='autoplay' width="420" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                    : <iframe className="success-story-iframe" width="420" height="315"
                    src={`https://www.youtube.com/embed/${this.state.iframeUrl.split('=').pop()}?autoplay=1`} allow='autoplay' allowFullScreen="true">
                  </iframe>
                }

                          
                      </div>
            </Modal>
      </div>
    );
  } 
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export default RecordedData;