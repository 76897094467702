import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  qualification: any;
  qualifications: any[];
  selectedFiles: any[];
  vacancyDetails: any;
  files: any[];
  selectedDocIds: any[];
  deletedDocIds: any[];
  previewFiles: any[];
  fullName: string;
  dob: string;
  email: string;
  dep_degree: string;
  contactNo: any
  selectedQualificationID: any;
  classFullName: any;
  classDob: any;
  classQualification: any;
  classEmail: any;
  classContactNo: any;
  showErrorMessage: boolean;
  showErrorEmail: boolean;
  errorMobile: boolean;
  classPreviewFiles: string;
  classSelectedFiles: string;
  globalStudyCourse: any;
  formAttachment: any;
  selectedFile: any;
  openDialog: boolean;
  selectedId: any;
  errorDoc: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HomeScreenController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  vacanciesGetApiCallId: string = "";
  qualificationsGetApiCallId: string = "";
  registerApiCallId: string = "";
  globalStudyCoursesGetApiCallId: string = "";
  formAttachmentGetApiCallId: string = "";
  deleteformAttachmentGetApiCallId: string = "";
  qualifications: any[];
  vacancyDetails: any;
  globalStudyCourse: any;
  formAttachment: any;
  deleteAttachment: any;
  selectedDocIds: any[] = [];
  deletedDocIds: any[] = [];
  selectedFiles: any[] = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      qualification: "",
      qualifications: [],
      vacancyDetails: {},
      selectedFiles: [],
      files: [],
      selectedDocIds: [],
      deletedDocIds: [],
      previewFiles: [],
      fullName: '',
      dob: '',
      email: '',
      dep_degree: '',
      contactNo: null,
      selectedQualificationID: '',
      classFullName: '',
      classDob: '',
      classQualification: '',
      classEmail: '',
      showErrorEmail: false,
      showErrorMessage: false,
      errorMobile: false,
      classPreviewFiles: '',
      classSelectedFiles: '',
      classContactNo: null,
      globalStudyCourse: {},
      formAttachment: {},
      selectedFile: {},
      openDialog:false,
      selectedId:null,
      errorDoc: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.qualificationsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            
            this.qualifications = responseJson.data;
            this.setState({
              qualifications: this.qualifications
            });
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.registerApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            this.props.showToast({type : 'success', message : 'Applied Successfully'})
            window.location.href="/"
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.globalStudyCoursesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            
            this.globalStudyCourse = responseJson.data.attributes;
            this.setState({
              globalStudyCourse: this.globalStudyCourse
            });           
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.formAttachmentGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            // console.log('this.state.selectedFiles4444', this.selectedFiles)
            //On User Profile Success
            if(this.state?.deletedDocIds?.length > 0) {
              for( var i=this.selectedFiles.length - 1; i>=0; i--){
                for( var j=0; j<this.state.deletedDocIds.length; j++){
                    if(this.selectedFiles[i] && (this.selectedFiles[i].id === this.state.deletedDocIds[j])){
                      this.selectedFiles.splice(i, 1);
                    }
                }
              }
            }
            // console.log('this.state.selectedFiles1212', this.state.selectedFiles);
            // console.log('this.selectedFiles1212', this.selectedFiles);
            // this.setState({selectedFiles: this.state.selectedFiles.filter(ele=> ele.id != this.state.selectedId)})
            this.formAttachment = responseJson.data;
            // console.log('this.state.previewFiles', this.state.previewFiles);
            // let _ids = [];
            this.selectedDocIds.push(this.formAttachment?.id)
            this.selectedFiles.push(this.formAttachment)
            // console.log('this.selectedDocIds', this.selectedDocIds);
            // console.log('this.selectedFiles', this.selectedFiles);
            // this.selectedDocIds=_ids
            this.setState({
              formAttachment: this.formAttachment,
              selectedDocIds: this.selectedDocIds,
              selectedFiles: this.selectedFiles
            });
            // console.log('this.state.formAttachment', this.state.formAttachment);
            // this.state.selectedDocIds.push(this.state.formAttachment)
            // this.state.previewFiles.map(ele => ele.id)
            // console.log('selectedDocIdsAdd', this.state.selectedDocIds);            
            // console.log('selectedFilesAdd', this.state.selectedFiles);            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.deleteformAttachmentGetApiCallId) {
          if (
            responseJson
          ) {
            // console.log('this.state.selectedId222', this.state.selectedId);
            // console.log('this.state.selectedFiles222', this.state.selectedFiles);
            // console.log('this.state.selectedDocIds222', this.state.selectedDocIds);
            //On User Profile Success
            this.deletedDocIds.push(this.state.selectedId)
            this.setState({
              openDialog: false, 
              selectedFiles: this.state.selectedFiles.filter(ele=> ele.id != this.state.selectedId),
              selectedDocIds: this.state.selectedDocIds.filter(ele=> ele != this.state.selectedId),
              deletedDocIds: this.deletedDocIds
            })
            // this.setState({selectedDocIds: this.state.selectedDocIds.filter(ele=> ele != this.state.selectedId)})
            this.props.showToast({type : 'success', message : 'Attachment deleted successfully'})
            // console.log('this.state.selectedId333', this.state.selectedId);
            // console.log('this.state.selectedFiles333', this.state.selectedFiles);
            // console.log('this.state.selectedDocIds333', this.state.selectedDocIds);
            // console.log('this.state.selectedFiles2', this.state.selectedFiles);
            if(this.selectedFiles.length == 0) {
              this.selectedFiles = []
            }
            // this.formAttachment = responseJson.data;
            // console.log('this.state.previewFiles', this.state.previewFiles);
            // let _ids = [];
            // this.selectedDocIds.push(this.formAttachment?.id)
            // this.selectedDocIds=_ids
            // this.setState({
            //   formAttachment: this.formAttachment,
            //   selectedDocIds: this.selectedDocIds
            // });
            // console.log('this.state.formAttachment', this.state.formAttachment);
            // this.state.selectedDocIds.push(this.state.formAttachment)
            // this.state.previewFiles.map(ele => ele.id)
            // console.log('selectedDocIds', this.state.selectedDocIds);            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getQualifications() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.qualificationsGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetQualifications
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetQualifications,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetQualifications
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCourseRegister() {
    // debugger
    if(!this.state.fullName) {
      this.setState({classFullName: 'form-control error-field'})
    } else {
      this.setState({classFullName: 'form-control'})
    }
    // dob vaidation
    if(!this.state.dob) {
      this.setState({classDob: 'form-control error-field'})
    } else {
      this.setState({classDob: 'form-control'})
    }
    // qualification vaidation
    if(!this.state.qualification) {
      this.setState({classQualification: 'form-select error-field'})
    } else {
      this.setState({classQualification: 'form-select'})
    }

    // email vaidation
    if(!this.state.email) {
      this.setState({classEmail: 'form-control error-field', showErrorEmail: true})
    } else {
      this.setState({classEmail: 'form-control', showErrorEmail: false})
    }
    if(this.state.email) {
      let pattern=/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/g;
      if(!this.state?.email?.match(pattern)) {
        this.setState({classEmail: 'form-control error-field', showErrorEmail: true})
        return
      } else {
        this.setState({classEmail: 'form-control', showErrorEmail: false})
      }
    }
    // contactNo vaidation
    if(!this.state.contactNo || this.state.contactNo.length > 10 || this.state.contactNo.length < 10 ) {
      this.setState({classContactNo: 'form-control error-field', errorMobile:true})
    } else {
      this.setState({classContactNo: 'form-control', errorMobile:false})
    }
    if(!this.state.previewFiles) {
      this.setState({classPreviewFiles: 'file-upload-area error-field'})
    } else {
      this.setState({classPreviewFiles: 'file-upload-area'})
    }
    if(!this.state.selectedFiles) {
      this.setState({classSelectedFiles: 'file-upload-area error-field'})
    } else {
      this.setState({classSelectedFiles: 'file-upload-area'})
    }
    if (
      !this.state.fullName || 
      !this.state.qualification || 
      !this.state.email || 
      !this.state.dob  || 
      !this.state.contactNo ||
      this.state.contactNo.length !== 10 ||
      !this.state.selectedFiles ||
      this.state.selectedDocIds.length <= 0 ) {
        // debugger
        this.setState({showErrorMessage: true})
        // your Name vaidation
        return;
      } else {
        this.setState({showErrorMessage: false})
      }
      const header = {
        "Content-Type": configJSON.contentTypeApiGetCourseRegister,
        token: localStorage.getItem("authToken")
        // token: this.authToken
      };

      // this.props.showLoader();

      let data: any = {
        full_name: this.state.fullName,
        date_of_birth: this.state.dob,
        email: this.state.email,
        qualification: this.state.qualification,
        contact_number: '91' + this.state.contactNo,
        global_study_course_attachment_ids: this.state.selectedDocIds,
      };

      const httpBody = {
        registration: data
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
      this.registerApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetCourseRegister
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetCourseRegister
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getGlobalStudyCourses() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.globalStudyCoursesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetGlobalStudyCourses
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetGlobalStudyCourses,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetGlobalStudyCourses
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAddFormAttachment(data:any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.formAttachmentGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetFormAttchment
    );

    const header = {
      // "Content-Type": configJSON.contentTypeApiGetFormAttchment,
      // token: localStorage.getItem('authToken')
    };

    const formData = new FormData();
      formData.append("attachment[document]", data);

      const httpBody = {
        data: formData
      };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetFormAttchment
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getDeleteFormAttachment(id:any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteformAttachmentGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetDeleteAttachment + id
    );

    const header = {
      // "Content-Type": configJSON.contentTypeApiGetFormAttchment,
      // "Access-Control-Allow-Origin": "*"
      // token: localStorage.getItem('authToken')
    };

    // const formData = new FormData();
    //   formData.append("attachment[document]", data);

    //   const httpBody = {
    //     data: formData
    //   };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   formData
    // );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetDeleteAttachment
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
