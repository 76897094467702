import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  myCourses: any[];
  name: string;
  number: string;
  message: string;
  className: string;
  classNumber: string;
  classMessage: string;
  errorMobile: boolean;
  errorName: boolean;
  errorMessage: boolean;
  showErrorMessage: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyFooterController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  contactRequestPostApiCallId: string = "";
  myCourses: any[];
  name: string;
  number: string;
  message: string;
  className: string;
  classNumber: string;
  classMessage: string;
  errorMobile: boolean;
  errorName: boolean;
  errorMessage: boolean;
  showErrorMessage: boolean;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      myCourses: [],
      name: "",
      number: "",
      message: "",
      className: "",
      classNumber: "",
      classMessage: "",
      errorMobile: false,
      errorName: false,
      errorMessage: false,
      showErrorMessage: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.contactRequestPostApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success
            this.props.showToast({type : 'success', message : 'Registered Successfully'})
            this.setState(
              {
                name: '', number: '', message: ''
              }
            )
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  contactRequest(e: any) {
    e.preventDefault()
    if(!this.state.name) {
      this.setState({className: 'form-control error-field', errorName: true})
    } else {
      this.setState({className: 'form-control', errorName: false})
    }
    // contactNo vaidation
    if(!this.state.number || this.state.number.length > 10 || this.state.number.length < 10 ) {
      this.setState({classNumber: 'form-control error-field', errorMobile: true})
    } else {
      this.setState({classNumber: 'form-control', errorMobile: false})
    }
    // yourMessage vaidation
    if(!this.state.message) {
      this.setState({classMessage: 'form-control error-field', errorMessage: true})
    } else {
      this.setState({classMessage: 'form-control', errorMessage: false})
    }
    if (
      !this.state.name ||
      !this.state.number ||
      !this.state.message) {
        // debugger
        this.setState({showErrorMessage: true})
        // your Name vaidation
        return;
      } else {
        this.setState({showErrorMessage: false})
      }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactRequestPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiPostMyFooter
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiPostMyFooter,
      token: localStorage.getItem('authToken')
    };
    let contact_request: any = {
      name: this.state.name,
      full_phone_number: this.state.number,
      message: this.state.message,
    };

    const httpBody = {
      contact_request: contact_request
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiPostMyFooter
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
