//@ts-nocheck 
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import MyCoursesController, {
  Props,
  configJSON
} from "./MyCoursesController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import CustomButton from "../../../components/src/customButton.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import MyFooterWeb from "./MyFooter.web";
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import HeaderWeb from "./Header.web";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  }
]
const whyNursingExperts = [
  { name: 'Multiple Course Options' },
  { name: 'Highly Qualified Faculty' },
  { name: 'Study Materials' },
  { name: 'Placement Support' },
  { name: 'Online Exams' },
  { name: 'Proved Performance' }
]
class MyCourses extends MyCoursesController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount () {
    const _zoomDiv = document.getElementById('zmmtg-root')
    if(_zoomDiv) {
      _zoomDiv.style.display = 'none'
    }
    this.scrollTop()
    this.getMyCourses()
  }
  scrollTop = () => {
    document.body.scrollTop = 0;
  }
  formatDate = (createdDate:any) => {
    let today = new Date(createdDate);
    let date = today.getDate() + "-"+ (today.getMonth()+1) +"-"+today.getFullYear();
    return date
  }
  getJoinMeeting = () => {
    this.setState({joinMeeting: true})
  }

  renderCourseBtn = (ele, ind) =>{
    return (
      <>
        <Grid item md={8} className="syllabus">
          {(ele?.attributes?.status == 'inactive')
            ? <div style={{ color: '#f00' }}>Subscription ended on date: <b>{ele?.attributes?.subscription_end_date}</b></div>
            : null
          }
        </Grid>
        <Grid item md={4}>
          <div className="my-courses-btns">

            {(ele?.attributes?.payment_status == 'Paid') &&
              <>
                {ele?.attributes?.show_live_class_button &&

                  <a target="_blank" href={(ele?.attributes?.enrolled_batch?.data?.attributes?.meetings?.data[0]?.attributes?.live_now) ? ele?.attributes?.enrolled_batch?.data?.attributes?.meetings?.data[0]?.attributes?.meeting_details?.join_url : '#'} className={(!(ele?.attributes?.status != 'inactive' && ele?.attributes?.enrolled_batch?.data?.attributes?.meetings?.data[0]?.attributes?.live_now)) ? 'no-underline disabled' : 'no-underline'} >
                    <Button variant="contained" className="btn-curve btn-success btn-join" disabled={!(ele?.attributes?.status != 'inactive' && ele?.attributes?.enrolled_batch?.data?.attributes?.meetings?.data[0]?.attributes?.live_now)}>JOIN LIVE CLASS</Button>
                  </a>
                }
                <CustomButton disabled={ele?.attributes?.status == 'inactive'} onClick={() => this.props.history.push('/recorded-videos/' + ele?.id)} label="RECORDED VIDEOS" className="btn-curve btn-primary btn-video" />

                {ele?.attributes?.show_material_button &&
                  <CustomButton disabled={ele?.attributes?.status == 'inactive'} onClick={() => this.props.history.push('/study-materials/' + ele?.attributes?.course?.data?.id)} label="STUDY MATERIALS" className="btn-curve btn-blue" />
                }
                {ele?.attributes?.show_test_series_button &&
                  <CustomButton disabled={ele?.attributes?.status == 'inactive'} label="TEST SERIES" className="btn-curve btn-info" onClick={() => this.props.history.push('/tsubjects-topics')} />
                }
              </>
            }
          </div>
        </Grid>
            </>
    )
  }
  

  renderCourseRight = (ele, ind) => {
    return (
      <Grid container
        justifyContent="space-between"
        alignItems="center">
        <Grid item><h3 className="sub-heading"> {ele?.attributes?.course?.data?.attributes?.title}</h3></Grid>
        <Grid item className="pay-information">
          <div className="pay-info">
            <span style={{ color: '#0CBB80', fontWeight: 900 }}> {ele?.attributes?.fees}</span>
            {ele?.attributes?.payment_status !== 'Paid' &&
              <CustomButton label="PAY" className="btn-curve btn-primary btn-pay-small" onClick={() => this.displayRazorpay(ele?.attributes?.order?.data?.attributes, ele?.attributes?.id)} />
            }
          </div>
          {(ele?.attributes?.payment_status == 'Paid' && ele?.attributes?.paid_on) &&
            <span className="paid-on">Paid on {ele?.attributes?.paid_on} </span>
          }

        </Grid>
      </Grid>
    )
  }

  renderCourseTime = (ele, ind) => {
    return (
      <div className="my-course-time" >
        <div className="my-course-time-m">
          {(ele?.attributes?.course?.data?.attributes?.batches?.data && ele?.attributes?.course?.data?.attributes?.batches?.data.length > 0)
            ? ele?.attributes?.course?.data?.attributes?.batches?.data.map((ele: any, key: any) =>
              <div className="course-time" key={key}>
                <span dangerouslySetInnerHTML={{ __html: ele?.attributes?.display_text }}></span>
              </div>
            )
            : <div className="course-time"><span dangerouslySetInnerHTML={{ __html: ele?.attributes?.course?.data?.attributes?.timing }}></span></div>
          }
        </div>

        {(ele?.attributes?.payment_status == 'Paid') &&
          <>
            {ele?.attributes?.show_current_test_button &&
              <CustomButton disabled={ele?.attributes?.status == 'inactive'} label="CLASS TEST" className="btn-curve btn-white btn-test" onClick={() => this.props.history.push('/start-general-test/' + Number(ele?.attributes?.course?.data?.id))} />
            }
          </>
        }
      </div>
    )
  }

  render() {
    const { myCourses } = this.state
    const { navigation } = this.props;
    const _this = this;
    const settings = {
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="section-heading">
          <h3><span>My Courses</span></h3>
        </div>
        <div className="container">
          <ul className="my-courses">
            {(myCourses && myCourses.length > 0)
              ? myCourses.map((ele, ind) =>
                <li key={ele.id} className="my-course">
                  <div className="my-course-left" style={{ backgroundColor: ind % 2 == 0 ? 'rgb(12, 187, 128)' : '#FFBF3B' }}>
                    <img src={ele?.attributes?.course?.data?.attributes?.course_image} />
                    {this.renderCourseTime(ele, ind)}
                  </div>
                  <div className="my-course-right" style={{ backgroundColor: ele?.attributes?.status == 'inactive' ? 'rgb(239 239 239)' : '', borderRadius: '10px' }}>
                    {this.renderCourseRight(ele, ind)}

                    <Grid container
                      className="course-btns"
                      justifyContent="space-between"
                      alignItems="flex-end">
                      {this.renderCourseBtn(ele, ind)}
                    </Grid>
                  </div>
                </li>
              ) : <h3 className="text-center w-100">No records found</h3>
            }
          </ul>
        </div>
        <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default MyCourses;
const MyCoursesToast = withSnackBar(MyCourses);
const MyCoursesWithLoader = withLoader(MyCoursesToast);
export default withRouter(MyCoursesWithLoader);