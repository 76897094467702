//@ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import GeneralTestSeriesController, {
  Props,
  configJSON
} from "./GeneralTestSeriesController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import CustomButton from "../../../components/src/customButton.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import MyFooterWeb from "./MyFooter.web";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withRouter } from "react-router";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import HeaderWeb from "./Header.web";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  }
]

const whyNursingExperts = [
  { name: 'Multiple Course Options' },
  { name: 'Highly Qualified Faculty' },
  { name: 'Study Materials' },
  { name: 'Placement Support' },
  { name: 'Online Exams' },
  { name: 'Proved Performance' }
]
class GeneralTestSeries extends GeneralTestSeriesController {

  constructor(props: Props) {
    super(props);
    localStorage.removeItem('answeredQA')
  }

  async componentDidMount() {
    document.body.scrollTop = 0;
    this.getQuestionsAndAnswers();
    setInterval(()=>{
      this.countDown(this.state.seconds)
    }, 1000)
  }

  handleCloseDialog = () => {
    this.setState({openDialog: false});
  };

  handleOpenDialog = () => {
    this.setState({openDialog: true});
  };

  openScoreDialog = () => {
    this.setState({openDialog: false,openScoreDialog: true});
    this.endTest()
  };

  closeScoreDialog = () => {
    this.setState({openScoreDialog: false});
  };

  getSeconds = (sec) => {
    let hours   = Math.floor(sec / 3600); // get hours
    let mins = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let secs = sec - (hours * 3600) - (mins * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (mins < 10) {mins = "0"+mins;}
    if (secs < 10) {secs = "0"+secs;}
      this.setState({seconds: sec - 1})
    return hours + ':'+ mins + ':' + secs; // Return is HH : MM : SS
  }
  getVimeo = (videoUrl) => {
    this.isMyScriptLoaded()
   let video_url =  videoUrl.split('/').pop();
    return video_url
  }
  isMyScriptLoaded = () => {
    let url = "https://player.vimeo.com/api/player.js";
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src == url) return true;
    }
    return false;
}

getId = (url: any) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;
    const data = this.state.startTest
    const questions = this.state.questions
    // const answered;
    // if(localStorage.getItem('answeredQA')) {
    //   answered = JSON.parse(localStorage.getItem('answeredQA'))
    // }
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="container mt-40">
          {questions && questions.length > 0
           ? <Grid container className="question-box">
            <Grid item md={8}>
              <div className="ques-left">

                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item md={6}>
                    <h3 className="test-series-heading">General test series</h3>
                    <span style={{ color: 'grey' }}>Class test</span>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: 'right' }}>
                    <h2 className="test-time">{this.state.time.h}:{this.state.time.m}:{this.state.time.s}</h2>
                  </Grid>
                </Grid>
                <hr />


                <Grid container>

                  <Grid item md={12} xs={12}>
                    <div className="skip" ><a onClick={()=>this.skip()}>Skip</a></div>
                  </Grid>
                  {/* .filter((ele, ind)=>((ind+1) == this.state.current)) */}
                  { questions && questions.length > 0 &&
                    questions.map((ele:any, ind)=>
                  <Grid container key={ind} style={{display: (ind+1) == this.state.current ? 'block' : 'none'}}>
                    <Grid item md={12} xs={12} >
                      <div className="ques">
                        <h3 style={{ color: '#3894FF' }}>Question {this.state.current} </h3>
                        <p>
                          {/* {ele?.attributes?.question?.data?.attributes?.question} */}
                          { ele?.attributes?.question?.data?.attributes?.question &&
                            <div>{ele?.attributes?.question?.data?.attributes?.question}</div>
                          }
                          {ele?.attributes?.question?.data?.attributes?.has_image && 
                            <img className="question-image" src={ele?.attributes?.question?.data?.attributes?.question_image} alt={ele?.attributes?.question?.data?.attributes?.question} />
                          }
                          {ele?.attributes?.question?.data?.attributes?.has_video && 
                            <>
                            { ele?.attributes?.question?.data?.attributes?.video.includes('vimeo') && 
                                <iframe className="question-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.question?.data?.attributes?.video)} height="200" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                            }
                            {
                                this.getId(ele?.attributes?.question?.data?.attributes?.video) 
                                ?
                                <iframe className="question-video" height="200"
                                    src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.question?.data?.attributes?.video)}>
                                </iframe>
                                : null
                            }
                            </>
                          }
                        </p>
                      </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Grid container spacing={4} >

                        <Grid item md={12} xs={12}>
                            <Grid container >
                          {/* <RadioGroup
                            name={this.state.current}
                            onChange={(e) => this.setOutput(e, ele)}
                            className="answers-group"
                            id={'radio'+ (ind+1)}
                          >
                              { ((ele?.attributes?.question?.data?.attributes?.answers?.data) && (ele?.attributes?.question?.data?.attributes?.answers?.data.length > 0)) &&
                                ele?.attributes?.question?.data?.attributes?.answers?.data.map((ele:any, index:number)=>
                                <div key={index} className="ques-option">
                                  <FormControlLabel key={ele?.id} value={ele?.id} control={<Radio />} label={ele?.attributes?.ans_text} />
                                </div>
                                  )
                                }
                          </RadioGroup> */}
                          {/* <div className="question-options" onChange={(e) => this.setOutput(e, ele)}>
                          { ((ele?.attributes?.question?.data?.attributes?.answers?.data) && (ele?.attributes?.question?.data?.attributes?.answers?.data.length > 0)) &&
                                ele?.attributes?.question?.data?.attributes?.answers?.data.map((element:any, index:number)=>
                          <label key={index}><input type="radio" value={element?.id} name={"my-answer" + (ind+1)} unchecked />{element?.attributes?.ans_text}</label>
                          )
                                }
                          </div> */}
                              <Grid onChange={(e) => this.setOutput(e, ele, 1)}  item md={(ele?.attributes?.question?.data?.attributes?.has_option1_image || ele?.attributes?.question?.data?.attributes?.has_option1_video) ? '12' : '6'}>
                                <label className="option-label"><input type="radio" value={ele?.attributes?.question?.data?.attributes?.option1_text} name={"my-answer" + (ind+1)} unchecked />{ele?.attributes?.question?.data?.attributes?.option1_text}</label>
                                  {(ele?.attributes?.question?.data?.attributes?.has_option1_image || ele?.attributes?.question?.data?.attributes?.has_option1_video) && 
                                  <div>
                                      {ele?.attributes?.question?.data?.attributes?.has_option1_image && 
                                          <img className="answer-image" src={ele?.attributes?.question?.data?.attributes?.option1_image} alt="option1 image" />
                                      }
                                      {ele?.attributes?.question?.data?.attributes?.has_option1_video && 
                                          <>
                                          {
                                              ele?.attributes?.question?.data?.attributes?.option1_video && 
                                              <>
                                              {
                                                  this.getId(ele?.attributes?.question?.data?.attributes?.option1_video) 
                                                  ?
                                                  <iframe className="answer-video" height="150"
                                                      src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.question?.data?.attributes?.option1_video)}>
                                                  </iframe>
                                                  : null
                                              }
                                              { ele?.attributes?.question?.data?.attributes?.option1_video.includes('vimeo') && 
                                                  <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.question?.data?.attributes?.option1_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                              }
                                              </>
                                          }
                                          </>
                                      }
                                  </div>
                                  }
                              </Grid>
                              <Grid onChange={(e) => this.setOutput(e, ele, 2)}  item md={(ele?.attributes?.question?.data?.attributes?.has_option2_image || ele?.attributes?.question?.data?.attributes?.has_option2_video) ? '12' : '6'}>
                                <label className="option-label"><input type="radio" value={ele?.attributes?.question?.data?.attributes?.option2_text} name={"my-answer" + (ind+1)} unchecked />{ele?.attributes?.question?.data?.attributes?.option2_text}</label>
                                  {(ele?.attributes?.question?.data?.attributes?.has_option2_image || ele?.attributes?.question?.data?.attributes?.has_option2_video) && 
                                  <div>
                                      {ele?.attributes?.question?.data?.attributes?.has_option2_image && 
                                          <img className="answer-image" src={ele?.attributes?.question?.data?.attributes?.option2_image} alt="option2 image" />
                                      }
                                      {ele?.attributes?.question?.data?.attributes?.has_option2_video && 
                                          <>
                                          {
                                              ele?.attributes?.question?.data?.attributes?.option2_video && 
                                              <>
                                              {
                                                  this.getId(ele?.attributes?.question?.data?.attributes?.option2_video) 
                                                  ?
                                                  <iframe className="answer-video" height="150"
                                                      src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.question?.data?.attributes?.option2_video)}>
                                                  </iframe>
                                                  : null
                                              }
                                              { ele?.attributes?.question?.data?.attributes?.option2_video.includes('vimeo') && 
                                                  <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.question?.data?.attributes?.option2_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                              }
                                              </>
                                          }
                                          </>
                                      }
                                  </div>
                                  }
                              </Grid>
                              <Grid onChange={(e) => this.setOutput(e, ele, 3)}  item md={(ele?.attributes?.question?.data?.attributes?.has_option3_image || ele?.attributes?.question?.data?.attributes?.has_option3_video) ? '12' : '6'}>
                                <label className="option-label"><input type="radio" value={ele?.attributes?.question?.data?.attributes?.option3_text} name={"my-answer" + (ind+1)} unchecked />{ele?.attributes?.question?.data?.attributes?.option3_text}</label>
                                  {(ele?.attributes?.question?.data?.attributes?.has_option3_image || ele?.attributes?.question?.data?.attributes?.has_option3_video) && 
                                  <div>
                                    {ele?.attributes?.question?.data?.attributes?.has_option3_image && 
                                      <img className="answer-image" src={ele?.attributes?.question?.data?.attributes?.option3_image} alt="option2 image" />
                                    }
                                    {ele?.attributes?.question?.data?.attributes?.has_option3_video && 
                                      <>
                                        {
                                          ele?.attributes?.question?.data?.attributes?.option3_video && 
                                          <>
                                            {
                                              this.getId(ele?.attributes?.question?.data?.attributes?.option3_video) 
                                              ?
                                              <iframe className="answer-video" height="150"
                                                  src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.question?.data?.attributes?.option3_video)}>
                                              </iframe>
                                              : null
                                            }
                                            { ele?.attributes?.question?.data?.attributes?.option3_video.includes('vimeo') && 
                                              <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.question?.data?.attributes?.option3_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                            }
                                          </>
                                        }
                                      </>
                                    }
                                  </div>
                                  }
                              </Grid>
                              <Grid onChange={(e) => this.setOutput(e, ele, 4)}  item md={(ele?.attributes?.question?.data?.attributes?.has_option4_image || ele?.attributes?.question?.data?.attributes?.has_option4_video) ? '12' : '6'}>
                                <label className="option-label"><input type="radio" value={ele?.attributes?.question?.data?.attributes?.option4_text} name={"my-answer" + (ind+1)} unchecked />{ele?.attributes?.question?.data?.attributes?.option4_text}</label>
                                  {(ele?.attributes?.question?.data?.attributes?.has_option4_image || ele?.attributes?.question?.data?.attributes?.has_option4_video) && 
                                  <div>
                                    {ele?.attributes?.question?.data?.attributes?.has_option4_image && 
                                      <img className="answer-image" src={ele?.attributes?.question?.data?.attributes?.option4_image} alt="option2 image" />
                                    }
                                    {ele?.attributes?.question?.data?.attributes?.has_option4_video && 
                                      <>
                                        {
                                          ele?.attributes?.question?.data?.attributes?.option4_video && 
                                          <>
                                            {
                                              this.getId(ele?.attributes?.question?.data?.attributes?.option4_video) 
                                              ?
                                              <iframe className="answer-video" height="150"
                                                  src={`https://www.youtube.com/embed/` + this.getId(ele?.attributes?.question?.data?.attributes?.option4_video)}>
                                              </iframe>
                                              : null
                                            }
                                            { ele?.attributes?.question?.data?.attributes?.option4_video.includes('vimeo') && 
                                              <iframe className="answer-video" src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.attributes?.question?.data?.attributes?.option4_video)} height="150" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                                            }
                                          </>
                                        }
                                      </>
                                    }
                                  </div>
                                  }
                              </Grid>
                            </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                    )

                  }
                  <div className="test-series-btns">
                    <div className="test-series-btns-left">
                      <CustomButton onClick={() => this.handlePrevious()} label="Previous" variant="outlined" className="btn-curve btn-white btn-previous" />
                      <CustomButton onClick={() => this.clearAnswer(this.state.current)} label="Clear" color="secondary" className="btn-curve btn-clear btn-danger" />
                    </div>
                    <div className="test-series-btns-right">
                      <CustomButton onClick={() => this.handleNext()} label={((questions.length) != this.state.current) ? "Save & Next" : "Save"} className="btn-curve btn-primary btn-save" />
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid className="question-box-right" item md={4}>
              <div className="ques-widget">
                <div className="ques-widget-top">
                  <div className="answers-widget">
                    <p> {questions.length} Questions </p>
                    <ul className="answered-un">
                      <li><FiberManualRecordIcon /> {this.state.answeredCount} Answered</li>
                      <li><FiberManualRecordIcon /> {questions.length - this.state.answeredCount} Unanswered</li>
                    </ul>
                    <p>{questions.length} Questions</p>
                  </div>
                  <ul className="questions">
                    {questions && questions.length > 0 &&
                      questions.map((que: any, index: number) =>
                        <li key={index}>
                          <Button
                            variant="contained"
                            className={`ques ${(this.state.current - 1 !== index ? 'unselect' : "")}`}
                            onClick={(e) => this.setCurrent(e)}
                          >
                            {index + 1}
                          </Button>
                        </li>
                      )}
                  </ul>
                </div>
                <div className="ques-widget-bottom">
                  <CustomButton onClick={() => this.handleOpenDialog()} label="END TEST" className="btn-curve btn-primary btn-end" />
                  <Dialog
              open={this.state.openDialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <span className="dialog-heading">Are you Sure you want to End the Test?</span>
                  <span className="dialog-body">{(questions.length == this.state.answeredCount) ? 'Great Work! You have answered all Questions.' : 'You have answered ' + this.state.answeredCount + ' Questions out of ' + questions.length + '.'}</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-footer">
                <Button variant="contained" color="primary" className="btn-cancel btn-curve" onClick={()=>this.handleCloseDialog()} color="primary">
                  RESUME
                </Button>
                <Button variant="contained" color="secondary" className="btn-logout btn-curve" onClick={()=>this.openScoreDialog()} color="primary" autoFocus>
                  END TEST
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openScoreDialog}
              onClose={this.state.openScoreDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description2"
            >
              {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description2" className="dailog-box">
                  <span className="dialog-heading">Your Score is?</span>
                  <span className="dialog-body">
                    <span className="score">{this.state.resultPercentage}/100</span>
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-footer">
                <Button variant="contained" color="primary" className="btn-cancel btn-curve" onClick={()=>this.props.history.push('/result-details/' + this.state.examId )} color="primary">
                  RESULT DETAILS
                </Button>
                <Button variant="contained" color="secondary" className="btn-logout btn-curve" onClick={()=>this.props.history.push('/')} color="primary" autoFocus>
                  BACK TO HOME
                </Button>
              </DialogActions>
            </Dialog>
                  {/* <Modal
                                    open={()=>this.openModal()}
                                    onClose={()=>this.handleClose()}
                                >
                                    {displayModalData()}
                                </Modal> */}
                </div>
              </div>

            </Grid>
            </Grid>
           : <h3 className="text-center w-100">No data found</h3>
          }
        </div>
        <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default TestSeriesPackage;
const GeneralTestSeriesToast = withSnackBar(GeneralTestSeries);
const GeneralTestSeriesWithLoader = withLoader(GeneralTestSeriesToast);
export default withRouter(GeneralTestSeriesWithLoader);