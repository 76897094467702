//@ts-nocheck 
import React, { Component } from "react";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { registrationLogo,welcomeUserImage } from "./assets";
// import "./style.css"
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";
interface Props {
    navigation: any;
    id: string;
    showLoader : any;
    hideLoader : any;
}

interface S {
    message: string;
    title: string;
    buttonText: string;
    buttonMessage: Message;
    userName: string
}

interface SS {
    id: any;
}

class EmailAccountWelcomeUser extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);

        this.state = {
            message: "An error has occured.",
            buttonText: "Ok",
            // buttonMessage: new Message(""),
            title: "ERROR",
            userName: ''
        };

        // this.subScribedMessages = [
        //     getName(MessageEnum.RestAPIResponceMessage),
        //     getName(MessageEnum.NavigationPayLoadMessage)
        // ];

        // this.receive = this.receive.bind(this);
        // runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    componentDidMount() {
        if(registrationLogo) {
            this.props.hideLoader();
        } else {
            this.props.showLoader();
        }
    }
    render() {
        const userName = localStorage.getItem('userName')
        return (
            <div className="welcome-widget" >
                <div className="welcome-info">
                    <img src={registrationLogo} />
                    <div className="welcome-content">
                        {userName && <h1>Welcome, {userName} !</h1>}
                        <p>
                        Nursing Experts is an organisation engulfed with state of the art competence to provide more relevant and comprehensive training for Nursing Officer, Nursing tutor, Competitive exam, Nursing Entrance exam Preparation (NEEP),Foundation course for GNM/B.Sc. /P.B.B.Sc Nursing Students (Classroom Programme), Test Series Solution Programme for government Jobs and other competitive exams aspirants. Being one of the best coaching center for nursing coaching, we are dedicated to provide solutions which would cater to the diversified needs of the students enabling them to be output oriented.
                        </p>
                        <button
                            // onClick={() => window.location.href='/' }
                            className="btn-primary btn-gohome"
                        >
                            <NavLink to="/">
                            GO TO HOME PAGE
                            </NavLink>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    // async receive(from: String, message: Message) {
    //     if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
    //         const title = message.getData(getName(MessageEnum.InfoPageTitleMessage));
    //         var content = message.getData(getName(MessageEnum.InfoPageBodyMessage));

    //         var buttonText = message.getData(
    //             getName(MessageEnum.InfoPageButtonTextMessage)
    //         );

    //         var buttonMessage = message.getData(
    //             getName(MessageEnum.InfoPageNavigationScreenMessage)
    //         );
    //         var userName = message.getData(
    //             getName(MessageEnum.AccountUserDetails)
    //         );

    //         this.setState({
    //             message: content,
    //             title: title,
    //             buttonMessage: buttonMessage,
    //             buttonText: buttonText,
    //             userName: userName
    //         });
    //     }
    // }
}


const EmailAccountWelcomeUserWithLoader = withLoader(EmailAccountWelcomeUser);
export default withRouter(EmailAccountWelcomeUserWithLoader);