// @ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import GalleryController, {
  Props,
  configJSON
} from "./GalleryController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import Modal from '@material-ui/core/Modal';
import { withRouter } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import MyFooterWeb from "./MyFooter.web";
import ArrowKeysReact from 'arrow-keys-react';
import HeaderWeb from "./Header.web";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
class Gallery extends GalleryController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   openModel: false
    // }
    ArrowKeysReact.config({
      left: () => {
        this.prevImage()
      },
      right: () => {
        this.nextImage()
      },
      up: () => {
        this.setState({
          content: 'up key detected.'
        });
      },
      down: () => {
        this.setState({
          content: 'down key detected.'
        });
      }
    });
  }
  componentDidMount () {
    // debugger
    document.body.scrollTop = 0;
    this.getGallery()
  }
  handleOpen = (data) => {
    this.setState({openModel:true, selectedObj: data});
  };

  handleClose = () => {
    this.setState({openModel:false});
  };
  nextImage = () => {
    this.state?.gallery && this.state?.gallery.length>0 &&
    this.state?.gallery.filter((ele, ind) => {
        if(ele.id == this.state.selectedObj.id) {
            if(ind !== this.state?.gallery?.length - 1) {
                this.setState({selectedObj: this.state?.gallery[ind+1] });
            } else {
                this.setState({selectedObj: this.state?.gallery[0] });
            }
        } 
    })
  }
  prevImage = () => {
    this.state?.gallery && this.state?.gallery.length>0 &&
    this.state?.gallery.filter((ele, ind) => {
        if(ele.id == this.state.selectedObj.id) {
            if(ind !== 0) {
                this.setState({selectedObj: this.state?.gallery[ind-1] });
            } else {
                this.setState({selectedObj: this.state?.gallery[this.state?.gallery?.length - 1] });
            }
        }
    })
  }
  render() {
    const gallery = this.state?.gallery;
    // let section1 = this.state.home?["section1"];
    const { navigation } = this.props;
    const _this = this;
    return (
      <div className="top-gap" {...ArrowKeysReact.events}>
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="container">
        <div className="section-heading">
          <h3><span>Gallery</span></h3>
          </div>
            <div className="gallery-widget">
                <ul>
                    {
                        gallery && gallery.length>0 &&
                        gallery.map((ele, ind)=>
                            <li key={ele.id}>
                                <div onClick={()=>this.handleOpen(ele)} className="gallery-image-info">
                                    <img src={ele?.attributes?.image} alt="" />
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
            <Modal
              open={this.state.openModel}
              onClose={()=>this.handleClose()}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className="modal-data"
            >
                <div className="gallery-slide">
                <a className="closeGallery" onClick={()=>this.handleClose()}><CloseIcon /></a>
                    <ArrowBackIosIcon onClick={()=>this.prevImage()}/>
                <ul className="model-gallery">
                        {
                            gallery && gallery.length>0 &&
                            gallery.map((ele, ind)=>
                            <li key={ele?.id} >
                                {
                                    this.state.selectedObj.id == ele.id &&
                                    <div className="selected-image">
                                    <img src={ele?.attributes?.image} alt="" />
                                    </div>
                                }
                            </li>
                            )
                        }
                    </ul>
                    <ArrowForwardIosIcon onClick={()=>this.nextImage()}/>
                </div>
            </Modal>
        </div>
          <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default Gallery;
// const GalleryWithLoader = withLoader(Gallery);
// export default withRouter(GalleryWithLoader);
// export default HomeScreen;