import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { registrationBg, registrationImage, registrationLogo, leftArrow, loginCap, loginImage, logo } from "./assets";
import { getAvailableCourses, getCity, getQualifications, getStates, validator, registerAccount } from './EmailAccountRegistrationController.web';
import { withRouter,RouteComponentProps } from "react-router";
import { withLoader } from '../../../components/src/withLoader.Web';
interface IQualification {
    id: string,
    type: string
    attributes: {
        name: string
    }
}
interface IEmailRegist {
    showLoader:any,
    hideLoader:any
}

interface IRegist {
    full_name: string,
    full_phone_number: string,
    email: string,
    state: string,
    city: string,
    qualification_id: string,
    interested_course_id: string,
    other_qualification: string,
    other_interested_course: string,
    [key:string]:string
}

const useStyles = makeStyles({
    header: {
        paddingBottom: '10px'
    },
    input: {
        width: '100%',
        padding: '12px 20px',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxSizing: 'border-box',
        marginBottom: '10px',
        color: 'grey'
    },
    select: {
        width: '100%',
        backgroundColor: '#fff',
        padding: '8px 20px',
        margin: '4px 0',
        color: 'grey',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    button: {
        width: '100%',
        color: 'white',
        border: 'none',
        backgroundColor: '#1890ff',
        borderRadius: '23px',
        cursor: 'pointer',
    },
    span: {
        // border:'none',
        width: '80%',
        padding: '10px 18px',
        borderRadius: '15px',
        backgroundColor: '#1890ff',
    },
    link: {
        textDecoration: 'underline',
        textAlign: 'right',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#292C95',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        margin: '10px'
    },
    loginBtn: {
        width: '40%',
        backgroundolor: '#E3EDF8 !important',
        padding: '12px 25px',
        color: '#3895FF',
        border: 'none',
        borderRadius: '20px',
        cursor: 'pointer',
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
        fontWeight: 500,

    },
    selectGroup: {
        width: '100%',
        argin: "4px 0",
    },
    btnheader: {
        padding: '5px',
        fontWeight: 500,
    },
    alignbtn: {
        display: 'flex',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderRadius: '10px',
        alignItems: 'center',
    },
    formControl: {
        position: 'relative',
        border: " 1px solid #ccc",
        borderRadius: "5px",
        width: "100%",
        margin: "0 auto",
        paddingRight: "13px",
        maxWidth: "100%",
        marginTop: "10px",
        marginBottom: '17px',
    },
    // selectArrow: {
    //     position: 'relative',
    //     "&:after": {
    //         content: "no-close-quote",
    //         borderTop: "9px solid grey",
    //         borderLeft: "7px solid transparent",
    //         borderRight: "7px solid transparent",
    //         position: "absolute",
    //         right: "3px",
    //         top: "8px",
    //     }
    // },
    half: {
        display: 'flex',
        width: '100%',
        margin: ' 0 auto',
        marginBottom: '13px',
        marginTop: '10px',
        justifyContent: 'space-between',
        alignItems: ' center',
    },
    formControl2: {
        position: 'relative',
        border: " 1px solid #ccc",
        borderRadius: "5px",
        width: "56%",
        marginRight: 10,
        paddingRight: "13px",
        maxWidth: "77%",
        marginTop: "0px",
        marginBottom: '8px',
    },
    formControl3: {
        position: 'relative',
        border: " 1px solid #ccc",
        borderRadius: "5px",
        width: "56%",
        paddingRight: "13px",
        maxWidth: "77%",
        marginTop: "0px",
        marginBottom: '8px',
    },
    image: {
        width: '100%',
        zIndex: 999,
        height: 'auto'
    },
    left: {
        backgroundColor: '#4271C9',
        color: 'white',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // borderStartStartRadius: '20px',
        // borderBottomLeftRadius: '20px'
    },
    right: {
        // backgroundColor: 'white',
        // padding: '20px',
        // height: '100%',
        // borderTopRightRadius: '20px',
        // borderBottomRightRadius: '20px'
    },
    wrapper: {
        paddingTop: '5%',
        paddingLeft: '20%',
        paddingRight: '20%',
        height: '60%',
        paddingBottom: '9%',
        display: 'flex',
    },
    main: {
        backgroundImage: `url(${registrationBg})`,
        backgroundSize: '100%'
    },
    wrapperMain: {
        borderRadius: '25px'
    },
    error: {
        border: '1px solid red'
    },
    errorMsg: {
        fontSize: '12px',
        color: 'red',
        textAlign: 'left',
        display: 'block',
        fontWeight: 'bold',
        margin: '10px 0',
        fontStyle: 'italic'
    }
})

// let history = useNavigate();
const numberOnly = (evt: any) => {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
}
const EmailAccountRegistration = (props:IEmailRegist & RouteComponentProps) => {
    const classes = useStyles();
    const [registData, setRegistData] = useState<IRegist>({

        full_name: "",
        full_phone_number: "",
        email: "",
        state: "",
        city: "",
        device_id: "",
        qualification_id: "",
        interested_course_id: "",
        other_qualification: "",
        other_interested_course: ""
    })
    const [allQualifications, setAllQualifications] = useState<Array<IQualification>>([]);
    const [allCourses, setAllCourses] = useState<Array<IQualification>>([]);
    const [allStates, setAllStates] = useState<Array<string>>([]);
    const [allCities, setAllCities] = useState<Array<string>>([]);
    const [emailRegex, setEmailRegex] = useState<null | RegExp>(null);
    const [error, setError] = useState<any>(false);
    const [otherQualificationField, setOtherQualificationField] = useState<any>(false);
    const [otherInterestedCourseField, setOtherInterestedCourseField] = useState<any>(false);
    const [errorMsg, setErrorMsg] = useState('');

    const { full_name, full_phone_number, email, state, city, qualification_id, interested_course_id, other_qualification, other_interested_course } = registData;

    useEffect(() => {
        prepareFieldsData();
    }, [])

    useEffect(() => {
        getCity(state, setAllCities);
    }, [registData.state])

    const prepareFieldsData = () => {
        getQualifications(setAllQualifications);
        getAvailableCourses(setAllCourses);
        getStates(setAllStates);
        validator(setEmailRegex);
    }
//     let otherQualification = "";
//     let otherInterestedCourse = "";
//     const handleChangeQualification: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
//     | HTMLSelectElement
// > = (e) => {
//         otherQualification = e.target.value;
//     }
//     const handleChangeInterestedCourse: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
//     | HTMLSelectElement
// > = (e) => {
//         otherInterestedCourse = e.target.value;
//     }
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
        | HTMLSelectElement
    > = (e) => {
        if (e.target.name === 'full_phone_number') {
            if(e.target.value.length > 10) {
                setErrorMsg('Invaid Phone Number');
            } else {
                setErrorMsg('');
            }
        }
        if (e.target.name === 'qualification_id') {
            let sel:any = document.getElementById("qualificationId")
            let selValue = sel.options[sel.selectedIndex].text;
            if (selValue == 'Other') {
                setOtherQualificationField(true)
            } else {
                setOtherQualificationField(false)
            }
        }
        if (e.target.name === 'interested_course_id') {
            let sel:any = document.getElementById("interestedCourseId")
            let selValue = sel.options[sel.selectedIndex].text;
            if (selValue == 'Other') {
                setOtherInterestedCourseField(true)
            } else {
                setOtherInterestedCourseField(false)
            }
        }
        setRegistData({
            ...registData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLAnchorElement> = async (event) => {
        event.preventDefault();
        let allError: any = {};
        setError({});
        setErrorMsg('');
        let fields = ['full_name', 'full_phone_number', 'email', 'state', 'city', 'qualification_id', 'interested_course_id', 'other_qualification', 'other_interested_course'];
        if (!full_phone_number || !email || !full_name || !qualification_id || !full_name || !state || !city || !interested_course_id) {
            setErrorMsg("*Please provide value to all fields");
            fields.map((field: string) => {
                if (!registData[field]) {
                    allError[field] = 'is Required';
                }
            })
            setError(allError);
            return;
        }

        else if (emailRegex && !emailRegex.test(email)) {
            // window.alert('Please enter valid email');
            setError({ email: 'Please enter a valid email' });
            setErrorMsg("*Please enter a valid email");
            return;
        }
        props.showLoader();
        const res = await registerAccount(registData, props.history.push);
        props.hideLoader();
        setError(res);
        const fieldsMap:IRegist={
           full_name: "Name",
            full_phone_number: "Phone Number",
            email: "Email",
            state: "State",
            city: "City",
            qualification_id: "Qualification",
            interested_course_id: "Course",
            other_qualification: "Other Qualification",
            other_interested_course: "Other Interested Course"
        }
        let field=Object.keys(res).join(' ');
        setErrorMsg(`* ${fieldsMap[field]} ${Object.values(res).join(' ')} `);
    }


    return (
        <div >
            {/* <Grid container className={`${classes.wrapper} registWrapper`}>
                <Grid item xs={12} md={3}>
                    <div className={`${classes.left} registLeft`}>
                        <h3 style={{ textAlign: 'center' }}>Welcome to <br /> Nursing Experts</h3>
                        <div className="regist">
                            <img src={registrationImage} alt="" />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <div className={`${classes.right} registRight`} >

                        <div className="login-logo">
                            <img className={classes.image} src={registrationLogo} />
                        </div>
                        <Grid container>
                            <Grid md={2}></Grid>
                            <Grid item xs={12} md={8}>
                                <div className="reg-form" style={{ textAlign: "center" }}>
                                    <h3>Signup</h3>
                                    <div className={classes.header}>Create your Account in Simple Steps</div>

                                    <form onSubmit={handleSubmit}>
                                        <input type='text' placeholder="Full Name" className={`${classes.input}  ${(error.full_name ? classes.error : '')}`} name="full_name" onChange={handleChange} value={full_name} />
                                        <input type='text' placeholder="Email Id" className={`${classes.input}  ${(error.email ? classes.error : '')}`} name="email" onChange={handleChange} value={email} />
                                        <input type='number' placeholder="Mobile Number" className={`${classes.input}  ${(error.full_phone_number ? classes.error : '')}`} name="full_phone_number" onChange={handleChange} value={full_phone_number} />
                                        <div className={`${classes.formControl}  ${(error.qualification_id ? classes.error : '')}`}>
                                            <select
                                                className={classes.select}
                                                value={qualification_id}
                                                name='qualification_id'
                                                onChange={handleChange}
                                            >
                                                <option value="" disabled selected >Qualification</option>
                                                {allQualifications && allQualifications.map((qual: IQualification) => (
                                                    <option
                                                        value={qual.id}
                                                    >
                                                        {qual.attributes.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className={classes.selectArrow}></span>
                                        </div>
                                        <div className={`${classes.formControl}  ${(error.interested_course_id ? classes.error : '')}`}>
                                            <select
                                                className={classes.select}
                                                value={interested_course_id}
                                                name='interested_course_id'
                                                onChange={handleChange}
                                            >
                                                <option value="" disabled selected hidden>Interested Courses</option>
                                                {allCourses && allCourses.map((course: IQualification) => (
                                                    <option
                                                        value={course.id}
                                                    >
                                                        {course.attributes.name}
                                                    </option>
                                                ))}

                                            </select>

                                            <span className={classes.selectArrow}></span>
                                        </div>
                                        <div className={classes.half}>
                                            <div className={`${classes.formControl2}  ${(error.state ? classes.error : '')}`}>
                                                <select
                                                    value={state}
                                                    name="state"
                                                    className={`${classes.select} ${classes.selectGroup}`}
                                                    onChange={handleChange}
                                                >{allStates && allStates.map((state: string) => (
                                                    <option
                                                        value={state}
                                                    >
                                                        {state}
                                                    </option>
                                                ))}
                                                    <option value="" disabled selected hidden>State</option>
                                                </select>
                                                <span className={classes.selectArrow}></span>
                                            </div>
                                            <div className={`${classes.formControl2}  ${(error.city ? classes.error : '')}`}>
                                                <select
                                                    value={city}
                                                    className={`${classes.select} ${classes.selectGroup}`}
                                                    name="city"
                                                    onChange={handleChange}
                                                >
                                                    <option value="" disabled selected hidden>City</option>
                                                    {allCities && allCities.map((city: string) => (
                                                        <option
                                                            value={city}
                                                        >
                                                            {city}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className={classes.selectArrow}></span>
                                            </div>
                                        </div>
                                        {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
                                        <button type={"submit"} className={classes.button} >
                                            <span className={classes.alignbtn}>
                                                <span className={classes.btnheader}>Set Password</span>
                                                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
                                            </span>
                                        </button>
                                    </form>
                                    <div>
                                        <span
                                            onClick={() => { window.location.href = '/' }}
                                        ><p className={classes.link}>
                                                <img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
                                        <p> Already have an Account? </p>
                                        <button className={classes.loginBtn}
                                            onClick={() => { props.history.push('login') }}
                                        >Login Here</button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid md={2}></Grid>
                    </div>
                </Grid>
            </Grid> */}
            <div className="login-widget">
            <div className="login">
              <div className="login-left">
                <h3>Welcome to <br /> Nursing Experts</h3>
                <img src={loginImage} alt="" />
              </div>
            <div className="login-right">
            <div className="login-logo"><img src={logo} /> </div>
                {/* Merge Engine UI Engine Code */}
                <div className={`${classes.right} registRight`} >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <div className="reg-form" style={{ textAlign: "center" }}>
                                    <h3 className="login-right-heading">Signup</h3>
                                    <div className={classes.header}>Create your Account in Simple Steps</div>

                                    <form onSubmit={handleSubmit}>
                                        <input type='text' placeholder="Full Name" className={`${classes.input}  ${(error.full_name ? classes.error : '')}`} name="full_name" onChange={handleChange} value={full_name} />
                                        <input type='text' placeholder="Email Id" className={`${classes.input}  ${(error.email ? classes.error : '')}`} name="email" onChange={handleChange} value={email} />
                                        <input type='tel' placeholder="Mobile Number" className={`${classes.input}  ${(error.full_phone_number ? classes.error : '')}`} name="full_phone_number" onChange={handleChange} value={full_phone_number} onKeyPress={(e)=>numberOnly(e)}/>
                                        <div className={`${classes.formControl}  ${(error.qualification_id ? classes.error : '')}`}>
                                            <select
                                                className={classes.select}
                                                value={qualification_id}
                                                name='qualification_id'
                                                onChange={handleChange}
                                                id="qualificationId"
                                            >
                                                <option value="" disabled selected>Qualification</option>
                                                {allQualifications && allQualifications.map(( qual: IQualification) => (
                                                    <option key={qual.id}
                                                        value={qual.id}
                                                    >
                                                        {qual.attributes.name}
                                                    </option>
                                                ))}
                                                {/* <option value="others">Others</option> */}
                                            </select>
                                            {/* <span className={classes.selectArrow}></span> */}
                                        </div>
                                            { otherQualificationField &&
                                                <input 
                                                type='text' 
                                                placeholder="Enter Qualification" 
                                                className={`${classes.input}  ${(error.qualification_id ? classes.error : '')}`}
                                                
                                                name='other_qualification'
                                                onChange={handleChange}
                                                />
                                            }
                                        <div className={`${classes.formControl}  ${(error.interested_course_id ? classes.error : '')}`}>
                                            <select
                                                className={classes.select}
                                                value={interested_course_id}
                                                name='interested_course_id'
                                                onChange={handleChange}
                                                id="interestedCourseId"
                                            >
                                                <option value="" disabled selected hidden>Interested Courses</option>
                                                {allCourses && allCourses.map((course: IQualification) => (
                                                    <option key={course.id}
                                                        value={course.id}
                                                    >
                                                        {course.attributes.name}
                                                    </option>
                                                ))}

                                            </select>

                                            {/* <span className={classes.selectArrow}></span> */}
                                        </div>
                                        { otherInterestedCourseField &&
                                                <input 
                                                type='text' 
                                                placeholder="Enter Interested Course" 
                                                className={`${classes.input}  ${(error.qualification_id ? classes.error : '')}`}
                                                
                                                name='other_interested_course'
                                                onChange={handleChange} 
                                                />
                                            }
                                        <div className={classes.half}>
                                            <div className={`${classes.formControl2}  ${(error.state ? classes.error : '')}`}>
                                                <select
                                                    value={state}
                                                    name="state"
                                                    className={`${classes.select} ${classes.selectGroup}`}
                                                    onChange={handleChange}
                                                >{allStates && allStates.map((state: string) => (
                                                    <option key={state}
                                                        value={state}
                                                    >
                                                        {state}
                                                    </option>
                                                ))}
                                                    <option value="" disabled selected hidden>State</option>
                                                </select>
                                                {/* <span className={classes.selectArrow}></span> */}
                                            </div>
                                            <div className={`${classes.formControl3}  ${(error.city ? classes.error : '')}`}>
                                                <select
                                                    value={city}
                                                    className={`${classes.select} ${classes.selectGroup}`}
                                                    name="city"
                                                    onChange={handleChange}
                                                >
                                                    <option value="" disabled selected hidden>City</option>
                                                    {allCities && allCities.map((city: string) => (
                                                        <option key={city}
                                                            value={city}
                                                        >
                                                            {city}
                                                        </option>
                                                    ))}
                                                </select>
                                                {/* <span className={classes.selectArrow}></span> */}
                                            </div>
                                        </div>
                                        {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
                                        <button type={"submit"} className={classes.button} >
                                            <span className={classes.alignbtn}>
                                                <span className={classes.btnheader}>Set Password</span>
                                                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
                                            </span>
                                        </button>
                                    </form>
                                    <div>
                                        <span
                                            onClick={() => {  props.history.push('/') }}
                                        ><p className={classes.link}>
                                                <img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
                                        <p> Already have an Account? </p>
                                        <button className={classes.loginBtn}
                                            onClick={() => { props.history.push('login') }}
                                        >Login Here</button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                {/* Merge Engine UI Engine Code */}
                <span className="cap cap-signup"><img src={loginCap} alt="image" /></span>
                <img className="login-left-mobile" src={loginImage} alt="" />
              </div>
            </div>
          </div>
        </div>
    )
}

export default (withLoader)(withRouter(EmailAccountRegistration));