export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const registrationBg = require("../assets/login-bg.png");
export const registrationImage = require("../assets/login-image.png");
export const registrationCap = require("../assets/cap.png");
export const registrationLogo = require("../assets/logo.png");
export const leftArrow= require("../assets/left-arrow.png");
export const welcomeUserImage =require("../assets/-g-Group.png");
export const loginBg = require("../assets/login-bg.png");
export const loginImage = require("../assets/login-image.png");
export const loginCap = require("../assets/cap.png");
export const logo = require("../assets/logo.png");
