// @ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import MyFooterController, {
  Props,
  configJSON
} from "./MyFooterController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import CustomButton from "../../../components/src/customButton.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
class MyFooter extends MyFooterController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount () {
    document.body.scrollTop = 0;
    this.setState({
      className: 'form-control',
      classNumber: 'form-control',
      classMessage: 'form-control'
    })
  }
  getName = (e) => {
    this.setState({name: e.target.value})
  }
  getNumber = (e) => {
    this.setState({number: e.target.value})
  }
  getMessage = (e) => {
    this.setState({message: e.target.value})
  }
  numberOnly = (evt: any) => {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57)
    {
        evt.preventDefault();
    }
  }
  render() {
    // const data = MyCourses?.attributes
    const { navigation } = this.props;
    const _this = this;
    return (
      <>
        <div className="register container">
          <img src={require('./strip.png')} />
          <div className="register-info">
            <div className="register-left">
              <h3>Many reasons to choose Nursing Experts</h3>
              <p>Hundreds of our students have been selected in various competitive examination. To get Online Coaching , Study Material , and Online Test Series.</p>
            </div>
            <div className="register-right">
              <Button variant="contained" className="btn-curve btn-register" ><NavLink to={'/apply-now'} onClick={()=>this.scrollTop()}>Register Now</NavLink></Button>
            </div>
          </div>
        </div>
        <div className="footer-widget">
          <footer className="container">
            <div className="footer-left">
              <div>
                <img className="footer-logo" src="https://nursingexperts.in/wp-content/uploads/2020/09/logo.png" alt="Image" />
                <ul className="footer-left-ul">
                  <li className="about-decsription">
                    <a >About Us</a>
                    <p>{localStorage.getItem('about') &&
                    localStorage.getItem('about')
                    }</p>
                    <a className="read-more" onClick={() => this.props.history.push('about-us')}>Read more</a>
                  </li>
                  <li onClick={() => this.props.history.push('/gallery')}><a >Gallery</a></li>
                  <li onClick={() => this.props.history.push('/Contactus')}><a >Contact Us</a></li>
                  <li onClick={() => this.props.history.push('/sucess-story')}><a >Success Story</a></li>
                  <li onClick={() => this.props.history.push('/testimonials')}><a >Testmonials</a></li>
                </ul>
              </div>
            </div>
            <div className="footer-middle">
              <div className="footer-block">
                <h3>Our Branches</h3>
                <ul>
                  <li onClick={() => this.props.history.push('/Contactus')}><a >Delhi Branch</a></li>
                  <li onClick={() => this.props.history.push('/Contactus')}><a >Patel Nagar</a></li>
                  <li onClick={() => this.props.history.push('/Contactus')}><a >Rohini</a></li>
                  <li onClick={() => this.props.history.push('/Contactus')}><a >Saket</a></li>
                  <li onClick={() => this.props.history.push('/Contactus')}><a >Rohtak</a></li>
                  <li onClick={() => this.props.history.push('/Contactus')}><a >Deharadun</a></li>
                </ul>
              </div>
              <div className="footer-block">
                <h3>Important Links</h3>
                <ul>
                  <li onClick={() => this.props.history.push('/privacy-policy')}><a >Privacy Policy</a></li>
                  <li onClick={() => this.props.history.push('/terms-and-conditions')}><a >Terms & Conditions</a></li>
                  <li onClick={() => this.props.history.push('/refund-and-cancellation')}><a >Refund & Cancellation Policy</a></li>
                </ul>
              </div>
              <div className="footer-block">
                <h3>Follow Us</h3>
                <ul className="social-links">
                  <li><a className="facebook" href={localStorage.getItem('facebook') ? localStorage.getItem('facebook') : '#'} target="_blank"><FacebookIcon /></a></li>
                  <li><a className="twitter" href={localStorage.getItem('twitter') ? localStorage.getItem('twitter') : '#'} target="_blank"><TwitterIcon /></a></li>
                  <li><a className="linkedin" href={localStorage.getItem('linkedin') ? localStorage.getItem('linkedin') : '#'} target="_blank"><LinkedInIcon /></a></li>
                  <li><a className="instagram" href={localStorage.getItem('instagram') ? localStorage.getItem('instagram') : '#'} target="_blank"><InstagramIcon /></a></li>
            
                 </ul>
                {/* <ul className="social-links">

                  <li><a className="facebook" href={facebook} target="_blank"><FacebookIcon /></a></li>
                  <li><a className="twitter" href={twitter} target="_blank"><TwitterIcon /></a></li>
                  <li><a className="linkedin" href={linkedin} target="_blank"><LinkedInIcon /></a></li>
                  <li><a className="instagram" href={instagram} target="_blank"><InstagramIcon /></a></li>
            
                 </ul> */}
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-block">
                <h3>Contact Us</h3>
                {/* <form className="" noValidate autoComplete="off"> */}
                  <div className="form-group">
                    <input value={this.state.name} id="nameId" className={this.state.className} type="text" placeholder="Your Name" onChange={(e)=>this.getName(e)}/>
                    {
                      this.state.errorName && 
                        <div className="error">
                          Name Required
                        </div>
                    }
                  </div>
                  <div className="form-group">
                    <input value={this.state.number} id="numberId" className={this.state.classNumber} type="tel" placeholder="Phone Number" onChange={(e)=>this.getNumber(e)} onKeyPress={(e)=>this.numberOnly(e)}/>
                    {
                      this.state.errorMobile && 
                        <div className="error">
                          Invalid Number
                        </div>
                    }
                  </div>
                  <div className="form-group">
                    <textarea value={this.state.message} id="messageId" className={this.state.classMessage} rows={3} placeholder="Your Message" onChange={(e)=>this.getMessage(e)}></textarea>
                    {
                      this.state.errorMessage && 
                        <div className="error">
                          Message Required
                        </div>
                    }
                  </div>
                  <div className="form-group">
                    <Button onClick={(e)=>this.contactRequest(e)} className="btn-register-now btn-curve" variant="contained" color="primary">REGISTER NOW</Button>
                  </div>
                {/* </form> */}
                <div className="store-links">
                  <a href={localStorage.getItem('google-play') ? localStorage.getItem('google-play') : '#'} target="_blank"><img src={require('./google-play.png')} /></a>
                  <a href={localStorage.getItem('app-store') ? localStorage.getItem('app-store') : '#'} target="_blank"><img src={require('./app-store.png')} /></a>
                  {/* <a href={googleStore} target="_blank"><img src={require('./google-play.png')} /></a>
                  <a href={appStore} target="_blank"><img src={require('./app-store.png')} /></a> */}
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default MyFooter;
const MyFooterToast = withSnackBar(MyFooter);
export default withRouter(MyFooterToast);