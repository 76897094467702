Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";

exports.btnExampleTitle = "CLICK ME";
exports.endPointApiGetHome = "homepage/homepage";
exports.contentTypeApiGetHome = "application/json";
exports.methodTypeApiGetHome = "GET";
exports.endPointApiGetVacancies = "content_management/vacancies";
exports.contentTypeApiGetVacancies = "application/json";
exports.methodTypeApiGetVacancies = "GET";
exports.endPointApiGetVacancyDetails = "content_management/vacancies/";
exports.contentTypeApiGetVacancyDetails = "application/json";
exports.methodTypeApiGetVacancyDetails = "GET";
exports.apiApplyNowType = "POST";
exports.apiEndPointApplyNow = "apply_form/applies";
exports.contentTypeApiApplyNow = "application/json";
exports.methodTypeApiGetApplyDetails = "GET";
exports.endPointApiGetApplyDetails = "apply_section/apply_nows";
exports.contentTypeApiGetApplyDetails = "application/json";
exports.endPointApiGetQualifications = "profile/qualifications";
exports.methodTypeApiGetQualifications = "GET";
exports.contentTypeApiGetQualifications = "application/json";
exports.endPointApiGetInterestedCourses = "profile/courses";
exports.methodTypeApiGetInterestedCourses = "GET";
exports.contentTypeApiGetInterestedCourses = "application/json";
exports.endPointApiGetOurPrograms = "course/our_programs";
exports.methodTypeApiGetOurPrograms = "GET";
exports.contentTypeApiGetOurPrograms = "application/json";
exports.endPointApiGetClassroomCourses = "course/classroom_courses";
exports.methodTypeApiGetClassroomCourses = "GET";
exports.contentTypeApiGetClassroomCourses = "application/json";
exports.endPointApiGetTestSeriesPackage = "course/courses?key=test_series_package";
exports.methodTypeApiGetTestSeriesPackage = "GET";
exports.contentTypeApiGetTestSeriesPackage = "application/json";
exports.endPointApiGetNeetBsc = "course/courses?key=neet_bsc_course";
exports.methodTypeApiGetNeetBsc = "GET";
exports.contentTypeApiGetGlobalStudyCourses = "application/json";
exports.endPointApiGetGlobalStudyCourses = "course/global_study_courses";
exports.methodTypeApiGetGlobalStudyCourses = "GET";
exports.contentTypeApiGetGlobalStudyCourses = "application/json";
exports.endPointApiGetPreviousYearPapers = "question/previous_year_question";
exports.methodTypeApiGetPreviousYearPapers = "GET";
exports.contentTypeApiGetPreviousYearPapers = "application/json";
exports.endPointApiGetMyCourses = "profile/enrolled_courses";
exports.methodTypeApiGetMyCourses = "GET";
exports.contentTypeApiGetMyCourses = "application/json";
exports.endPointApiGetOnlineCourse = "course/online_courses";
exports.methodTypeApiGetOnlineCourse = "GET";
exports.contentTypeApiGetOnlineCourse = "application/json";
exports.endPointApiGetCourse = "course/courses/";
exports.methodTypeApiGetCourse = "GET";
exports.contentTypeApiGetCourse = "application/json";
exports.endPointApiGetFormAttchment = "course/global_study_course_attachments";
exports.methodTypeApiGetFormAttchment = "POST";
exports.contentTypeApiGetFormAttchment = "multipart/form-data";
exports.endPointApiGetCourseRegister = "course/global_study_course_registrations";
exports.methodTypeApiGetCourseRegister = "POST";
exports.contentTypeApiGetCourseRegister = "application/json";
exports.endPointApiGetDeleteAttachment = "course/global_study_course_attachments/";
exports.methodTypeApiGetDeleteAttachment = "DELETE";
exports.contentTypeApiGetDeleteAttachment = "application/json";
exports.endPointApiEnrollNow = "profile/enrolled_courses";
exports.methodTypeApiEnrollNow = "POST";
exports.contentTypeApiEnrollNow = "application/json";
exports.endPointApiGetGallery = "content_management/galleries";
exports.methodTypeApiGetGallery = "GET";
exports.contentTypeApiGetGallery = "application/json";
exports.endPointApiGetAbout = "settings/abouts";
exports.methodTypeApiGetAbout = "GET";
exports.contentTypeApiGetAbout = "application/json";
exports.endPointApiPostMyFooter = "contact_us/contact_requests";
exports.methodTypeApiPostMyFooter = "POST";
exports.contentTypeApiPostMyFooter = "application/json";
exports.methodTypeApiPostEnrollNow = "PUT";
exports.endPointApiGetMeetingRecordings = "profile/enrolled_courses/";
exports.methodTypeApiGetMeetingRecordings = "GET";
exports.endPointApiGetDigitalLibrary = "course/digital_library";
exports.methodTypeApiGetDigitalLibrary = "GET";
exports.contentTypeApiGetDigitalLibrary = "application/json";
exports.endPointApiUpdatePayment = "profile/enrolled_courses/";
exports.methodTypeApiUpdatePayment = "GET";
exports.endPointApiShowStudyMaterial = "course/courses/";
exports.methodTypeApiShowStudyMaterial = "GET";
exports.endPointApiGetSubjectsAndTopics = "question/subject";
exports.methodTypeApiGetSubjectsAndTopics = "GET";
exports.contentTypeApiGetSubjectsAndTopics = "application/json";
exports.endPointApiGetStudyMaterial = "course/study_materials";
exports.methodTypeApiGetStudyMaterial = "GET";
exports.contentTypeApiGetStudyMaterial = "application/json";
exports.endPointApiGetValidateSession = "profile/profile";
exports.methodTypeApiGetValidateSession = "GET";
exports.contentTypeApiGetValidateSession = "application/json";
exports.endPointApiGetQaList = "question/topic/";
exports.methodTypeApiGetQaList = "GET";
exports.contentTypeApiGetQaList = "application/json";
exports.endPointApiGetScoreCard = "scoring/score_card";
exports.methodTypeApiGetScoreCard = "GET";
exports.contentTypeApiGetScoreCard = "application/json";
exports.endPointApiPostStartTest = "question/exam";
exports.methodTypeApiPostStartTest = "POST";
exports.contentTypeApiPostStartTest = "application/json";
exports.endPointApiSaveAnswer = "question/exam_question/";
exports.methodTypeApiSaveAnswer = "PATCH";
exports.contentTypeApiSaveAnswer = "application/json";
exports.endPointApiReAppearTest = "question/exam/";
exports.methodTypeApiReAppearTest = "PATCH";
exports.contentTypeApiReAppearTest = "application/json";
exports.endPointApiEndTest = "question/exam/";
exports.methodTypeApiEndTest = "PATCH";
exports.contentTypeApiEndTest = "application/json";
exports.endPointApiGetNotifications = "notifications/notifications";
exports.methodTypeApiGetNotifications = "GET";
exports.contentTypeApiGetNotifications = "application/json";
exports.endPointApiPostStartGeneralTest = "question/exam/create_general_test";
exports.methodTypeApiPostStartGeneralTest = "POST";
exports.contentTypeApiPostStartGeneralTest = "application/json";
exports.endPointApiGetSuccessStories = "video_library/success_story";
exports.methodTypeApiGetSuccessStories = "GET";
exports.contentTypeApiGetSuccessStories = "application/json";
exports.endPointApiGetOpenedNotifications = "notifications/notifications/";
exports.methodTypeApiGetOpenedNotifications = "PATCH";
exports.contentTypeApiGetOpenedNotifications = "application/json";
exports.endPointApiGetDeleteNotifications = "notifications/notifications/";
exports.methodTypeApiGetDeleteNotifications = "DELETE";
exports.contentTypeApiGetDeleteNotifications = "application/json";
exports.apiEndPointSetPassword = "profile/set_password";
exports.apiTypeSetPassword = "PATCH";
exports.contentTypeApiSetPassword = "application/json";
exports.endPointApiGetContactUs = "contact_us/contacts";
exports.methodTypeApiGetContactUs = "GET";
exports.contentTypeApiGetContactUs = "application/json";
exports.endPointApiGetStates = "profile/locations";
exports.methodTypeApiGetStates = "GET";
exports.contentTypeApiGetStates = "application/json";
exports.endPointApiGetCities = "profile/locations?state=";
exports.methodTypeApiGetCities = "GET";
exports.contentTypeApiGetCities = "application/json";
exports.apiSignupType = "POST";
exports.apiEndPointSignup = "account/signup";
exports.contentTypeSignup = "application/json";
exports.endPointApiRazorPay = "profile/enrolled_courses/";
exports.methodTypeApiRazorPay = "GET";
exports.contentTypeApiRazorPay = "application/json";
exports.methodTypeApiGetTestimonials = "GET";
exports.contentTypeApiGetTestimonials = "application/json";
exports.endPointApiGetTestimonials = "content_management/testimonials";
// Customizable Area End