//@ts-nocheck
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { useHistory } from "react-router-dom";
// Customizable Area End

export const configJSON = require("./config");
import { RouterProps } from "react-router";
export type Props = RouterProps & {
  id: string;
  showToast : any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  classEmail: any;
  phoneNumber: any;
  classPhoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  avatar: any;
  qualification: any;
  classQualification: any;
  qualifications: any[];
  interestedCourse: any;
  classInterestedCourse: any;
  interestedCourseList: any[];
  city: any;
  classCity: any;
  cities: any[];
  state: any;
  classState: any;
  states: any[];
  showForm: boolean;
  fullName: any;
  classFullName: any;
  userAttr1: any[];
  selectedQualification: any;
  selectedQualificationID: any;
  selectedInterestedCourseID: any;
  showCity: boolean;
  selectedProfileImage: any;
  showErrorMessage: boolean;
  otherQualificationField: boolean;
  otherInterestedCourseField: boolean;
  otherQualification: string;
  otherInterestedCourse: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  avatar: any;
  // qualification: any;
  interestedCourse: any;
  city: any;
  state: any;
  showForm: boolean;
  fullName: any;
  labelFullName: string;
  labelQualification: string;
  labelInterestedCourse: string;
  labelState: string;
  labelCity: string;
  qualificationsGetApiCallId: any;
  interestedCoursesGetApiCallId: any;
  statesGetApiCallId: any;
  citiesGetApiCallId: any;
  otherQualificationField: boolean;
  otherInterestedCourseField: boolean;
  otherQualification: string;
  otherInterestedCourse: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      firstName: "",
      // lastName: "",
      email: "",
      classEmail: "",
      phoneNumber: "",
      classPhoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      avatar: "",
      qualification: "",
      classQualification: "",
      qualifications: [],
      interestedCourse: "",
      interestedCourseList: [],
      city: "",
      classCity: "",
      cities: [],
      state: "",
      classState: "",
      states: [],
      showForm: false,
      fullName: "",
      labelQualification: "",
      labelInterestedCourse: "",
      userAttr1: [],
      selectedQualification: "",
      showCity: false,
      selectedQualificationID: "",
      selectedInterestedCourseID: "",
      selectedProfileImage: "",
      showErrorMessage: false,
      classInterestedCourse: "",
      otherQualificationField: false,
      otherInterestedCourseField: false,
      otherQualification: "",
      otherInterestedCourse: ""
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    this.labelFullName = configJSON.labelFullName;
    this.labelQualification = configJSON.labelQualification;
    this.labelInterestedCourse = configJSON.labelInterestedCourse;
    this.labelState = configJSON.labelState;
    this.labelCity = configJSON.labelCity;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        // debugger
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";
              let avatar = this.userAttr.avatar ? this.userAttr.avatar : null;
              let qualification = this.userAttr.qualification;
              let interestedCourse = this.userAttr.interested_course;
              let otherQualification = this.userAttr.other_qualification;
              let otherInterestedCourse = this.userAttr.other_interested_course;
              let city = this.userAttr.city;
              let state = this.userAttr.state;
              let fullName = this.userAttr.full_name;
              this.setState({
                email: email,
                firstName: firstName,
                // lastName: lastName,
                phoneNumber: phoneNumber,
                avatar: avatar,
                qualification: qualification,
                interestedCourse: interestedCourse,
                city: city,
                state: state,
                fullName: fullName,
                otherQualification: otherQualification,
                otherInterestedCourse: otherInterestedCourse
              });
              localStorage.setItem("userName", fullName);
            localStorage.setItem("avatar", avatar);
              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              //@ts-ignore
              this.txtInputFullNameProps.value = fullName;

              //@ts-ignore
              // this.txtInputQualificationProps.value = qualification;

              //@ts-ignore
              this.txtInputInterestedCourseProps.value = interestedCourse;

              //@ts-ignore
              this.txtInputStateProps.value = state;

              //@ts-ignore
              this.txtInputCityProps.value = city;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
            this.props.hideLoader();

          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
          this.getCities(this.state.state);
          this.state.qualifications.filter((ele: any) => {
            if(ele.attributes.name == this.state.qualification) {
              this.setState({selectedQualificationID: ele.id})
            }
          })
          this.state.interestedCourseList.filter((ele: any) => {
            if(ele.attributes.name == this.state.interestedCourse) {
              this.setState({selectedInterestedCourseID: ele.id})
            }
          })
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson && responseJson?.data && responseJson?.data?.attributes) {
            this.props.showToast({type : 'success', message : 'Profile updated successfully.'});
            this.props.hideLoader();
            this.getQualifications();
            this.enableDisableEditPassword(false);
            this.getInterestedCourses();
            this.getStates();
            this.getUserProfile();
            this.setState({showForm: false})
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } else if (apiRequestCallId === this.qualificationsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              qualifications: this.userAttr
            });
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session Expired Log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.interestedCoursesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              interestedCourseList: this.userAttr
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session Expired Log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.statesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              states: this.userAttr
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session Expired Log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.citiesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              cities: this.userAttr
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session Expired Log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;
          // debugger
          this.getQualifications();
          this.getInterestedCourses();
          this.getStates();
          this.getUserProfile();
      }
    }

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    // if (error) {
    //   this.showAlert(configJSON.errorTitle, error);

    //   return;
    // }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    // if (mobileNo && countryCode) {
    //   this.validateMobileOnServer(
    //     this.state.currentCountryCode,
    //     this.state.phoneNumber
    //   );
    // } else {
      // }
    this.validateAndUpdateProfile();
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }
  
  validateAndUpdateProfile() {
    if(!this.state.interestedCourse) {
      this.setState({ classInterestedCourse: 'form-select error-field' })
    } else {
      this.setState({classInterestedCourse: 'form-select' })
    }
    if(!this.state.fullName) {
      this.setState({classFullName: 'form-control error-field'})
    } else {
      this.setState({classFullName: 'form-control'})
    }
    if(!this.state.email) {
      this.setState({classEmail: 'form-control error-field'})
    } else {
      this.setState({classEmail: 'form-control'})
    }
    if(!this.state.phoneNumber) {
      this.setState({classPhoneNumber: 'form-control error-field'})
    } else {
      this.setState({classPhoneNumber: 'form-control'})
    }
    // qualification vaidation
    if(!this.state.qualification) {
      this.setState({classQualification: 'form-select error-field'})
    } else {
      this.setState({classQualification: 'form-select'})
    }
    if(!this.state.state) {
      this.setState({classState: 'form-select error-field'})
    } else {
      this.setState({classState: 'form-select'})
    }
    if(!this.state.city) {
      this.setState({classCity: 'form-select error-field'})
    } else {
      this.setState({classCity: 'form-select'})
    }

    if(!this.state.interestedCourse ||
       !this.state.qualification ||
       !this.state.fullName ||
       !this.state.email ||
       !this.state.state ||
       !this.state.city ||
       !this.state.phoneNumber
       ) {
         this.setState({showErrorMessage: true})
         return
       } else {
        this.setState({showErrorMessage: false})
       }
    // let firstName = this.state.firstName;
    // let lastName = this.state.lastName;
    let fullName = this.state.fullName;
    let qualification = this.state.qualification;
    let interestedCourse = this.state.interestedCourse;
    let state = this.state.state;
    let city = this.state.city;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    // const errorFirstName = this.validateFirstName(firstName);
    // const errorLastName = this.validateLastName(lastName);
    const errorFullName = this.validateLastName(fullName);
    const errorQualification = this.validateLastName(qualification);
    const errorInterestedCourse = this.validateLastName(interestedCourse);
    const errorState = this.validateLastName(state);
    const errorCity = this.validateLastName(city);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    // if (errorFirstName != null) {
    //   this.showAlert(configJSON.errorTitle, errorFirstName);
    //   // return false;
    // } else if (errorLastName != null) {
    //   this.showAlert(configJSON.errorTitle, errorLastName);
    //   // return false;
    // } 
    if (errorFullName != null) {
      this.showAlert(configJSON.errorTitle, errorFullName);
      return false;
    } else if (errorQualification != null) {
      this.showAlert(configJSON.errorTitle, errorQualification);
      return false;
    } else if (errorInterestedCourse != null) {
      this.showAlert(configJSON.errorTitle, errorInterestedCourse);
      return false;
    } else if (errorState != null) {
      this.showAlert(configJSON.errorTitle, errorState);
      return false;
    } else if (errorCity != null) {
      this.showAlert(configJSON.errorTitle, errorCity);
      return false;
    } 

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let fullNameOld = this.userAttr.full_name;
      let qualificationOld = this.userAttr.qualification;
      let interestedCourseOld = this.userAttr.interested_course;
      let stateOld = this.userAttr.state;
      let cityOld = this.userAttr.city;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      // if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
      //   firstName = null;
      // }

      if (this.isNonNullAndEmpty(fullName) && fullName === fullNameOld) {
        fullName = null;
      }

      if (this.isNonNullAndEmpty(qualification) && qualification === qualificationOld) {
        qualification = null;
      }

      if (this.isNonNullAndEmpty(interestedCourse) && interestedCourse === interestedCourseOld) {
        interestedCourse = null;
      }

      if (this.isNonNullAndEmpty(state) && state === stateOld) {
        state = null;
      }

      if (this.isNonNullAndEmpty(city) && city === cityOld) {
        city = null;
      }

      // if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
      //   lastName = null;
      // }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      // this.isNonNullAndEmpty(firstName) ||
      // this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(fullName)   || 
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      this.isNonNullAndEmpty(fullName) ||
      this.isNonNullAndEmpty(qualification) ||
      this.isNonNullAndEmpty(interestedCourse) ||
      this.isNonNullAndEmpty(state) ||
      this.isNonNullAndEmpty(city) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        // "Content-Type": configJSON.contentTypeApiProfileUpdateUser,
        token: localStorage.getItem("authToken")
        // token: this.authToken
      };

      this.props.showLoader();

      let data: any = {
        // first_name: this.state.firstName,
        // last_name: this.state.lastName,
        full_name: this.state.fullName,
        qualification: this.state.qualification,
        interestedCourse: this.state.interestedCourse
      };
      const formData = new FormData();
      formData.append("data[full_name]", this.state.fullName);
      formData.append("data[email]", this.state.email);
      formData.append("data[state]", this.state.state);
      formData.append("data[city]", this.state.city);
      formData.append("data[account_qualification_attributes][qualification_id]", this.state.selectedQualificationID);
      formData.append("data[account_interested_course_attributes][interested_course_id]", this.state.selectedInterestedCourseID);
      formData.append("data[other_qualification]", this.state.otherQualification ? this.state.otherQualification : "",);
      formData.append("data[other_interested_course]", this.state.otherInterestedCourse ? this.state.otherInterestedCourse : "");
      {
        this.state.selectedProfileImage &&
        formData.append("data[avatar]", this.state.selectedProfileImage)
      }
      // formData.append("data[avatar]", this.state.avatar);
      // formData.append("data[remove_avatar]", 'false');
      // console.log('formData', formData);
      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: formData
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiProfileUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      // token: this.authToken
      token: localStorage.getItem('authToken')
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: localStorage.getItem('authToken')
    };

    this.props.showLoader();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  getQualifications() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.qualificationsGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetQualifications
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetQualifications,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetQualifications
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getInterestedCourses() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.interestedCoursesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetInterestedCourses
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetInterestedCourses,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetInterestedCourses
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStates() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.statesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetStates
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetStates,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetStates
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCities(name: any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.citiesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetCities +''+ name
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCities,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetCities
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
     onChangeText: (text:string) => 
     {
       if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
     
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
       }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType:"tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps  = {
    value: "",
    editable: true,
    onChangeText: (text:string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps  = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
  ? this.txtInputEmailWebProps
  : this.txtInputEmailMobileProps;
  
  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => { 
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ 
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry });
        this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
        this.imgNewPasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText:(text:string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({ 
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry });
        this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
        this.imgReTypePasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }
  toggleForm = {
    onPress: () => {
      this.setState({showForm: !this.state.showForm})
    },
  }
  txtInputFullNameProps = {
    onChangeText: (text: string) => {
      this.setState({ fullName: text });

      //@ts-ignore
      this.txtInputFullNameProps.value = text;
    }
  };
  // txtInputQualificationProps = {
  //   onChangeText: (text: string) => {
  //     this.setState({ qualification: text });

  //     //@ts-ignore
  //     this.txtInputQualificationProps.value = text;
  //   }
  // };
  txtInputInterestedCourseProps = {
    onChangeText: (text: string) => {
      this.setState({ interestedCourse: text });

      //@ts-ignore
      this.txtInputInterestedCourseProps.value = text;
    }
  };
  txtInputStateProps = {
    onChangeText: (text: string) => {
      this.setState({ state: text });

      //@ts-ignore
      this.txtInputStateProps.value = text;
    }
  };
  txtInputCityProps = {
    onChangeText: (text: string) => {
      this.setState({ city: text });

      //@ts-ignore
      this.txtInputCityProps.value = text;
    }
  };
  validateFullName(fullName: String) {
    return !this.isNonNullAndEmpty(fullName)
      ? "Full name " + configJSON.errorBlankField
      : null;
  }
  validateQualification(qualification: String) {
    return !this.isNonNullAndEmpty(qualification)
      ? "Qualification " + configJSON.errorBlankField
      : null;
  }
  validateInterestedCourse(interestedCourse: String) {
    return !this.isNonNullAndEmpty(interestedCourse)
      ? "Interested Course " + configJSON.errorBlankField
      : null;
  }
  validateState(state: String) {
    return !this.isNonNullAndEmpty(state)
      ? "State " + configJSON.errorBlankField
      : null;
  }
  validateCity(city: String) {
    return !this.isNonNullAndEmpty(city)
      ? "City " + configJSON.errorBlankField
      : null;
  }
  citiesProps = (name: any) => {
    this.getCities(name)
  };
  onFileChange = event => {
    let file = URL.createObjectURL(event.target.files[0]);
    this.setState({ avatar: file, selectedProfileImage:event.target.files[0] });
  
  };
  onFileUpload = () => {
    
    // Create an object of formData
    const formData = new FormData();
  
    // Update the formData object
    formData.append(
      "myFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
  
    // Details of the uploaded file
  }
  // Customizable Area End

}
