import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader: any;
  hideLoader: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  startTest: any;
  current: number;
  output: string;
  questions: any[];
  answeredQuestions: [];
  saveAnswerData: any;
  clearAnswerData: any;
  reAppearTestData: any;
  endTestData: any;
  questionId: string;
  openDialog: boolean;
  openScoreDialog: boolean;
  examId: string;
  seconds: number;
  time: any;
  resultPercentage: string;
  savedQAObj: {};
  answeredCount:number;
  selectedAnswers: any[];
  selectedOption: number;
  // savedQAArray: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TestSeriesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  startTestGetApiCallId: string = "";
  saveAnswerApiCallId: string = "";
  reAppearTestApiCallId: string = "";
  endTestApiCallId: string = "";
  clearAnswerApiCallId: string = "";
  startTest: {};
  current: number;
  output: string;
  questions: any[];
  answeredQuestions: [];
  saveAnswerData: {};
  clearAnswerData: {};
  questionId: string;
  reAppearTestData: any;
  endTestData: any;
  openDialog: boolean;
  openScoreDialog: boolean;
  examId: string;
  seconds: number;
  timer: any;
  resultPercentage: string;
  savedQAObj: {};
  savedQAArray: any[];
  answeredCount:number;
  selectedAnswers: any[];
  selectedOption: number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      startTest: {},
      current: 1,
      output: '',
      questions: [],
      answeredQuestions: [],
      saveAnswerData: {},
      clearAnswerData: {},
      questionId: '',
      reAppearTestData: {},
      endTestData: {},
      openDialog: false,
      openScoreDialog: false,
      examId: '',
      seconds: 0,
      time: {},
      resultPercentage: '',
      savedQAObj: {},
      answeredCount: 0,
      selectedAnswers: [],
      selectedOption: 0
      // Customizable Area End
    };
    this.savedQAArray = [];
    this.selectedAnswers = [];
    this.timer = 0;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.startTestGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.startTest = responseJson.data;
            this.examId = responseJson.data?.id;
            this.seconds = responseJson.data?.attributes?.actual_duration*60;
            this.questions = responseJson.data?.attributes?.exam_questions?.data;
            this.setState({
              startTest: this.startTest,
              questions: this.questions,
              examId: this.examId,
              seconds: this.seconds
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.saveAnswerApiCallId) {
          if(responseJson.success) {
            this.saveAnswerData = responseJson.data;
            if(this.savedQAArray.indexOf(this.state.savedQAObj) == -1) {
              this.savedQAArray.push(this.state.savedQAObj)
              localStorage.setItem('answeredQA', JSON.stringify(this.savedQAArray))
              let count: any;
              count = localStorage.getItem('answeredQA')
              this.setState({answeredCount: JSON.parse(count).length})
            }
            // if(this.savedQAArray && this.savedQAArray.length > 0) {
            //   this.savedQAArray.map((ele:any, ind)=>{
            //     if(ele.attributes.question.data.attributes.id == this.state.questionId) {
            //       debugger
            //       this.savedQAArray.splice(ind, 1)
            //       this.savedQAArray.push(this.state.savedQAObj)
            //     } else {
            //       this.savedQAArray.push(this.state.savedQAObj)
            //     }
            //   })
            // } else {
            //   this.savedQAArray.push(this.state.savedQAObj)
            // }
            this.setState({
              saveAnswerData: this.saveAnswerData,
              answeredQuestions: this.answeredQuestions,
              // savedQAArray: this.savedQAArray
            });
            this.props.showToast({type : 'success', message : 'Saved Successfully'});
          } else if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.saveAnswerData = responseJson.data;
            this.setState({
              saveAnswerData: this.saveAnswerData
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.reAppearTestApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.reAppearTestData = responseJson.data;
            this.setState({
              reAppearTestData: this.reAppearTestData
            });
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.endTestApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.endTestData = responseJson.data;
            this.resultPercentage = responseJson.data?.attributes?.result_percentage;
            this.setState({
              endTestData: this.endTestData,
              resultPercentage: this.resultPercentage
            });
            // this.props.history.push('')
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.clearAnswerApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.clearAnswerData = responseJson.data;
            this.setState({
              clearAnswerData: this.clearAnswerData
            });
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getQuestionsAndAnswers() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.startTestGetApiCallId = requestMessage.messageId;
    let _id = window.location.pathname.split('/').pop()

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiPostStartTest
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiPostStartTest,
      token: localStorage.getItem('authToken')
    };

    let data: any = {
      "topic_id": _id
    }

    const httpBody = {
      'exam': data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiPostStartTest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  saveAnswer() {
    if(this.state.questionId && this.state.output != "") {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.saveAnswerApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiSaveAnswer + this.state.questionId
      );
  
      const header = {
        "Content-Type": configJSON.contentTypeApiSaveAnswer,
        token: localStorage.getItem('authToken')
      };
  
      let data: any = {
        "selected_option": this.state.selectedOption
      }
  
      const httpBody = {
        "exam_question": data
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiSaveAnswer
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  clearAnswer(currentNumber: any) {
    const checkedRadio1 = document.querySelectorAll(`[name='my-answer${currentNumber}']`);
    checkedRadio1.forEach((ele: any, _ind)=> {
      ele.checked = false
    })
    this.setState({questionId: ''})
  }
  reAppearTest() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reAppearTestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiReAppearTest + this.state.questionId
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiReAppearTest,
      token: localStorage.getItem('authToken')
    };

    let data: any = {
      "status": "started"
    }

    const httpBody = {
      "exam": data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiReAppearTest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  endTest() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.endTestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiEndTest + this.state.examId
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiEndTest,
      token: localStorage.getItem('authToken')
    };

    let data: any = {
      "status": "finished"
    }

    const httpBody = {
      "exam": data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiEndTest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setOutput(e: any, ele: any) {
    ele['qAnsweredId'] = e.target.value
    this.setState({
      output: e.target.value, 
      questionId: ele?.id,
      savedQAObj: ele,
    })
    this.collectAnswers(e.target.value)
    // this.checkedRadio(e.target.value)
  }
  collectAnswers(value: any) {
    if(this.selectedAnswers.indexOf(value) == -1) {
      this.selectedAnswers.push(value)
      this.setState({selectedAnswers: this.selectedAnswers})
    }
  }
  checkedRadio(value: any) {
    if(this.state.selectedAnswers && this.state.selectedAnswers.length > 0) {
      this.state.selectedAnswers.map((ele)=>{
        if(ele == value) {
          return
        }
      })
    }
  }
  handlePrevious() {
    if (this.state.current == 1) {
        return;
    } else {
      this.setState({current: this.state.current-1});
    }
    this.setState({questionId: ''})
  }

  handleNext() {
    this.saveAnswer()
    if (this.state.current === this.state.questions.length) {
        return;
    } else {
      this.setState({current: this.state.current+1});
    }
    // this.setState({questionId: ''})
  }
  skip() {
    if (this.state.current === this.state.questions.length) {
      this.setState({current: 1});
    } else {
      this.setState({current: this.state.current+1});
    }
  }
  setCurrent(e: any) {
    this.setState({current: Number(e.target.innerText)})
  }

  secondsToTime(secs: any){
    if(secs == 0) {
      this.setState({openScoreDialog: true})
      this.endTest()
    }
    // debugger
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  countDown(seconds:any) {
    // Remove one second, set state so a re-render happens.
    if(seconds >= 1) {
      this.setState({
        time: this.secondsToTime(seconds - 1),
        seconds: seconds - 1,
      });
    }
    
    // Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(0);
    }
  }
  // Customizable Area End
  
}
