import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");
import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  applyDetails: any;
  selectedFiles: any[];
  vacancyDetails: any;
  files: any[];
  previewFiles: any[];
  fullName: string;
  Pwd: string;
  classPwd: string;
  CPwd: string;
  classCPwd: string;
  fatherName: string;
  classFatherName: string;
  dob: string;
  classDob: string;
  email: string;
  classEmail: string;
  dep_degree: string;
  contactNo: any;
  classContactNo: any;
  gender: string;
  classGender: string;
  courseType: string;
  classCourseType: string;
  // qualification: string;
  classQualification: string;
  yourMessage: string;
  classYourMessage: string;
  showErrorMessage: boolean;
  showErrorEmail: boolean;
  errorMobile: boolean;
  qualification: any;
  qualifications: any[];
  interestedCourse: any;
  interestedCourseList: any[];
  selectedQualificationID: any;
  selectedInterestedCourseID: any;
  redirect: any;
  otherQualificationField: boolean;
  otherInterestedCourseField: boolean;
  otherQualification: string;
  otherInterestedCourse: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SetsPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  setPasswordApiCallId: string = "";
  applyDetails: any;
  vacancyDetails: any;
  qualificationsGetApiCallId: any;
  interestedCoursesGetApiCallId: any;
  userAttr: any;
  otherQualificationField: boolean;
  otherInterestedCourseField: boolean;
  otherQualification: string;
  otherInterestedCourse: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      applyDetails: {},
      vacancyDetails: {},
      selectedFiles: [],
      files: [],
      previewFiles: [],
      fullName: '',
      Pwd: '',
      classPwd: 'form-control',
      CPwd: '',
      classCPwd: 'form-control',
      fatherName: '',
      classFatherName: '',
      dob: '',
      classDob: '',
      email: '',
      classEmail: '',
      dep_degree: '',
      contactNo: null,
      classContactNo: '',
      gender: '',
      classGender: '',
      courseType: '',
      classCourseType: '',
      // qualification: '',
      classQualification: '',
      yourMessage: '',
      classYourMessage: '',
      showErrorMessage: false,
      showErrorEmail: false,
      errorMobile: false,
      qualification: "",
      qualifications: [],
      interestedCourse: "",
      interestedCourseList: [],
      selectedQualificationID: "",
      selectedInterestedCourseID: "",
      redirect: "",
      otherQualificationField: false,
      otherInterestedCourseField: false,
      otherQualification: "",
      otherInterestedCourse: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.setPasswordApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            this.props.showToast({type : 'success', message : 'Password created successfully'})
            localStorage.setItem('userName', responseJson.data.attributes.full_name);
            localStorage.setItem('email', responseJson.data.attributes.email);
            localStorage.setItem('phoneNumber', responseJson.data.attributes.phone_number);
            localStorage.setItem('loginId', responseJson.data.id);
            this.props.history.push({
              pathname: '/welcome-user',
            })
            // window.location.href="/"
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getSetPassword() {
    if(localStorage.getItem("authToken")) {
    if(!this.state.Pwd) {
      this.setState({classPwd: 'form-control error-field', showErrorMessage: true})
    } else {
      this.setState({classPwd: 'form-control', showErrorMessage: false})
    }
    // father name vaidation
    if(!this.state.CPwd) {
      this.setState({classCPwd: 'form-control error-field', showErrorMessage: true})
    } else {
      this.setState({classCPwd: 'form-control', showErrorMessage: false})
    }
    let pattern=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
      if(!this.state?.Pwd?.match(pattern) || !this.state?.CPwd?.match(pattern)) {
          this.setState({
            yourMessage: '*Password should have atleast 1 Capital letter, Small letter, Digit, Special symbol and minimum length 8 ',
            showErrorMessage: true
          });
          return
      }
      if(!this.state.Pwd || !this.state.CPwd){
          this.setState({
            yourMessage: '*Please provide value to all fields',
            showErrorMessage: true
          });
          return ;
      }
      if (this.state.Pwd !== this.state.CPwd) {
          this.setState({
            yourMessage: '*Create password and confirm password should be same',
            showErrorMessage: true
          });
          return;
      }
      const header = {
        "Content-Type": configJSON.contentTypeApiSetPassword,
        token: localStorage.getItem("authToken")
        // token: this.authToken
      };

      // this.props.showLoader();

      let data: any = {
        new_password: this.state.Pwd,
      };

      const httpBody = {
        data: data
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
      this.setPasswordApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointSetPassword
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiTypeSetPassword
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  } else {
    this.props.history.push("/signup")
  }
  }
  getPassword = (e: any) => {
    this.setState({Pwd: e.target.value})
  }
  getConfirmPassword = (e: any) => {
    this.setState({CPwd: e.target.value})
  }
}
