import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  home: any[];
  settings: any[];
  section_1: any[];
  section_2: any;
  section_3: any;
  section_4: any;
  section_5: any;
  section_6: any;
  section_7: any;
  section_8: any;
  section_9: any;
  about: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HomeScreenController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  homeGetApiCallId: string = "";
  settingsGetApiCallId: string = "";
  aboutGetApiCallId: string = "";
  home:any[];
  settings:any[];
  section_1: any[];
  section_2: any;
  section_3: any;
  section_4: any;
  section_5: any;
  section_6: any;
  section_7: any;
  section_8: any;
  section_9: any;
  about: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      home: [],
      settings: [],
      section_1: [],
      section_2: {},
      section_3: {},
      section_4: {},
      section_5: {},
      section_6: {},
      section_7: {},
      section_8: {},
      section_9: {},
      about: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.homeGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.section_1 = responseJson?.data?.attributes?.section1?.data;
            this.section_2 = responseJson?.data?.attributes?.section2?.data;
            this.section_3 = responseJson?.data?.attributes?.section3?.data;
            this.section_4 = responseJson?.data?.attributes?.section4?.data;
            this.section_5 = responseJson?.data?.attributes?.section5?.data;
            this.section_6 = responseJson?.data?.attributes?.section6?.data;
            this.section_7 = responseJson?.data?.attributes?.section7;
            this.section_8 = responseJson?.data?.attributes?.section8?.data;
            this.section_9 = responseJson?.data?.attributes?.section9?.data?.attributes;
            this.setState({
              home: this.home,
              section_1: this.section_1,
              section_2: this.section_2,
              section_3: this.section_3,
              section_4: this.section_4,
              section_5: this.section_5,
              section_6: this.section_6,
              section_7: this.section_7,
              section_8: this.section_8,
              section_9: this.section_9,
            });
            this.props.hideLoader();
            // this.props.hideLoader();
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              // this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.settingsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.settings = responseJson.data;
            this.setState({
              settings: this.settings
            });
            for(let i = 0; i<= this.settings.length; i++) {
              if(this.settings[i]?.attributes?.name == 'Toll Free Number') {
                localStorage.setItem('toll-free', this.settings[i]?.attributes?.description)
              }
              if(this.settings[i]?.attributes?.name == 'Facebook Link') {
                localStorage.setItem('facebook', this.settings[i]?.attributes?.description)
              }
              if(this.settings[i]?.attributes?.name == 'Twitter Link') {
                localStorage.setItem('twitter', this.settings[i]?.attributes?.description)
              }
              if(this.settings[i]?.attributes?.name == 'Linkedin Link') {
                localStorage.setItem('linkedin', this.settings[i]?.attributes?.description)
              }
              if(this.settings[i]?.attributes?.name == 'Instagram Link') {
                localStorage.setItem('instagram', this.settings[i]?.attributes?.description)
              }
              if(this.settings[i]?.attributes?.name == 'Google Play Store Link') {
                localStorage.setItem('google-play', this.settings[i]?.attributes?.description)
              }
              if(this.settings[i]?.attributes?.name == 'App Store Link') {
                localStorage.setItem('app-store', this.settings[i]?.attributes?.description)
              }
            }
            // this.props.hideLoader();
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired Log in again.", "");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.aboutGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.about = responseJson.data?.attributes?.description;
            localStorage.setItem('about', this.about)
            // this.props.hideLoader();
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired log in again.", "");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getHome() {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.homeGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetHome
    );
    // this.props.showLoader();
    const header = {
      "Content-Type": configJSON.contentTypeApiGetHome,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetHome
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getSettingsData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.settingsGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "settings/settings"
    );

    const header = {
      "Content-Type": 'aplication/json',
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAbout() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.aboutGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetAbout
    );
    // this.props.showLoader();
    const header = {
      "Content-Type": configJSON.contentTypeApiGetAbout,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetAbout
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
