import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { createTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
// type MyProps = { testID:string, isChecked: boolean; onChangeValue?: (value: boolean) => void };
type MyProps = { 
    testID?:any,
    className?:string,
    label?:string, 
    isIcon?:boolean,
    onClick?:any,
    color?:any,
    variant?:any,
    iconLeft?:any,
    disabled?: boolean
};
export default class CustomButton extends React.Component<MyProps> {
    constructor(props: any){
        super(props)

    }
    render() {
        return(
            <Button 
            disabled={this.props.disabled || false}
            onClick={this.props.onClick}
            variant={this.props.variant || 'contained'}
            color={this.props.color || 'primary'}
            className={this.props.className}>
                {
                    this.props.iconLeft &&
                    <span className="btn-icon-left">{this.props.iconLeft}</span>
                }
          <span className="btn-label">{this.props.label}</span>
         { this.props.isIcon &&  <span className="btn-icon"><TrendingFlatIcon/></span> }
        </Button>
        )
    }
}