// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ToastProvider } from "../../components/src/ToastContext";
import registerServiceWorker from '../../components/src/registerServiceWorker';

ReactDOM.render(
  <Router>
    <ToastProvider>
    <App />
    </ToastProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
