import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");
import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  applyDetails: any;
  selectedFiles: any[];
  vacancyDetails: any;
  files: any[];
  previewFiles: any[];
  fullName: string;
  yourName: string;
  classYourName: string;
  fatherName: string;
  classFatherName: string;
  dob: string;
  classDob: string;
  email: string;
  classEmail: string;
  dep_degree: string;
  contactNo: any;
  classContactNo: any;
  gender: string;
  classGender: string;
  courseType: string;
  classCourseType: string;
  // qualification: string;
  classQualification: string;
  yourMessage: string;
  classYourMessage: string;
  classState: string;
  classCity: string;
  showErrorMessage: boolean;
  showErrorEmail: boolean;
  errorMobile: boolean;
  qualification: any;
  qualifications: any[];
  interestedCourse: any;
  interestedCourseList: any[];
  selectedQualificationID: any;
  selectedInterestedCourseID: any;
  redirect: any;
  otherQualificationField: boolean;
  otherInterestedCourseField: boolean;
  otherQualification: string;
  otherInterestedCourse: string;
  cities: any[];
  states: any[];
  city: any;
  state: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignupController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  applyDetailsGetApiCallId: string = "";
  signUpApiCallId: string = "";
  vacancyDetailsGetApiCallId: string = "";
  applyDetails: any;
  vacancyDetails: any;
  qualificationsGetApiCallId: any;
  interestedCoursesGetApiCallId: any;
  userAttr: any;
  otherQualificationField: boolean;
  otherInterestedCourseField: boolean;
  otherQualification: string;
  otherInterestedCourse: string;
  statesGetApiCallId: any;
  citiesGetApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      applyDetails: {},
      vacancyDetails: {},
      selectedFiles: [],
      files: [],
      previewFiles: [],
      fullName: '',
      yourName: '',
      classYourName: '',
      fatherName: '',
      classFatherName: '',
      dob: '',
      classDob: '',
      email: '',
      classEmail: '',
      dep_degree: '',
      contactNo: null,
      classContactNo: '',
      gender: '',
      classGender: '',
      courseType: '',
      classCourseType: '',
      // qualification: '',
      classQualification: '',
      yourMessage: '',
      classYourMessage: '',
      showErrorMessage: false,
      showErrorEmail: false,
      errorMobile: false,
      qualification: "",
      qualifications: [],
      interestedCourse: "",
      interestedCourseList: [],
      selectedQualificationID: "",
      selectedInterestedCourseID: "",
      redirect: "",
      otherQualificationField: false,
      otherInterestedCourseField: false,
      otherQualification: "",
      otherInterestedCourse: "",
      states: [],
      cities: [],
      state: "",
      city: "",
      classState: '',
      classCity: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.signUpApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            localStorage.setItem('authToken', responseJson?.meta?.token);
            this.props.history.push({
              pathname: '/set-password',
            })
            // window.location.href="/"
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].full_phone_number
            ) {
              this.props.showToast({type : 'error', message : responseJson?.errors[0]?.full_phone_number});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.applyDetailsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            
            this.applyDetails = responseJson.data;
            this.setState({
              applyDetails: this.applyDetails
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.vacancyDetailsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            
            this.vacancyDetails = responseJson.data;
            this.setState({
              vacancyDetails: this.vacancyDetails
            });
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session expired", "log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }else if (apiRequestCallId === this.qualificationsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              qualifications: this.userAttr
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.interestedCoursesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              interestedCourseList: this.userAttr
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.statesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              states: this.userAttr
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session Expired Log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.citiesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data;
            this.setState({
              cities: this.userAttr
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session Expired Log in again.'});
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  signUp() {
    if(!this.state.yourName) {
      this.setState({classYourName: 'form-control error-field'})
    } else {
      this.setState({classYourName: 'form-control'})
    }
    // qualification vaidation
    if(!this.state.qualification) {
      this.setState({classQualification: 'form-select error-field'})
    } else {
      this.setState({classQualification: 'form-select'})
    }
    // courseType vaidation
    if(!this.state.courseType) {
      this.setState({classCourseType: 'form-select error-field'})
    } else {
      this.setState({classCourseType: 'form-select'})
    }
    // email vaidation
    // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!this.state.email) {
      this.setState({classEmail: 'form-control error-field', showErrorEmail: true})
    } else {
      this.setState({classEmail: 'form-control', showErrorEmail: false})
    }
    if(this.state.email) {
      let pattern=/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/g;
      if(!this.state?.email?.match(pattern)) {
        this.setState({classEmail: 'form-control error-field', showErrorEmail: true})
      } else {
        this.setState({classEmail: 'form-control', showErrorEmail: false})
      }
    }
    // contactNo vaidation
    if(!this.state.contactNo || this.state.contactNo.length > 10 || this.state.contactNo.length < 10 ) {
      this.setState({classContactNo: 'form-control error-field', errorMobile:true})
    } else {
      this.setState({classContactNo: 'form-control', errorMobile:false})
    }
    if(!this.state.state) {
      this.setState({classState: 'form-select error-field'})
    } else {
      this.setState({classState: 'form-select'})
    }
    if(!this.state.city) {
      this.setState({classCity: 'form-select error-field'})
    } else {
      this.setState({classCity: 'form-select'})
    }
    if (
      !this.state.yourName || 
      !this.state.qualification || 
      !this.state.email || 
      !this.state.courseType ||
      !this.state.contactNo ||
      !this.state.city ||
      !this.state.state ) {
        // debugger
        this.setState({showErrorMessage: true})
        // your Name vaidation
        return;
      } else {
        this.setState({showErrorMessage: false})
      }
      const header = {
        "Content-Type": configJSON.contentTypeSignup,
        // token: localStorage.getItem("authToken")
        // token: this.authToken
      };

      // this.props.showLoader();

      let data: any = {
              "type": "sms_account",
              "attributes": {
                  "full_phone_number": '91' + this.state.contactNo,
                  "full_name": this.state.yourName,
                  "email": this.state.email,
                  "state": this.state.state,
                  "city": this.state.city,
                  "device_id": "", // When signup from mobile
                  "account_qualification_attributes": {
                      "qualification_id": this.state.selectedQualificationID
                  },
                  "account_interested_course_attributes": {
                      "interested_course_id": this.state.selectedInterestedCourseID
                  },
                  "other_qualification": this.state.otherQualification ? this.state.otherQualification : "",
                  "other_interested_course": this.state.otherInterestedCourse ? this.state.otherInterestedCourse : ""
              }
    }
      const httpBody = {
        data: data
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
      this.signUpApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointSignup
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiSignupType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  getApplyDetails() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyDetailsGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetApplyDetails
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetApplyDetails,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetApplyDetails
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getQualifications() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.qualificationsGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetQualifications
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetQualifications,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetQualifications
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getInterestedCourses() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.interestedCoursesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetInterestedCourses
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetInterestedCourses,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetInterestedCourses
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStates() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.statesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetStates
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetStates,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetStates
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCities(name: any) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.citiesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetCities +''+ name
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCities,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetCities
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getContact = (e: any) => {
    let value = e.target.value;
    if(!value || value.length > 10 ) {
      this.setState({classContactNo: 'form-control error-field', errorMobile:true})
    } else {
      this.setState({classContactNo: 'form-control', errorMobile:false, contactNo: value})
    }
  }
}
