//@ts-nocheck 
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Picker
} from "react-native";
import CustomButton from "../../../components/src/customButton.web";

import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController.web";
import EditIcon from '@material-ui/icons/Edit';
// import Select from "react-select/src/Select";
import CheckIcon from '@material-ui/icons/Check';
import Footer from "../../../components/src/Footer.web";
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import MyFooterWeb from "../../ContentManagement/src/MyFooter.web";
import HeaderWeb from "../../ContentManagement/src/Header.web";
class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getValidations();
    this.requestSessionData();
    this.setState({
      classInterestedCourse: 'form-select',
      classQualification: 'form-select',
      classState: 'form-control',
      classCity: 'form-control',
      classEmail: 'form-control',
      classPhoneNumber: 'form-control',
      classFullName: 'form-control',
    })
  }

  // Customizable Area Start
  setSelectedQualification = (data: any) => {
    const name_id = data.target.value.split('_');
    const name = data.target.value;
    const id = data;
    if(name_id[0] == 'Other') {
      this.setState({otherQualificationField: true})
    }
    this.setState({qualification: name_id[0], selectedQualificationID: name_id[1]})
  }
  setSelectedCourse = (data: any) => {
    const name_id = data.target.value.split('_');
    const name = data.target.value.split('_')[0];
    const id = data;
    if(name_id[0] == 'Other') {
      this.setState({otherInterestedCourseField: true})
    }
    this.setState({interestedCourse: name_id[0], selectedInterestedCourseID: name_id[1]})
  }
  setSelectedState = (data: any) => {
    const name = data.target.value;
    this.setState({state: name})
    this.citiesProps(name)
    // this.setState({showCity: true})
  }
  setSelectedCity = (data: any) => {
    const name = data.target.value;
    this.setState({city: name})
  }

  getOtherQualification = (e:any) => {
    this.setState({otherQualification: e.target.value})
  }

  getOtherInterestedCourse = (e:any) => {
    this.setState({otherInterestedCourse: e.target.value})
  }
  // Customizable Area End

  render() {
    const _img = this.userAttr;
    return (
      <div className="top-gap">

      {/* <HeaderProfileWeb /> */}
      <HeaderWeb />
      <div className="container">
                 <div className="profile-view">
                   <div className="profile-image">
                     <span>
                       {
                         this.state.avatar
                         ? <img src={this.state.avatar} alt="" />
                         : <img src={require('./default-user.png')} alt="" />
                       }
                       {this.state.showForm &&
                       <div className="edit-profile-pic">
                         <input style={{display: 'none'}} type="file" onChange={this.onFileChange} ref={fileInput => this.fileInput = fileInput} accept="image/*"/>
                         <button className="btn-edit-profile" onClick={()=>this.fileInput.click()}>
                         <EditIcon />
                         </button>
                       </div>
                       }
                     </span>
                   </div>
                   {
                     !this.state.showForm ?
                   <div className="profile-details">
                     <div className="profile-details-left">
                       <div className="profile-name">
                         <h1>{this.state.fullName} </h1>
                       </div>
                       <div className="profile-sub">
                         <div className="form-group">
                           <label className="field-label">EMAIL ID</label>
                           <div className="field-value">{this.state.email}</div>
                         </div>
                         <div className="form-group">
                           <label className="field-label">CONTACT</label>
                           <div className="field-value">{this.state.phoneNumber}</div>
                         </div>
                         <div className="form-group">
                           <label className="field-label">QUALIFICATION</label>
                           <div className="field-value">{this.state.qualification}</div>
                         </div>
                         <div className="form-group">
                           <label className="field-label">INTERESTED COURSE</label>
                           <div className="field-value">{this.state.interestedCourse}</div>
                         </div>
                         { this.state.otherQualification &&
                          <div className="form-group">
                           <label className="field-label">OTHER QUALIFICATION</label>
                           <div className="field-value">{this.state.otherQualification}</div>
                         </div>
                         }
                         { this.state.otherInterestedCourse &&
                          <div className="form-group">
                           <label className="field-label">OTHER INTERESTED COURSE</label>
                           <div className="field-value">{this.state.otherInterestedCourse}</div>
                         </div>
                         }
                         <div className="form-group">
                           <label className="field-label">CITY</label>
                           <div className="field-value">{this.state.city}</div>
                         </div>
                         <div className="form-group">
                           <label className="field-label">STATE</label>
                           <div className="field-value">{this.state.state}</div>
                         </div>
                       </div>
                     </div>
                     <div className="profile-details-right">
                         <CustomButton onClick={()=>this.toggleForm.onPress()} className="btn-curve btn-edit" label="Edit" iconLeft={<EditIcon/>}/>
                     </div>
                   </div> :
                   <div className="profile-details">
                     <div className="profile-details-left">
                       {/* <Text style={styles.titleWhySignUp}>{this.labelHeader}</Text> */}
                       <div className="profile-name">
                         {/* <Text style={styles.title}>{this.labelFirstName}</Text> */}
                         <TextInput
                           testID={"txtInputFullName"} //Merge Engine::From BDS
                           style={styles.bgInput} //UI Engine::From Sketch
                           placeholder={this.labelFullName} //UI Engine::From Sketch
                           {...this.txtInputFullNameProps} //Merge Engine::From BDS - {...this.testIDProps}
                         />
                       </div>
                       <div className="profile-sub-edit side-by-side">
                         <div className="form-group">
                           {/* <Text style={styles.title}>{this.labelEmail}</Text> */}
                           <TextInput
                             testID={"txtInputEmail"} //Merge Engine::From BDS
                             style={styles.bgInput} //UI Engine::From Sketch
                             placeholder={this.labelEmail} //UI Engine::From Sketch
                             {...this.txtInputEmailProps} //Merge Engine::From BDS - {...this.testIDProps}
                           />
                         </div>
                         <div className="form-group">
                           {/* <Text style={styles.title}>{this.labelMobile}</Text> */}
                           <TextInput
                             testID={"txtInputPhoneNumber"} //Merge Engine::From BDS
                             style={styles.bgMobileInput} //UI Engine::From Sketch
                             placeholder={this.labelMobile} //UI Engine::From Sketch
                             {...this.txtInputPhoneNumberProps} //Merge Engine::From BDS - {...this.testIDProps}
                           />
                         </div>
                       </div>
                       {/* <div className="form-group">
                         <Text style={styles.title}>{this.labelArea}</Text>
                         <CountryCodeSelector
                           allowPropChange={true}     //Merge Engine::From BDS
                           navigation={navigation}    //Merge Engine::From BDS
                           id={"CountryCodeSelector"} //Merge Engine::From BDS
                           placeHolder={this.state.currentCountryCode} //UI Engine::From Sketch
                           style={styles.bgRectBorder} //UI Engine::From Sketch
                           disable={!this.state.countryCodeEnabled}  //Merge Engine::From BDS
                           value={this.state.currentCountryCode} //Merge Engine::From BDS
                         /> 
                       </div> */}
                       <div className="form-group">
                             <select className="form-select"
                               onChange={(e)=>{this.setSelectedQualification(e)}}
                             >
                               <option value="" disabled>QUALIFICATION</option>
                               {
                                 this.state.qualifications && this.state.qualifications.length > 0 && 
                                 this.state.qualifications.map((ele:any, ind:any)=>
                                 <option key={ele.id} id={ele.id} value={ele.attributes.name +'_'+ ele.id} selected={this.state.qualification == ele.attributes.name}>{ele.attributes.name}</option>
                                 )
                               }
                             </select>
                       </div>
                       { this.state.otherQualificationField &&
                        <div className="form-group">
                            <input 
                            type='text' 
                            placeholder="Enter Qualification" 
                            name='other_qualification'
                            onChange={(e)=>this.getOtherQualification(e)}
                            className="form-control"
                            />
                            </div>
                        }
                       <div className="form-group">
                           <select className={this.state.classInterestedCourse}
                             onChange={(e)=>{this.setSelectedCourse(e)}}
                           >
                             <option value="" disabled>Interested Course</option>
                               {
                                 this.state.interestedCourseList && this.state.interestedCourseList.length > 0 && 
                                 this.state.interestedCourseList.map((ele:any, ind:any)=>
                                 <option key={ele.id} value={ele.attributes.name +'_'+ ele.id} selected={this.state.interestedCourse == ele.attributes.name}>{ele.attributes.name}</option>
                                 )
                               }
                           </select>
                         
                       </div>
                       { this.state.otherInterestedCourseField &&
                        <div className="form-group">
                            <input 
                            type='text' 
                            placeholder="Enter Interested Course" 
                            name='other_interested_course'
                            onChange={(e)=>this.getOtherInterestedCourse(e)}
                            className="form-control"
                            />
                            </div>
                        }
                       <div className="profile-sub-edit side-by-side">
                         <div className="form-group">
                             <select className="form-select"
                               onChange={(e)=>{this.setSelectedState(e)}}
                             >
                               <option value="" disabled>State</option>
                                 {
                                   this.state.states && this.state.states.length > 0 && 
                                   this.state.states.map((ele:any, ind:any)=>
                                   <option key={ele} value={ele} selected={this.state.state == ele}>{ele}</option>
                                   )
                                 }
                             </select>
                           
                         </div>
                         <div className="form-group">
                             <select
                               className="form-select"
                               onChange={(e)=>{this.setSelectedCity(e)}}
                             >
                               <option value="" disabled>City</option>
                                 {
                                   this.state.cities && this.state.cities.length > 0 && 
                                   this.state.cities.map((ele:any, ind:any)=>
                                   <option key={ele} value={ele} selected={this.state.city == ele}>{ele}</option>
                                   )
                                 }
                             </select>
                         </div>
                       </div>
                         {this.state.showErrorMessage &&
                          <div className="form-group error">All fields are required</div>
                         }
                     </div>
                     <div className="profile-details-right">
                       {/* <View style={{ marginLeft: 32 }}>
                         <Button
                           testID={"btnSubmit"} //Merge Engine::From BDS
                           title={"Save"} //UI Engine::From Sketch
                           color="#6200EE"
                           disabled={this.state.saveButtonDisable}
                           onPress={() => this.validateMobileAndThenUpdateUserProfile()}
                         />
                       </View> */}
                       <CustomButton className="btn-curve btn-success" label="SAVE" iconLeft={<CheckIcon />} onClick={() => this.validateMobileAndThenUpdateUserProfile()}/>
                     </div>
                   </div>
                   }
                 </div>
               </div>
                 <MyFooterWeb />
         </div>
    );
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "100%" : "100%",
    // maxWidth: 1140,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    // marginTop: 0,
    // borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 1 : 1,
    borderRadius: 5,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 10 : 10,
    width: "100%",
    borderColor: '#ced4da',
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: '#ced4da',
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 1 : 1,
    // borderBottomWidth: 1,
    borderColor: '#ced4da',
    borderRadius: 5,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 10 : 10,
    width: "100%"
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 },
  selectField: {
    borderColor: "#767676"
  }
});
const UserProfileBasicBlockToast = withSnackBar(UserProfileBasicBlock);
const UserProfileBasicBlockWithLoader = withLoader(UserProfileBasicBlockToast);
export default withRouter(UserProfileBasicBlockWithLoader);
// Customizable Area End