//@ts-nocheck 
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import PdfMaterialsController, {
  Props,
  configJSON
} from "./PdfMaterialsController.web";
import Footer from "../../../components/src/Footer.web";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import MyFooterWeb from "./MyFooter.web";
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import HeaderWeb from "./Header.web";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const scoreCardList = [
  {
      img: 'course-study.jpeg',
      courseName: "PDF Book Part - 1",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 20:22",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "PDF Book Part - 2",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 20:22",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
  {
      courseName: "PDF Book Part - 3",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 20:22",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "PDF Book Part - 4",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 20:22",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
  {
      courseName: "PDF Book Part - 5",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 20:22",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "PDF Book Part - 6",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 20:22",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
]
class PdfMaterials extends PdfMaterialsController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   openModel: false
    // }
  }
  async componentDidMount () {
    document.body.scrollTop = 0;
    this.showStudyMaterial()
  }
  render() {
    const { navigation } = this.props;
    const _this = this;
    const {studyMaterilList} = this.state
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="container">
          <div className="section-heading">
            <h3><span>Study Materials</span></h3>
          </div>
          <div className="recorded-videos">
            <ul>
            {(studyMaterilList && studyMaterilList.length > 0) 
            ? studyMaterilList.map((ele, ind) =>
                    <li key={ind}>
                      {
                        ele?.attributes?.has_file
                        ? <a className="record-content" href={ele?.attributes?.file + '#toolbar=0'}>
                        <span className="record-img">
                            <img src={ele?.attributes?.thumbnail_image} alt={ele?.attributes?.name} />
                        </span>
                        <div className="record-info">
                            <h3>{ele?.attributes?.name}</h3>
                            <div className="record-date">Uploaded on: {ele.courseDate}</div>
                            {/* <span className="record-time"><AccessTimeIcon /> 03 h 23m</span> */}
                        </div>
                      </a>
                      : <div className="record-content" onClick={()=>this.handleOpen(ele?.attributes?.video_url)}>
                      <span className="record-img">
                          <img src={ele?.attributes?.thumbnail_image} alt={ele?.attributes?.name} />
                      </span>
                      <div className="record-info">
                          <h3>{ele?.attributes?.name}</h3>
                          {/* <div className="record-date">Uploaded on: {ele.courseDate}</div> */}
                          {/* <span className="record-time"><AccessTimeIcon /> 03 h 23m</span> */}
                      </div>
                    </div>
                      }
                      
                    </li>
                    ) : <h3 className="text-center w-100">No records found</h3>
                }
            </ul>
          </div>
        </div>
        <Modal
              open={this.state.openModel}
              onClose={this.handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              className="modal-data"
            >
              <div >
                {/* <a className="modal-title" onClick={()=>this.handleClose}>
                  <span >
                    <CloseIcon />
                  </span>
                </a> */}
                          <iframe className="success-story-iframe" width="420" height="315"
                            src={`https://www.youtube.com/embed/${this.state.iframeUrl.split('=').pop()}?autoplay=1`} allow='autoplay' allowFullScreen="true">
                            {/* src={this.state.iframeUrl+`?autoplay=1`} allow='autoplay' allowFullScreen="true"> */}
                          </iframe>
                      </div>
            </Modal>
          <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default PdfMaterials;
const PdfMaterialsToast = withSnackBar(PdfMaterials);
const PdfMaterialsWithLoader = withLoader(PdfMaterialsToast);
export default withRouter(PdfMaterialsWithLoader);