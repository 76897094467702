import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core"
import { ArrowRightAlt, ArrowBack } from '@material-ui/icons';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { forgotPasswordBg, forgotPasswordImage, forgotPasswordLogo, leftArrow } from "./assets";
import { withRouter, RouteComponentProps } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";

export interface IOther {
  messageId: string,
  navigation: {
    navigate: any
  }
}


let othervalues: IOther = {
  messageId: '',
  navigation: {
    navigate: ''
  }
}

export interface IPassword {
  showLoader: any,
  hideLoader: any
}


export interface Props {
  navigation: any;
  id: string;
}

export interface S {

}

export interface SS {
  id: any;
}

const useStyles = makeStyles({
  header: {
    paddingBottom: '10px',
    margin: '10px',
    fontSize: '12px'
  },
  input: {
    width: '100%',
    padding: '12px 20px',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxSizing: 'border-box',
    marginBottom: '10px'
  },
  span: {
    // border:'none',
    width: '80%',
    padding: '10px 18px',
    borderRadius: '15px',
    backgroundColor: '#1890ff',
  },
  link: {
    textDecoration: 'underline',
    textAlign: 'right',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#292C95',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '14px',
    margin: '10px'
  },
  loginBtn: {
    width: '40%',
    backgroundColor: '#D3F5F5',
    padding: '15px',
    color: 'blue',
    border: 'none',
    borderRadius: '15px'
  },
  form: {
    padding: '0px'
  },
  btnheader: {
    padding: '5px',
    fontWeight: 500,
  },
  alignbtn: {
    display: 'flex',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    padding: '5px 10px',
    borderRadius: '10px'
  },
  button: {
    width: '100%',
    color: 'white',
    border: 'none',
    backgroundColor: '#1890ff',
    borderRadius: '23px',
    marginTop: '20px',
    fontSize: 16
  },
  main: {
    backgroundImage: `url(${forgotPasswordBg})`,
    backgroundSize: '100%',
  },
  left: {
    height: '96%',
    backgroundColor: '#4271C9',
    color: '#fff',
    padding: '20px'
  },
  right: {
    height: '96%',
    backgroundColor: 'white',
    padding: '20px'
  },
  wrapper: {
    paddingTop: '5%',
    paddingLeft: '20%',
    paddingRight: '20%',
    height: '60%',
    paddingBottom: '9%',
    display: 'flex',
  },
  error: {
    border: '1px solid red'
  },
  errorMsg: {
    fontSize: '12px',
    color: 'red',
    textAlign: 'left',
    display: 'block',
    fontWeight: 'bold',
    margin: '10px',
    fontStyle: 'italic'
  },

})

const block = new BlockComponent<Props, S, SS>(
  {
    navigation: '',
    id: '123'
  }
)


const NewPassword = (props: IPassword & RouteComponentProps) => {

  const classes = useStyles();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState<any>(false);
  const [errorMsg, setErrorMsg] = useState('');



  useEffect(() => {
    block.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ]
    runEngine.attachBuildingBlock(block, block.subScribedMessages);

    block.receive = async (from: string, message: Message) => {

      othervalues.navigation.navigate = props.history.push;
      if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
        setToken(message.getData(
          getName(MessageEnum.AuthTokenDataMessage)
        ));
      }

      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        props.hideLoader();
        if (responseJson.error || responseJson.errors) {
          setErrorMsg(responseJson.errors[0]?.otp);
          // setErrorMsg('Invalid Password,Need one caps letter, one digit and minimum length');
          setError({newPassword:'Invald Password', confirmPassword:'Invalid Password'});
          // window.alert('Invalid Password!')
        } else {
          const msg = new Message(getName(MessageEnum.NavigationMessage));

          msg.addData(getName(MessageEnum.NavigationTargetMessage), "password-changed")

          msg.addData(getName(MessageEnum.NavigationPropsMessage), othervalues);
          block.send(msg);
        }
      }
    }
    return (() => {
      runEngine.unSubscribeFromMessages(block, block.subScribedMessages);
    })
  }, []);



  const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setError({});
    setErrorMsg('');
    let errObj:{[key:string]:string}={};
    if (!newPassword || !confirmPassword) {
      !newPassword ? errObj.newPassword='required' :'';
      !confirmPassword ? errObj.confirmPassword='required' :'';
      setError(errObj);
      setErrorMsg(`*Please provide value to all fields`);
      return;
    }
    if (newPassword !== confirmPassword) {

      errObj.newPassword=`Password doesn't matches`;
      errObj.confirmPassword=`Password doesn't matches`;
      setError(errObj);
      setErrorMsg(`Password doesn't matches`);
      return;
    }
    createPassword();
  }

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
    | HTMLSelectElement> = (e) => {
      if (e.target.name === 'password') {
        setNewPassword(e.target.value);
      }
      else {
        setConfirmPassword(e.target.value);
      }
    }

  const createPassword = () => {

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    othervalues.messageId = requestMessage.messageId;
    const body = {
      data: {
        token: token,
        new_password: newPassword
      }
    }
    const httpHeaders = {
      "Content-Type": "application/json"
    };
    props.showLoader();

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      'forgot_password/password');
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(httpHeaders));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  return (
    <div className="login-widget">
                <div className="login">
                    <div className="login-left">
                        <h3>Welcome to <br /> Nursing Experts</h3>
                        <img src={forgotPasswordImage} alt="" />
                    </div>
                    <div className="login-right" style={{ padding: '20px' }}>
                        <div className="login-logo"><img src={forgotPasswordLogo} /> </div>
                        <div className="login-info">
                            <h3 className="login-right-heading">Reset Password</h3>
                            <div className="login-sub-heading">Create your Password for Nursing Expert</div>
                            <form className={classes.form} onSubmit={handleSubmit}>
                    <input
                      type='password'
                      placeholder="Create Password"
                      className={`${classes.input}  ${(error.newPassword ? classes.error : '')}`}
                      name="password"
                      onChange={handleChange}
                      value={newPassword}
                    />
                    <input
                      type='password'
                      placeholder="Confirm Password"
                      className={`${classes.input}  ${(error.confirmPassword ? classes.error : '')}`}
                      name="newpassword"
                      onChange={handleChange}
                      value={confirmPassword}
                    />
                    {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
                    <button type={"submit"} className={classes.button} >
                      <span className={classes.alignbtn}>
                        <span className={classes.btnheader}>Submit</span>
                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
                      </span>
                    </button>
                  </form>
                            <div>
                                <span
                                    onClick={() => { window.location.href = '/' }}
                                ><p className={classes.link}><img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    // <div className={classes.main}>
    //   <Grid container className={`${classes.wrapper} registWrapper`}>
    //     <Grid item xs={12} md={3}>
    //       <div className={`${classes.left} registLeft`}>
    //         <div className="newPswrdImg">

    //           <h3 style={{ textAlign: 'center' }}>Welcome to <br /> Nursing Experts</h3>
    //           <img src={forgotPasswordImage} alt="" />
    //         </div>
    //       </div>
    //     </Grid>
    //     <Grid item xs={12} md={9} >
    //       <div className={`${classes.right} registRight`} >
    //         <div className="login-logo"><img src={forgotPasswordLogo} /> </div>
    //         <div style={{ textAlign: "center", marginTop: "20%", marginBottom: "20%" }} >
    //           <h1>Reset Password</h1>
    //           <span className={classes.header}>Create your Password for Nursing Expert</span>
    //           <Grid container>
    //             <Grid md={2}></Grid>
    //             <Grid md={8} xs={12}>
    //               <form className={classes.form} onSubmit={handleSubmit}>
    //                 <input
    //                   type='password'
    //                   placeholder="Create Password"
    //                   className={`${classes.input}  ${(error.newPassword ? classes.error : '')}`}
    //                   name="password"
    //                   onChange={handleChange}
    //                   value={newPassword}
    //                 />
    //                 <input
    //                   type='password'
    //                   placeholder="Confirm Password"
    //                   className={`${classes.input}  ${(error.confirmPassword ? classes.error : '')}`}
    //                   name="newpassword"
    //                   onChange={handleChange}
    //                   value={confirmPassword}
    //                 />
    //                 {errorMsg && <span className={classes.errorMsg}>{errorMsg}</span>}
    //                 <button type={"submit"} className={classes.button} >
    //                   <span className={classes.alignbtn}>
    //                     <span className={classes.btnheader}>Submit</span>
    //                     <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='20px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
    //                   </span>
    //                 </button>
    //               </form>
    //               <div>
    //                 <span
    //                   onClick={() => { window.location.href = '/' }}
    //                 ><p className={classes.link}><img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
    //               </div>

    //             </Grid>
    //             <Grid md={2}></Grid>
    //           </Grid>
    //         </div>
    //       </div>
    //     </Grid>
    //   </Grid>
    // </div>
  )
}


export default withLoader((withRouter)(NewPassword));