import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { forgotPasswordBg, forgotPasswordCap, forgotPasswordImage, forgotPasswordLogo, leftArrow } from "./assets";
import { withRouter, RouteComponentProps } from "react-router";
import { withLoader } from "../../../components/src/withLoader.Web";


export interface IOther {
    resendMessageId: string,
    validateMessageId: string,
    token: string,
    full_mobile_number: string,
    navigation: {
        navigate: any
    }
}

let othervalues: IOther = {
    resendMessageId: '',
    validateMessageId: '',
    token: '',
    full_mobile_number: '',
    navigation: {
        navigate: ''
    }
}

export interface IForgotOTP {
    showLoader: any,
    hideLoader: any
}


export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    city: string;
    searchState: string;
    messageId: string
}

export interface SS {
    id: any;
}

const useStyles = makeStyles({
    header: {
        paddingBottom: '10px',
        margin: '10px',
        fontSize: '12px'
    },
    input: {
        width: '100%',
        padding: '12px 20px',
        display: 'inline-block',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxSizing: 'border-box'
    },
    span: {
        // border:'none',
        width: '98%',
        padding: '10px 18px',
        borderRadius: '15px',
        backgroundColor: '#1890ff',
    },
    link: {
        textDecoration: 'underline',
        textAlign: 'right',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#292C95',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '14px',
        margin: '10px'
    },
    loginBtn: {
        width: '40%',
        backgroundColor: '#D3F5F5',
        padding: '15px',
        color: 'blue',
        border: 'none',
        borderRadius: '15px'
    },
    form: {
        padding: '0px'
    },

    btnheader: {
        padding: '5px',
        fontWeight: 500,
    },
    alignbtn: {
        display: 'flex',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        padding: '5px 10px',
        borderRadius: '10px'
    },
    button: {
        width: '100%',
        color: 'white',
        border: 'none',
        backgroundColor: '#1890ff',
        borderRadius: '23px',
        marginLeft: '8px'
    },
    errorCounter: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        paddingLeft: '5px'
    },
    main: {
        backgroundImage: `url(${forgotPasswordBg})`,
        backgroundSize: '100%',
    },
    left: {
        height: '96%',
        backgroundColor: '#4271C9',
        color: '#fff',
        padding: '20px'
    },
    right: {
        height: '96%',
        backgroundColor: 'white',
        padding: '20px'
    },
    wrapper: {
        paddingTop: '5%',
        paddingLeft: '20%',
        paddingRight: '20%',
        height: '60%',
        paddingBottom: '9%',
        display: 'flex',
    },
    error: {
        border: '1px solid red'
    },
    errorMsg: {
        fontSize: '12px',
        color: 'red',
        textAlign: 'left',
        display: 'block',
        fontWeight: 'bold',
        margin: '10px',
        fontStyle: 'italic'
    },

})

const block = new BlockComponent<Props, S, SS>(
    {
        navigation: '',
        id: '123'
    }
)


const ForgotPasswordOTP = (props: IForgotOTP & RouteComponentProps) => {

    const classes = useStyles();
    const [otp_code, setOtp_code] = useState('');
    const [otp_token, setOtpToken] = useState('');
    const [error, setError] = useState('');
    const [full_phone_number, setFull_phone_number] = useState('');
    const [counter, setCounter] = useState(0);



    useEffect(() => {
        block.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ]
        runEngine.attachBuildingBlock(block, block.subScribedMessages);

        block.receive = async (from: string, message: Message) => {

            othervalues.navigation.navigate = props.history.push;
            if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
                setCounter(59);
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );

                setFull_phone_number(message.getData(
                    getName(MessageEnum.AuthTokenPhoneNumberMessage)
                ))
                othervalues.full_mobile_number = message.getData(
                    getName(MessageEnum.AuthTokenPhoneNumberMessage)
                )
                setOtpToken(message.getData(
                    getName(MessageEnum.AuthTokenDataMessage)
                ));
                othervalues.token = message.getData(getName(MessageEnum.AuthTokenDataMessage));
            }
            else if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {

                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );
                props.hideLoader();
                if (apiRequestCallId === othervalues.validateMessageId) {
                    var responseJson = message.getData(
                        getName(MessageEnum.RestAPIResponceSuccessMessage)
                    );
                    if (responseJson.errors || responseJson.error) {
                        setError('* Wrong OTP. Please enter correct OTP');
                        setOtp_code('');
                    } else {
                        const msg: Message = new Message(
                            getName(MessageEnum.NavigationNewPasswordMessage)
                        );
                        msg.addData(
                            getName(MessageEnum.AuthTokenDataMessage),
                            otp_token || othervalues.token
                        );

                        msg.addData(getName(MessageEnum.NavigationPropsMessage), othervalues);
                        block.send(msg);

                    }
                }
                else if (apiRequestCallId === othervalues.resendMessageId) {
                    var responseJson = message.getData(
                        getName(MessageEnum.RestAPIResponceSuccessMessage)
                    );
                    if (responseJson.errors || responseJson.error) {
                        setError('*something went wrong , please try again later');
                    }
                    else {
                        const msg: Message = new Message(
                            getName(MessageEnum.NavigationMobilePhoneOTPMessage)
                        );

                        msg.addData(
                            getName(MessageEnum.AuthTokenDataMessage),
                            responseJson.meta.token
                        );

                        msg.addData(getName(MessageEnum.NavigationPropsMessage), othervalues);

                        msg.addData(
                            getName(MessageEnum.AuthTokenEmailMessage),
                            ''
                        );

                        msg.addData(getName(MessageEnum.AuthTokenPhoneNumberMessage), responseJson.data.attributes.full_phone_number);

                        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

                        block.send(msg);
                    }
                }
            }
            //   }
        }
        return (() => {
            runEngine.unSubscribeFromMessages(block, block.subScribedMessages);
        })
    }, []);

    useEffect(() => {
        let timer: any = undefined;
        if (counter > 0) {
            timer = setInterval(() => setCounter(counter - 1), 1000);
        }
        return () => clearInterval(timer);
    }, [counter])


    const handleSubmit: React.FormEventHandler<HTMLFormElement | HTMLAnchorElement> = (event) => {
        event.preventDefault();
        // setError('')
        if (!otp_code) {
            setError('*otp code is required');
            return;
        }
        validateOTP();
    }

    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement
        | HTMLSelectElement> = (e) => {
            setOtp_code(e.target.value);
        }

    const validateOTP = () => {

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        othervalues.validateMessageId = requestMessage.messageId;
        const body = {
            data: {
                token: otp_token,
                otp_code
            }
        }
        const httpHeaders = {
            "Content-Type": "application/json"
        };
        props.showLoader();
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            'forgot_password/otp_confirmation');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(httpHeaders));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    const resendOTP = () => {
        if (!othervalues.full_mobile_number) {
            props.history.push('/ForgotPassword');
            return;
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        othervalues.resendMessageId = requestMessage.messageId;
        block.setState({
            city: requestMessage.messageId
        })
        const body = {
            data: {
                attributes: {
                    full_phone_number: othervalues.full_mobile_number
                }
            }
        }
        const httpHeaders = {
            "Content-Type": "application/json"
        };
        props.showLoader();

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            'forgot_password/otp');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(httpHeaders));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    return (
        <div className="login-widget">
                <div className="login">
                    <div className="login-left">
                        <h3>Welcome to <br /> Nursing Experts</h3>
                        <img src={forgotPasswordImage} alt="" />
                    </div>
                    <div className="login-right" style={{ padding: '20px' }}>
                        <div className="login-logo"><img src={forgotPasswordLogo} /> </div>
                        <div className="login-info">
                            <h3 className="login-right-heading">OTP</h3>
                            <div className="login-sub-heading">Enter OTP we sent you on your Mobile No.</div>
                            <form className={classes.form} onSubmit={handleSubmit}>
                                        <input
                                            type='text'
                                            placeholder="Enter OTP"
                                            className={`${classes.input}  ${(error ? classes.error : '')}`}
                                            name="otp_code"
                                            onChange={handleChange}
                                            value={otp_code}
                                        />
                                        <div className={classes.errorCounter}>
                                            {error ? <span style={{ color: 'red', fontSize: '12px' }}>{error}</span> : <div></div>}
                                            {counter > 0 ? <span style={{ color: 'blue', fontSize: '12px', cursor: 'pointer' }}>
                                                Resend OTP in 00:{counter}
                                            </span> :
                                                <span style={{ color: 'purple', fontSize: '12px', textDecoration: 'underline', cursor: 'pointer' }} onClick={resendOTP}>
                                                    Resend OTP
                                                </span>
                                            }
                                        </div>

                                        <button type={"submit"} className={classes.button} >
                                            <span className={classes.alignbtn}>
                                                <span className={classes.btnheader}>Confirm</span>
                                                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='28px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
                                            </span>
                                        </button>
                                    </form>
                            <div>
                                <span
                                    onClick={() => { window.location.href = '/' }}
                                ><p className={classes.link}><img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        // <div className={classes.main}>
        //     <Grid container className={`${classes.wrapper} registWrapper`}>
        //         <Grid item xs={12} md={3}>
        //             <div className={`${classes.left} registLeft`}>
        //                 <div className="fgtPswrdOTPImg">
        //                     <h3 style={{ textAlign: 'center' }}>Welcome to <br /> Nursing Experts</h3>
        //                     <img src={forgotPasswordImage} alt="" />
        //                 </div>
        //             </div>
        //         </Grid>
        //         <Grid item xs={12} md={9} >
        //             <div className={`${classes.right} registRight`} >
        //                 <div className="login-logo"><img src={forgotPasswordLogo} /> </div>
        //                 <div style={{ textAlign: "center", marginTop: "20%", marginBottom: "20%" }} >
        //                     <h1>OTP</h1>
        //                     <span className={classes.header}>Enter OTP we sent you on your Mobile No.</span>
        //                     <Grid container>
        //                         <Grid md={2}></Grid>
        //                         <Grid md={8} xs={12}>
        //                             <form className={classes.form} onSubmit={handleSubmit}>
        //                                 <input
        //                                     type='text'
        //                                     placeholder="Enter OTP"
        //                                     className={`${classes.input}  ${(error ? classes.error : '')}`}
        //                                     name="otp_code"
        //                                     onChange={handleChange}
        //                                     value={otp_code}
        //                                 />
        //                                 <div className={classes.errorCounter}>
        //                                     {error ? <span style={{ color: 'red', fontSize: '12px' }}>{error}</span> : <div></div>}
        //                                     {counter > 0 ? <span style={{ color: 'blue', fontSize: '12px', cursor: 'pointer' }}>
        //                                         Resend OTP in 00:{counter}
        //                                     </span> :
        //                                         <span style={{ color: 'purple', fontSize: '12px', textDecoration: 'underline', cursor: 'pointer' }} onClick={resendOTP}>
        //                                             Resend OTP
        //                                         </span>
        //                                     }
        //                                 </div>

        //                                 <button type={"submit"} className={classes.button} >
        //                                     <span className={classes.alignbtn}>
        //                                         <span className={classes.btnheader}>Confirm</span>
        //                                         <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='white' height='20px'><path d="M22 12l-4-4v3H3v2h15v3z"></path></svg>
        //                                     </span>
        //                                 </button>
        //                             </form>
        //                             <div>
        //                                 <span
        //                                     onClick={() => { props.history.push('/') }}
        //                                 ><p className={classes.link}><img style={{ marginRight: '10px', width: '23px' }} src={leftArrow} alt="" />Back to Home Page</p></span>
        //                             </div>
        //                         </Grid>
        //                         <Grid md={2}></Grid>
        //                     </Grid>
        //                 </div>
        //             </div>
        //         </Grid>
        //     </Grid>
        // </div>
    )
}


export default withLoader((withRouter)(ForgotPasswordOTP));