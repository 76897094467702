// App.js - WEB
import React, { Component } from "react";
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../blocks/ContentManagement/src/HomeScreen.web";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Notifications from "../../blocks/Notifications/src/Notifications";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ZoomIntegration24 from "../../blocks/ZoomIntegration24/src/ZoomIntegration24";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SingleDeviceLogin from "../../blocks/SingleDeviceLogin/src/SingleDeviceLogin";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import CarouselDisplay from "../../blocks/CarouselDisplay/src/CarouselDisplay";
import Scoring from "../../blocks/Scoring/src/Scoring";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Videos4 from "../../blocks/Videos4/src/Videos4";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountSetPassword from "../../blocks/email-account-registration/src/EmailAccountSetPassword.web";
import EmailAccountWelcomeUser from "../../blocks/email-account-registration/src/EmailAccountWelcomeUser.web";
import Payments from "../../blocks/Payments/src/Payments";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import ChangePassword from "../../blocks/forgot-password/src/ChangePassword.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import PasswordChanged from "../../blocks/forgot-password/src/PasswordChanged.web";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import PrivacySettings from "../../blocks/PrivacySettings/src/PrivacySettings";
import Button from '@material-ui/core/Button';
import Testimonials from "../../blocks/ContentManagement/src/Testimonials.web";
import SuccessStory from "../../blocks/ContentManagement/src/SuccessStory.web";

// src/packages/blocks/contactus/src/Testinomial.web.tsx
import './styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import HeaderProfile from "../../components/src/HeaderProfile.web";
// import ScoreCard from "../../components/src/scoreCard.web";
import MenuIcon from '@material-ui/icons/Menu';
// web routing
import RouteConfig from "./route.config";

const routeMap = {
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ZoomIntegration24: {
    component: ZoomIntegration24,
    path: "/ZoomIntegration24"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SingleDeviceLogin: {
    component: SingleDeviceLogin,
    path: "/SingleDeviceLogin"
  },
  ProductDescription: {
    component: ProductDescription,
    path: "/ProductDescription"
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay"
  },
  Scoring: {
    component: Scoring,
    path: "/Scoring"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Videos4: {
    component: Videos4,
    path: "/Videos4"
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: "/OrderDetailView"
  },
  QuestionBank: {
    component: QuestionBank,
    path: "/QuestionBank"
  },
  PushNotifications: {
    component: PushNotifications,
    path: "/PushNotifications"
  },
  ApiIntegration8: {
    component: ApiIntegration8,
    path: "/ApiIntegration8"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailAccountSetPassword: {
    component: EmailAccountSetPassword,
    path: "/EmailAccountSetPassword"
  },
  EmailAccountWelcomeUser: {
    component: EmailAccountWelcomeUser,
    path: "/EmailAccountWelcomeUser"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: "/AssessmentTest"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: "/PaymentAdmin"
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: "/VideoLibrary"
  },
  // core:{
  //  component:core,
  // path:"/core"},
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  ChangePassword: {
    component: ChangePassword,
    path: "/ChangePassword"
  },
  PasswordChanged: {
    component: PasswordChanged,
    path: "/PasswordChanged"
  },
  TimeTracker: {
    component: TimeTracker,
    path: "/TimeTracker"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: "/PrivacySettings"
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Testimonials:{
    component:Testimonials,
    path:'/Testimonials'
  },
  SuccessStory:{
    component:SuccessStory,
    path:'/SucessStory'
  },
  // ScoreCard: {
  //   component: ScoreCard,
  //   path: '/ScoreCard'
  // }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
     showMenu: true
    }
  }
  toggleMenu = () => {
    this.setState({showMenu: !this.state.showMenu})
    // this.showMenu = !this.showMenu
  }
  componentDidMount() {
    if(window.innerWidth<1024){
      this.setState({showMenu:false})
    }
  }
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const pathname = window.location.pathname
    console.log('pathname', pathname);
    const mainContentNoPadding = (
    // pathname == '/login' ||
    // pathname == '/signup' ||
    pathname == '/forgotPassword' ||
    pathname == '/ForgotPassword' ||
    pathname == 'ForgotPasswordOTP' ||
    pathname == '/NewPassword' ||
    pathname == '/password-changed' ||
    pathname == '/ChangePassword' ||
    pathname =='/change-password'||
    pathname == '/welcome-user'||
    pathname =='/set-password') ? 'padding-zero' : (pathname == '/') ? 'main-content-no-pdding' : 'main-content-no-pdding';
    const classMainContent = `main-content ${mainContentNoPadding}`
    return (
      <>
        {/* {pathname !== '/login' &&
          pathname !== '/EmailAccountRegistration' &&
          pathname !== '/EmailAccountWelcomeUser' &&
          pathname !== '/ForgotPassword' &&
          pathname !== '/ForgotPasswordOTP' &&
          pathname !== '/NewPassword' &&
          pathname !== '/password-changed' &&
          pathname !== '/ChangePassword' &&
          pathname !=='/set-password'  &&
          <header>
            <div className="header">
              <div className="header-left">
                <a><img src={require('./logo.png')} /></a>
              </div>
              {this.state.showMenu &&
              <div className={"header-middle"} >
                <TopNav />
                {window.innerWidth<1024 &&
              <>
              <HeaderProfile />
              <Button className="btn-number" variant="contained" color="primary">+1800 212 1719</Button>
              </>
              }
              </div>
              }
              <div className="header-right">
              {window.innerWidth>1024 &&
              <>
              <HeaderProfile />
              <Button className="btn-number" variant="contained" color="primary">+1800 212 1719</Button>
              </>
              }
              {window.innerWidth<1024 &&
              <span className="btn-toggle" onClick={()=>this.toggleMenu()}><MenuIcon /></span>
              }
              </div>
            </div>
          </header>
        } */}
        <div className={classMainContent}>
          <RouteConfig></RouteConfig>
          {/* {WebRoutesGenerator({ routeMap })}
          <ModalContainer /> */}
        </div>
      </>
    );
  }
}

export default App;