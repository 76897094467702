import { makeStyles, Button, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Footer from '../../../components/src/Footer.web';
import { MapImage, FooterImage, headPhones, headPhonesBack, emailImg, emailImgBack, phoneImgBack, phoneImg, cardBack } from "./assets";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import ContactusController from './ContactusController.web';
import { withRouter, RouteComponentProps } from "react-router";
import { withLoader } from '../../../components/src/withLoader.Web';
import { Height } from '@material-ui/icons';
import MyFooterWeb from '../../ContentManagement/src/MyFooter.web';
import HeaderWeb from '../../ContentManagement/src/Header.web';

const useStyles = makeStyles({
    numbers: {
        position: 'relative',
        padding: '0 10px',
        fontSize: '36px',
        fontWeight: 'bold',
        display: 'inline-block',
        "&:after": {
            content: "no-close-quote",
            height: '22px',
            width: '19px',
            borderRadius: '20px',
            background: '#d0e6ff',
            display: 'inline-block',
            position: 'absolute',
            left: '12px',
            top: '15px',
            zIndex: "-1",
        },
    },
    numbers2: {
        position: 'relative',
        padding: '0 10px',
        fontSize: '36px',
        display: 'inline-block',
        fontWeight: 'bold',
        "&:after": {
            content: "no-close-quote",
            height: '50px',
            width: '50px',
            borderRadius: '25px',
            background: '#d0e6ff',
            display: 'inline-block',
            position: 'absolute',
            left: '-2px',
            top: '-6px',
            zIndex: "-1",
        },
    },
    counters: {
        position: 'relative',
        display: 'inline-block',
        paddingRight: '50px',
        cursor: 'pointer',
        color: '#3895ff',
        fontWeight: 'bold',
        "&:before": {
            content: 'no-close-quote',
            position: "absolute",
            bottom: '-16px',
            left: '54px',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            borderTop: '15px solid #0CBB80',
            display: 'inline-block,'
        },
    },
    location: {
        display: 'inline-block',
        paddingRight: '50px',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: '#454545'
    },
    locations: {
        margin: '10px',
        display: 'flex',
        justifyContent: "",
        fontSize: '36px',
        fontWeight: 'bold',
    },
    block: {
        margin: '20px'
    },
    imageMap: {
        backgroundImage: `url(${MapImage})`
    },
    footer: {
        backgroundImage: `url(${FooterImage})`
    },
    callCover: {
        paddingTop: '20px',
        paddingLeft: '20px',
        height: '250px'
    },
    emailCover: {

        paddingTop: '20px',
        paddingLeft: '20px',
        height: '250px',
        ['@media (max-width:767px)']: {
            paddingTop: '30px'
        },
    },
    tollFreeCover: {

        paddingTop: '20px',
        paddingLeft: '20px',
        height: '250px',
        ['@media (max-width:767px)']: {
            paddingTop: '50px',
            paddingBottom: '20px',
        },
    },
    call: {
        backgroundColor: '#3895ff',
        color: 'white',
        textAlign: 'center',
        height: '100%',
        width: '90%',
        transform: 'rotate(-5deg)',
        padding: '30px 10px',
        borderRadius: '8px',
        position: 'relative',
        boxShadow: '2px 3px 4px 4px #ccc'
    }, email: {
        backgroundColor: '#0CBB80',
        color: 'white',
        textAlign: 'center',
        height: '100%',
        width: '90%',
        transform: 'rotate(6deg)',
        padding: '30px 10px',
        borderRadius: '8px',
        position: 'relative',
        wordWrap: 'break-word',
        boxShadow: '2px 3px 4px 4px #ccc'

    }, tollFree: {
        backgroundColor: 'orange',
        color: 'white',
        textAlign: 'center',
        height: '100%',
        width: '90%',
        transform: 'rotate(-5deg)',
        padding: '30px 10px',
        borderRadius: '8px',
        position: 'relative',
        boxShadow: '2px 3px 4px 4px #ccc'
    }, tollFreeImage: {
        position: 'absolute',
        top: '-2px',
        right: '3px',
        width: '20px'
    },
    tollFreeImageBack: {
        position: 'absolute',
        top: '-19px',
        right: '-11px',
        width: '51px'
    },
    emailImg: {
        position: 'absolute',
        top: '88%',
        left: '90%',
        width: '20px'
    },
    emailImgBack: {
        position: 'absolute',
        width: '55px',
        top: '79%',
        left: '83%',
    },
    phoneImgBack: {
        position: 'absolute',
        top: '-14px',
        width: '51px',
        left: '-7px'
    },
    phoneImg: {
        position: 'absolute',
        top: '0px',
        left: '8px',
        width: '20px'
    },

    cardBack: {
        backgroundImage: `url(${cardBack})`
    }
});


interface IContact {
    showLoader: any,
    hideLoader: any
}

const ContactUs = (props: IContact & RouteComponentProps) => {
    document.body.scrollTop = 0;
    const { data, getContactUsData } = ContactusController(props.showLoader, props.hideLoader);

    const branches = data?.attributes?.branches || [];
    const [currentSelected, setCurrentSelected] = useState(0);

    useEffect(() => {
        getContactUsData();
    }, [])

    setInterval(()=>{
        getContactUsData();
    }, 30000)
    
    const classes = useStyles();
    return (
        <div className="top-gap">
            {/* <HeaderProfileWeb /> */}
            <HeaderWeb />
            <div className="section-heading w-800-center">
                <h3><span >Contact Us</span></h3>
            </div>
            <div className="container">
            <Grid container spacing={4} justifyContent="center" className="contact-cards">
                <Grid item xs={12} md={4}>
                    <div className={classes.cardBack}>
                        <div className={classes.callCover}>
                            <div className={classes.call}>
                                <img src={phoneImgBack} className={classes.phoneImgBack} />
                                <img src={phoneImg} className={classes.phoneImg} />
                                <div className="contact-card-info">
                                    <p>CALL</p>
                                    <p>{data?.attributes?.call}</p>
                                    {/* <p>{data?.attributes?.call}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={classes.cardBack}>

                        <div className={classes.emailCover}>
                            <div className={classes.email}>

                                <img src={emailImgBack} className={classes.emailImgBack} />
                                <img src={emailImg} className={classes.emailImg} />
                                <div className="contact-card-info email">
                                <p>EMAIL</p>
                                <p>{data?.attributes?.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={classes.cardBack}>
                        <div className={classes.tollFreeCover}>
                            <div className={classes.tollFree}>
                                <img src={headPhonesBack} className={classes.tollFreeImageBack} />
                                <img src={headPhones} className={classes.tollFreeImage} />
                                <div className="contact-card-info toll-free">
                                <p>TOLL FREE</p>
                                <p>NUMBER</p>
                                <p>{data?.attributes?.toll_free}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
                    <div className="location-list">
                                {branches.map((branch: any, index: number) =>
                                    <Grid key={index} item className="location-info">
                                        <div
                                            className={index === currentSelected ? classes.counters : classes.location}
                                            onClick={() => { setCurrentSelected(index) }}
                                            key={index}
                                        >

                                            <div className={classes.numbers}>
                                                {index + 1}
                                            </div>
                                            <span className="branch-name">{branch.branch_name}</span>
                                        </div>

                                    </Grid>
                                )}
                            </div>
                            <div className="add-details">
                        <span className="address-in" style={{ color: '#0CBB80', display: 'block' }}>ADDRESS</span>
                        <span className="address-info">{branches[currentSelected] ? branches[currentSelected].address : " "}</span>
                    </div>
                    <div className="add-details">
                        <span className="contact-num" style={{ color: '#0CBB80', display: 'block' }}>CONTACT NUMBER</span>
                        <span className="address-info">{branches[currentSelected] ? branches[currentSelected].contact_number : " "}</span>
                    </div>
            </div>
            <iframe
                src={`https://maps.google.com/maps?q=${branches[currentSelected]?.latitude},${branches[currentSelected]?.longitude}&z=15&output=embed`}

                height="400"
                width="100%"
                style={{ border: 0 }}>

            </iframe>
            <MyFooterWeb />
        </div>
    )
}

export default withLoader((withRouter)(ContactUs));