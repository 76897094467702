import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader : any;
  hideLoader : any;
  open?:boolean,
  anchorRef?:any,
  prevOpen?:any,
  location?:any,
  sessionData: any,
  notificationCount: number
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  home: any[];
  settings: any[];
  anchorEl: any;
  anchorElPrograms: any;
  isLogin: boolean;
  open: boolean;
  showMenu: boolean;
  showSubMenu: boolean;
  openDialog: boolean;
  sessionData: any;
  notificationCount: number;
  demoLinkObj: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  homeGetApiCallId: string = "";
  settingsGetApiCallId: string = "";
  aboutGetApiCallId: string = "";
  validateSessionGetApiCallId: string = "";
  home:any[];
  settings:any[];
  anchorEl: null;
  anchorElPrograms: null;
  isLogin: false;
  open: false;
  showMenu: true;
  showSubMenu: false;
  openDialog: false;
  sessionData: any;
  notificationCount: null;
  demoLinkObj: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      home: [],
      settings: [],
      anchorEl: null,
      anchorElPrograms: null,
      isLogin: false,
      open: false,
      showMenu: true,
      showSubMenu: false,
      openDialog: false,
      sessionData: {},
      notificationCount: 0,
      demoLinkObj: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.settingsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.settings = responseJson?.data;
            this.demoLinkObj = this.settings[10];
            this.setState({
              settings: this.settings,
              demoLinkObj: this.demoLinkObj
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired Log in again.", "");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.validateSessionGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success
            this.sessionData = responseJson.data;
            localStorage.setItem('notificationCount', this.sessionData?.attributes?.notification_count)
            this.setState({
              sessionData: this.sessionData,
              notificationCount: this.sessionData?.attributes?.notification_count
            });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              // this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } 
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getSettingsData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.settingsGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "settings/settings"
    );

    const header = {
      "Content-Type": 'aplication/json',
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goToLogin = () => {
    localStorage.clear()
    localStorage.removeItem('authToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('avatar');
    this.props.history.push('/login')
    this.props.showToast({type : 'error', message : "Logout Successfully"});
  }
  getValidateSession() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateSessionGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetValidateSession
    );

    const header = {
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetValidateSession
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
