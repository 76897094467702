import React from "react";
import { View, StyleSheet } from "react-native";
import { withRouter } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const menuList = [
  {
    name: 'Apply Now'
  },
  {
    name: 'Question Bank'
  },
  {
    name: 'Vacancies'
  }
]
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
const TopNav = ({ history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
  
    <Typography >
        <Link
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Programs
          <ArrowDropDownIcon />
        </Link>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className="dropdown-menu" autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose}>Class Room Course</MenuItem>
                    <MenuItem onClick={handleClose}>Online Course Packages</MenuItem>
                    <MenuItem onClick={handleClose}>Test Series Package</MenuItem>
                    <MenuItem onClick={handleClose}>Study Material</MenuItem>
                    <MenuItem onClick={handleClose}>NEET & B.SC Nursing Entrance</MenuItem>
                    <MenuItem onClick={handleClose}>Global Study Package</MenuItem>
                    <MenuItem onClick={handleClose}>Regular Students</MenuItem>
                    <MenuItem onClick={handleClose}>Digital Nursing Library</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      {menuList
        ? menuList.map((ele, ind) =>
          <Link key={ele.name} href="#" >{ele.name}</Link>
        ) : null
      }
    </Typography>
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly"
  }
});

export default withRouter(TopNav);
