//@ts-nocheck 
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import CourseController, {
  Props,
  configJSON
} from "./CourseController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import CustomButton from "../../../components/src/customButton.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import MyFooterWeb from "./MyFooter.web";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HeaderWeb from "./Header.web";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  }
]
const whyNursingExperts = [
  { name: 'Multiple Course Options' },
  { name: 'Highly Qualified Faculty' },
  { name: 'Study Materials' },
  { name: 'Placement Support' },
  { name: 'Online Exams' },
  { name: 'Proved Performance' }
]
class Course extends CourseController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount() {
    document.body.scrollTop = 0;
    this.getCourse()
  }

  getId = (url: any) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  openEnrollForm = () => {
    this.setState({ openDialog: true });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  }
  getPaymentType = (e) => {
    this.setState({ paymentType: e.target.value })
  }
  getBatch = (e) => {
    this.setState({ batch: e.target.value })
  }
  getVimeo = (videoUrl) => {
    // var script = document.createElement("script");
    // // Add script content
    // script.src = "https://player.vimeo.com/api/player.js";
    // // Append
    // document.head.appendChild(script);
    this.isMyScriptLoaded()
    let video_url = videoUrl.split('/').pop();
    return video_url
  }
  isMyScriptLoaded = () => {
    let url = "https://player.vimeo.com/api/player.js";
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      if (scripts[i].src == url) return true;
    }
    return false;
  }
  render() {
    // const courses = this.state?.section_2?.attributes?.tiles
    const { course } = this.state
    const courseData = course?.attributes
    const demoVideos = courseData?.demo_videos
    const batchList = courseData?.batches?.data
    // const EnrolledAccounts = this.state?.course?.attributes?.enrolled_accounts
    // const loginId = localStorage.getItem('loginId')
    // let enrolledId;
    // if(EnrolledAccounts) {
    //   EnrolledAccounts.find((ele, ind) => {
    //     if(ele == loginId) {
    //       enrolledId = ele
    //     }
    //   })
    // }
    // let section1 = this.state.home?["section1"];
    const { navigation } = this.props;
    const _this = this;
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: (demoVideos?.length >= 2) ? 2 : 1,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="container">
          <div className="section-heading">
            <h3><span>{courseData?.title}</span></h3>
          </div>
          <Grid container spacing={3}>
            <Grid item sm={12} md={5}>
              <div className="enroll-now-widget">
                <img src={courseData?.course_image} alt="" />
                {
                  (!!courseData?.course_image && this.state.enrolledId) ?
                    <button className="btn-curve btn-enroll btn-navlink"><NavLink to="/my-courses">My Courses</NavLink></button> :
                    <button className="btn-curve btn-enroll btn-navlink" onClick={() => this.openEnrollForm()}><NavLink to="#">Enroll Now</NavLink></button>
                }
              </div>
              <div className="widget-details mt-40">
                {/* <div className="group-widget">
                  <h4>Courses</h4>
                  <div className="group-value">{courseData?.courses_detail}</div>
                </div>
                <div className="group-widget">
                  <h4>Qualification </h4>
                  <div className="group-value">{courseData?.qualification}</div>
                </div> */}
                <div className="group-widget-double">
                  {
                    courseData?.timing &&
                    <div className="group-widget group-widget-auto">
                      <h4>Timing</h4>
                      <div className="group-value">
                        <div dangerouslySetInnerHTML={{ __html: courseData?.timing }}></div>
                      </div>
                    </div>
                  }
                  <div className="group-widget group-widget-auto">
                    <h4>Duration</h4>
                    <div className="group-value">{courseData?.duration}</div>
                  </div>
                </div>
                <div className="group-widget">
                  <h4>Syllabus Covered</h4>
                  <div className="group-value">
                   
                    {courseData?.syllabus_covered?.split(/[\r\n|-]+/).map((i: any, key: any) => {
                      return <div className="num-gap" key={key}>{i}</div>
                    })}

                    {/* <div dangerouslySetInnerHTML={{__html: courseData?.syllabus_covered}}></div> */}
                    {/* <div>{testSeries?.syllabus_covered}</div> */}
                    {/* <ol>
                      <li>All Nursing Subjects including Nursing Reasearch & Management</li>
                      <li>Nclex - RN(Saunders)</li>
                      <li>Nclex - RN(Mosby)</li>
                      <li>Non - Nursing Subjects like Math, Reasoning, English, Aptitude, G.K and Hindi</li>
                      <li>All Previous years Exam Papers</li>
                    </ol> */}
                  </div>
                </div>
                <div className="group-widget">
                  <h4>Fee</h4>
                  <div className="group-value">
                    {courseData?.fee}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item sm={12} md={7}>
              <div className="course-list">
                <h3>
                  {courseData?.short_description?.split(/[\r\n|-]+/).map((i: any, key: any) => {
                    return <div className="num-gap" key={key}>{i}</div>
                  })}
                </h3>
                <ul>
                  {courseData?.description?.split(/[\r\n|-]+/)?.map((i: any, key: any) => {
                    return <li key={key}>
                      <div className="list-info">
                        <span className="list-image">
                          <img src={require('./Combined-Shape.png')} alt="" />
                        </span>
                        <span className="list-desc">
                          {i.replace('-', '')}
                        </span>
                      </div>
                    </li>;
                  })}
                </ul>
              </div>
            </Grid>
          </Grid>
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <span className="radios">
                  {/* <h3>Please select your batch preference</h3> */}
                  <span className="dialog-header">Please select your batch preference</span>
                  <RadioGroup className="" aria-label="batch" name="batch" value={this.state.batch} onChange={(e) => this.getBatch(e)}>
                    {
                      (batchList && batchList.length > 0)
                        ? batchList.map((ele: any, ind: number) =>
                          <FormControlLabel key={ele?.id} value={ele?.id} control={<Radio />} label={ele?.attributes?.display_text} />
                        )
                        : <span className="text-center no-batches"><b>No bacthes found</b></span>
                    }
                    {/* <FormControlLabel value="0" control={<Radio />} label="Evening batch" /> */}
                  </RadioGroup>
                </span>
                {/* <div className="form-grp">
                <h3>Please select Payment type</h3>
                  <select name="" id="" className="form-select" onChange={(e)=>this.getPaymentType(e)}>
                    <option value="one_time_payment">One time payment</option>
                    <option value="installment_payment">Installment payment</option>
                    <option value="offline_payment">Offline payment</option>
                  </select>
                </div> */}
                {
                  this.state.showError &&
                  <span className="error">
                    Please select batch
                  </span>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-footer">
              {
                batchList && batchList.length > 0 &&
                <Button variant="contained" color="primary" className="btn-primary btn-curve" onClick={() => this.enrollNow()} >
                  CONFIRM
                </Button>
              }
            </DialogActions>
          </Dialog>
        </div>
        {(demoVideos && demoVideos.length > 0) &&
          <div className="new-courses">
            <div className="container">
              <div className="section-heading">
                <h3><span>Demo Videos</span></h3>
              </div>
              <Slider className="demo-slider-widget" {...settings}>
                {demoVideos.map((ele: any) =>
                    <div className="demo-slider" key={ele.id}>
                      <div className="demo-content">
                        {
                          ele?.video_url.includes('youtub') &&
                          <iframe width="420" height="315"
                            src={`https://www.youtube.com/embed/` + this.getId(ele?.video_url)} allowFullscreen="true">
                          </iframe>
                          // : <video className="demo-video" src={ele?.video_url} controls loop></video>
                        }
                        {/* {
                        !this.getId(ele?.video_url) && <video className="demo-video" src={ele?.video_url} controls loop></video>
                      } */}
                        {
                          ele?.video_url.includes('vimeo') &&
                          <iframe src={`https://player.vimeo.com/video/` + this.getVimeo(ele?.video_url)} width="420" height="315" allowFullScreen={true}></iframe>
                        }
                        {/* <video className="demo-video" src={ele?.video_url} controls loop></video> */}
                        {/* <video className="success-story-video" src={ele?.video_url} controls autoPlay={true} loop></video> */}
                        {/* <span className="play">
                        <img src={require('./play.png')} alt="" />
                        <h3>{ele.title}</h3>
                      </span> */}
                      </div>
                    </div>)  
                }
              </Slider>
            </div>
          </div>
        }
        <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default Course;
const CourseToast = withSnackBar(Course);
const CourseWithLoader = withLoader(CourseToast);
export default withRouter(CourseWithLoader);