import React from "react";
import { makeStyles,Grid } from "@material-ui/core";
import {forgotPasswordBg} from "./assets";
import CustomButton from "../../../components/src/customButton.web";

let othervalues = {
    messageId: ''
}

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
}

export interface SS {
    id: any;
}

const useStyles = makeStyles({
    wrapper: {
        margin:'20%',
        backgroundColor: 'white',
        padding: '10%',
        borderRadius:'8px'
    },
    header:{
       color:'#0CBB80',
       fontWeight: 'bold',
       marginTop: 0
    },
    button: {
        width: '70%',
        color: 'white',
        border: 'none',
        padding: '10px 18px',
        backgroundColor:'#2E81F7',
    },
    span: {
        width: '80%',
        padding: '10px 18px',
        borderRadius: '15px',
        backgroundColor: '#1890ff',
    },
    loginWrapper:{
        display:'block',
      textAlign:'center',
      paddingTop:'30px'  
    },
    loginBtn: {
        width: '60%',
        backgroundColor: '#2E81F7',
        padding: '15px',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer'
    },
    main: {
        backgroundImage: `url(${forgotPasswordBg})`,
        backgroundSize: '100%',
        position:'fixed',
        height:'100%',
        width:'100%'
      },
      wrap:{
          backgroundColor:"white",
          padding:'5%',
          textAlign:'center',
          position:'absolute',
          top:'30%',
          borderRadius: '15px'
      }       
})

const PasswordChanged = (props: any) => {

    const classes = useStyles();

    return (
        <div className={classes.main}>
            <Grid container>
            <Grid xs={1} md={4}></Grid>
                <Grid xs={9} md={4}>
                <div className={classes.wrap} >
                <>
                    <h1 className={classes.header}>Congratulations..!!</h1>
                    <span style={{color:'grey'}}>Password Changed Successfully</span>
                    <span className={classes.loginWrapper}>
                        <CustomButton
                            className="btn-primary btn-login2 btn-change"
                            onClick={() => window.location.href='/login'}
                            label="GO TO LOGIN PAGE"
                        />
                </span>
                </>
                </div>
                </Grid>
                <Grid xs={1} md={4}></Grid>
                </Grid>
        </div>
    )
}


export default PasswordChanged;