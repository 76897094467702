//@ts-nocheck 
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import ContactUsController, {
  Props,
  configJSON
} from "./ContactUsController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import Footer from "../../../components/src/Footer.web";
import Slider from "react-slick";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import CustomButton from "../../../components/src/customButton.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import MyFooterWeb from "./MyFooter.web";
import { withRouter } from "react-router";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import HeaderWeb from "./Header.web";
import { MapImage, FooterImage, headPhones, headPhonesBack, emailImg, emailImgBack, phoneImgBack, phoneImg, cardBack } from "./assets";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  }
]
const whyNursingExperts = [
  { name: 'Multiple Course Options' },
  { name: 'Highly Qualified Faculty' },
  { name: 'Study Materials' },
  { name: 'Placement Support' },
  { name: 'Online Exams' },
  { name: 'Proved Performance' }
]
class ContactUs extends ContactUsController {

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount() {
    this.scrollTop()
    this.getContactUsDetails()
  }
  scrollTop = () => {
    document.body.scrollTop = 0;
  }
  formatDate = (createdDate: any) => {
    let today = new Date(createdDate);
    let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
    return date
  }
  getJoinMeeting = () => {
    this.setState({ joinMeeting: true })
  }
  render() {
    const { contactData } = this.state
    const { navigation } = this.props;
    const _this = this;
    const settings = {
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="section-heading w-800-center">
          <h3><span >Contact Us</span></h3>
        </div>
        <div className="container">
          <Grid container spacing={4} justifyContent="center" className="contact-cards">
            <Grid item xs={12} md={4}>
              <div className="cardBack">
                <div className="callCover">
                  <div className="call">
                    <img src={phoneImgBack} className="phoneImgBack" />
                    <img src={phoneImg} className="phoneImg" />
                    <div className="contact-card-info">
                      <p>CALL</p>
                      {/* <p>{call}</p> */}
                      <p>{contactData?.attributes?.call}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="cardBack">

                <div className="emailCover">
                  <div className="email">

                    <img src={emailImgBack} className="emailImgBack" />
                    <img src={emailImg} className="emailImg" />
                    <div className="contact-card-info email">
                      <p>EMAIL</p>
                      {/* <p>{email}</p> */}
                      <p>{contactData?.attributes?.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="cardBack">
                <div className="tollFreeCover">
                  <div className="tollFree">
                    <img src={headPhonesBack} className="tollFreeImageBack" />
                    <img src={headPhones} className="tollFreeImage" />
                    <div className="contact-card-info toll-free">
                      <p>TOLL FREE</p>
                      <p>NUMBER</p>
                      {/* <p>{tollFree}</p> */}
                      <p>{contactData?.attributes?.toll_free}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="location-list">
            {(contactData?.attributes?.branches && contactData?.attributes?.branches.length > 0)
              ? contactData?.attributes?.branches.map((branch: any, index: number) =>
                <Grid key={index} item className="location-info">
                  <div
                    className={index === this.state.currentSelected ? 'counters' : 'location'}
                    onClick={() => this.getCurrentLocation(branch, index)}
                    key={index}
                  >

                    <div className="numbers">
                      {index + 1}
                    </div>
                    <span className="branch-name">{branch.branch_name}</span>
                  </div>

                </Grid>
              ) : <h3 className="text-center w-100">No records found</h3>
            }
          </div>
          {
            (contactData?.attributes?.branches && contactData?.attributes?.branches.length > 0) &&
            <>
              <div className="add-details">
                <span className="address-in" style={{ color: '#0CBB80', display: 'block' }}>ADDRESS</span>
                <span className="address-info">{this.state.address ? this.state.address : contactData?.attributes?.branches[0]?.address}</span>
              </div>
              <div className="add-details">
                <span className="contact-num" style={{ color: '#0CBB80', display: 'block' }}>CONTACT NUMBER</span>
                <span className="address-info">{this.state.contactNumber ? this.state.contactNumber : contactData?.attributes?.branches[0].contact_number}</span>
              </div>
            </>
          }
        </div>
        {
          (contactData?.attributes?.branches && contactData?.attributes?.branches.length > 0) &&
          <>
            <iframe
              src={`https://maps.google.com/maps?q=${this.state.latitude ? this.state.latitude : contactData?.attributes?.branches[0]?.latitude},${this.state.longitude ? this.state.longitude : contactData?.attributes?.branches[0]?.longitude}&z=15&output=embed`}

              height="400"
              width="100%"
              style={{ border: 0 }}>

            </iframe>
          </>
        }
        <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default MyCourses;
const ContactUsToast = withSnackBar(ContactUs);
const ContactUsWithLoader = withLoader(ContactUsToast);
export default withRouter(ContactUsWithLoader);