import React, { useState, useEffect } from 'react';
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { BlockComponent } from "../../../framework/src/BlockComponent";
let apiEmailLoginCallId = ""
let othervalues = {
  navigation: {
    navigate: ''
  },
  messageId: '',
  hideLoader: () => { }
}


export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  city: Array<string>;
  searchState: string;
  messageId: string,
}

export interface SS {
  id: any;
}

export interface IProp {
  error: any;
  errorMsg: any;
  setError: any;
  setErrorMsg: any
}

const EmailAccountSetPasswordController = ({ error, errorMsg, setError, setErrorMsg }: IProp) => {

  const block = new BlockComponent<Props, S, SS>(
    {
      navigation: '',
      id: '123'
    }
  )

  useEffect(() => {

    block.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ]
    runEngine.attachBuildingBlock(block, block.subScribedMessages);

    block.receive = async (from: string, message: Message) => {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === othervalues.messageId) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        othervalues.hideLoader();
        if (responseJson.error || responseJson.errors) {
          let errObj: { [key: string]: string } = {};
          if (responseJson.errors) {
            errObj.password = responseJson.errors[0]?.profile || 'Invalid Password';
            errObj.confirm_password = responseJson.errors[0]?.profile || 'Invalid Password';
            setErrorMsg(responseJson.errors[0].profile);
            setError(errObj);
            // window.alert(responseJson.errors[0].profile);
          } else {
            setError(errObj);
            errObj.password = 'password not match';
            errObj.confirm_password = 'password not match';
            setErrorMsg("something went wrong");
          }
        } else {
          const msg: Message = new Message(
          getName(MessageEnum.AccountSetPasswordSuccess)
          );
          msg.addData(getName(MessageEnum.NavigationTargetMessage), "welcome-user");
          msg.addData(getName(MessageEnum.NavigationPropsMessage), othervalues);
          msg.addData(getName(MessageEnum.AccountUserDetails), responseJson.data.attributes.first_name);
          block.send(msg);
        }
        if(responseJson.data && responseJson.data.attributes) {
          doMobileLogIn(JSON.stringify(responseJson))
          // localStorage.removeItem('userPassword');
        }
      } 
      if (apiRequestCallId === apiEmailLoginCallId) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
         const userName = responseJson?.data?.attributes?.full_name;
         const avatar = responseJson?.data?.attributes?.avatar;
         const authToken = responseJson?.meta?.token;
          localStorage.setItem("userName", userName);
          localStorage.setItem("avatar", avatar);
          localStorage.setItem("authToken", authToken);
          // this.saveLoggedInUserData(responseJson);
          // this.sendLoginSuccessMessage();
          // this.openInfoPage();
          // this.props.history.push({
          //   pathname: '/',
          // })
        } else {
          //Check Error Response
          // this.props.showToast({type : 'error', message : responseJson?.errors[0]?.failed_login});
          // this.parseApiErrorResponse(responseJson);
          // this.sendLoginFailMessage();
        }

        // this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }, [])

  const setPassword = async (password: string, navigateFunc: any, showLoader: any, hideLoader: any) => {


    othervalues.navigation.navigate = navigateFunc;
    othervalues.hideLoader = hideLoader;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    othervalues.messageId = requestMessage.messageId;
    const body =
    {
      data: {
        new_password: password
      }
    }
    const httpHeaders = {
      token: localStorage.getItem('authToken'),
      "Content-Type": "application/json"
    };
    showLoader();
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      'profile/set_password');
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(httpHeaders));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  const doMobileLogIn = async (responseData:any) => {
    let response = JSON.parse(responseData)
    // debugger
    // if (
    //   response.full_phone_number === null ||
    //   response.full_phone_number.length === 0 ||
    //   response.full_phone_number.length > 10 || 
    //   response.full_phone_number.length < 10 
    // ) {
    //   // this.setState({errorMessage: configJSON.errorMobileNotValid, errorMobile:true, classMobile:'form-control error-field'})
    //   // return false;
    // }
    // if (password === null || password.length === 0) {
    //   this.setState({errorMessage: configJSON.errorMobileNotValid, errorPassword:true, classPassword:'form-control error-field'})
    //   return false;
    // }

    const header = {
      "Content-Type": "application/json"
    };
    const getPassword = localStorage.getItem("userPassword")
    const attrs = {
      full_phone_number: response.data.attributes.full_phone_number,
      password: getPassword
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'login/login'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // return true;
    // const userName = response?.attributes?.full_name;
    // const avatar = response?.attributes?.avatar;
    // localStorage.setItem("userName", userName);
    // localStorage.setItem("avatar", avatar);
  }
  return { setPassword };
  
}


export default EmailAccountSetPasswordController;