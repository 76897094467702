// @ts-nocheck
import React from 'react';
import HeaderProfileWeb from '../../../components/src/HeaderProfile.web';
import TermsAndConditionController, { Props } from './TermsAndConditionController.web';
import { Grid, FormControlLabel, Radio, Button, withStyles } from '@material-ui/core';
import Footer from '../../../components/src/Footer.web';
import MyFooterWeb from './MyFooter.web';
import HeaderWeb from './Header.web';


const useStyles = () => ({
    numbers: {
        position: 'relative',
        padding: '0 10px',
        fontSize: '36px',
        fontWeight: 500,
        display: 'inline-block',
        textAlign: 'center',
        "&:after": {
            content: "no-close-quote",
            height: '50px',
            width: '50px',
            borderRadius: '25px',
            background: '#d0e6ff',
            display: 'inline-block',
            position: 'absolute',
            left: '6px',
            top: '-5px',
            zIndex: "-1",
        },
    },
    circle: {
        position: 'relative',
        padding: '0 10px',
        fontSize: '36px',
        fontWeight: 500,
        display: 'inline-block',
        textAlign: 'center',
        "&:after": {
            content: "no-close-quote",
            height: '20px',
            width: '20px',
            borderRadius: '25px',
            background: '#3895FF',
            display: 'inline-block',
            position: 'absolute',
            left: '0px',
            top: '18px',
            zIndex: "-1",
        }
    },
    block: {
        marginBottom: '10px',
        padding: '2%',
        borderStyle: 'dashed',
        borderWidth: '0px 0px 3px 0px',
        borderColor: '#f0f0f0'
    },
    btnHome: {
        backgroundColor: 'red',
        color: 'white'
    },
    btnFinish: {
        backgroundColor: '#3895FF',
        color: 'white'
    },
    answer: {
        backgroundColor: 'white',
        padding: '3%',
        border: '2px solid red',
        borderRadius: '8px',
        width: '100%'
    },
    container: {
        boxShadow: '2px 2px 2px 5px #f0f0f0',
        borderRadius: '8px'

    },
});


interface QuestionBank {
    classes: { [className in keyof typeof styles]: string }

}

const termsData = ["Website Disclaimer", "External Links Disclaimer", "Errors and Omissions Disclaimer", "Logos and Trademarks Disclaimer", "Contact Us"];

class TermsAndCondition extends TermsAndConditionController {

    constructor(props: Props & QuestionBank) {
        super(props);
    }

    async componentDidMount() {
        document.body.scrollTop = 0;
        this.getSettingsData();
    }

    render() {

        const classes: any = this.props.classes;
        return (
            <>
                {/* <HeaderProfileWeb /> */}
                <HeaderWeb />
                <Grid container>

                    <Grid item md={12} style={{ marginTop: '5%', textAlign: 'center' }}>
                        <div className="section-heading">
                            <h3><span>{"Terms  & Conditions"}</span></h3>
                        </div>
                    </Grid>


                    <Grid container>
                        <Grid item md={2}></Grid>
                        <Grid item md={8}>
                            <Grid container>
                                {this.state.settingsList.map((data) =>
                                    <Grid md={12}>
                                        {/* <span className={classes.circle}> </span>
                                        <div style={{ color: 'grey', fontWeight: 900, paddingLeft: '40px' }}>
                                            <h3 style={{color:'black'}}>{data}</h3>
                                            <p>Payment made b way of online Payment Gateway (Debit/Credit Card/Net Banking) require
                                                no signature and are valid only on payment relisation. Valid proof of transaction a will
                                                be required from the depositor.
                                            </p>

                                            <p>The fee once paid will not be refunded nor adjusted n any other course or with fee
                                                for any other student
                                            </p>
                                        </div>
                                        <span className={classes.circle}> </span>
                                        <div style={{ color: 'grey', fontWeight: 900, paddingLeft: '40px' }}>
                                            <p>Nursing Experts reserves the right to make any alteration in its programmee/fee without any prior </p>
                                        </div> */}
                                        <div dangerouslySetInnerHTML={{ __html: data.attributes.description }}></div>
                                    </Grid>
                                )}

                            </Grid>
                        </Grid>
                        <Grid item md={1}></Grid>
                    </Grid>

                </Grid>

                <Grid>
                    <MyFooterWeb />
                </Grid>

            </>
        )
    }




}

export default withStyles(useStyles)(TermsAndCondition);
