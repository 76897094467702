import { makeStyles, Grid, Modal } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Footer from '../../../components/src/Footer.web';
import useSuccessStory from "./useSuccessStoryController.web";
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import MyFooterWeb from './MyFooter.web';
import HeaderWeb from './Header.web';


const useStyles = makeStyles({
    numbers: {
        position: 'relative',
        padding: '0 10px',
        fontSize: '36px',
        fontWeight: 500,
        display: 'inline-block',
        textAlign: 'center',
        "&:after": {
            content: "no-close-quote",
            height: '50px',
            width: '50px',
            borderRadius: '25px',
            background: '#d0e6ff',
            display: 'inline-block',
            position: 'absolute',
            left: '6px',
            top: '-5px',
            zIndex: "-1",
        },
    },
    counters: {
        position: 'relative',
        display: 'inline-block',
        paddingRight: '50px',
        cursor: 'pointer',
        color: 'purple',
        "&:before": {
            content: 'no-close-quote',
            position: "absolute",
            bottom: '-16px',
            left: '54px',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid green',
            display: 'inline-block,'
        },
    },
    location: {
        display: 'inline-block',
        paddingRight: '50px',
        cursor: 'pointer'
    },
    locations: {
        margin: '10px',
        display: 'flex',
        justifyContent: "",
        fontSize: '36px',
        fontWeight: 500,
        paddingLeft: '50px'
    },
    block: {
        paddingLeft: "50px",
        margin: '20px'
    },
    info: {
        //    justifyContent:'space-around',
        padding: '50px',
    },
    image: {
        position: 'relative',
        display: 'inline-block',
        padding: '2%',
        borderRadius: "8px",
        ['@media (max-width:767px)']: {
            padding: '0%'
        },
    },
    imageInfo: {
        position: 'absolute',
        top: '185px',
        color: 'white',
        fontSize: '14px',
        paddingLeft: '10px',
        fontWeight: 'bold',
    },
    p: {
        margin: '0px',
    },
    reponsive: {
        maxWidth: '100%',
        borderRadius: '8px',
        objectFit: 'cover',
    }
});

const SucessStory = (props: any) => {
    document.body.scrollTop = 0;
    const { data, getSuccessStoryData } = useSuccessStory();

    const [openModel, setOpenModel] = useState(false);
    const handleClose = () => {
        setOpenModel(!openModel);
    }

    useEffect(() => {
        getSuccessStoryData();
    }, [])

    const classes = useStyles();
    return (
        <div className="top-gap">
            {/* <HeaderProfileWeb /> */}
            <HeaderWeb />
                    <div className="section-heading">
                        <h3><span>Success Story</span></h3>
                    </div>
            <Grid container >
                <Grid item md={1}></Grid>
                <Grid item md={10} >
                    <Grid container >
                        { (data && data.length > 0) &&
                        data.map((info: any, index: number) =>
                            <Grid key={index} item md={4}>
                                <Modal
                                    open={openModel}
                                    onClose={handleClose}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className="modal-data"
                                >
                                    <video className="success-story-video" src={info.attributes.video_url} controls autoPlay={true} loop></video>
                                </Modal>
                                <div className={classes.image} onClick={handleClose}>
                                    <span >
                                        <img src={info.attributes.thumbnail_image} className={classes.reponsive} width={750} height={250} />
                                    </span>
                                    <div className={classes.imageInfo}>
                                        <p className={classes.p}>{info.attributes.bio}</p>
                                        <p className={classes.p}>{info.attributes.name}</p>
                                    </div>
                                    <div className="play-story">
                                        <img src={require("./play.png")} alt="" />
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
            <Grid >
                <MyFooterWeb />
            </Grid>
        </div>
    )
}

export default SucessStory;