import React from 'react';

const baseURL=`https://${window.location.href.split("/")[2]}`;
const webQualificationApiEndPoint='/profile/qualifications';
const validationApiEndPoint='/profile/validations';
const webStateApiEndPoint='/profile/locations';
const webCoursesApiEndPoint='/profile/courses';
const registerApiEndPoint='/account/signup';


interface IQualification{
    id: string,
    type:string
    attributes:{
        name: string
    }
}

interface IRegist{
    full_name:string,
    full_phone_number:string,
    email:string,
    state:string,
    city:string,
    qualification_id: string,
    interested_course_id: string
    other_qualification: string,
    other_interested_course: string
}


export const getQualifications = async(setQualifications:
    React.Dispatch<React.SetStateAction<Array<IQualification>>>)=>{
    try{
        const response=await 
         fetch(`${baseURL}${webQualificationApiEndPoint}`);
        let responseJson = await response.json();
        setQualifications(responseJson.data);
 }catch(err){
    }
}


export const getAvailableCourses= async(setCourses:
    React.Dispatch<React.SetStateAction<Array<IQualification>>>)=>{

  try{
   const response = await 
   fetch(`${baseURL}${webCoursesApiEndPoint}`);
   const responseJSON = await response.json();
   setCourses(responseJSON.data);
   
  }catch(err){
  }
}


export const getStates = async(setStates:
    React.Dispatch<React.SetStateAction<Array<string>>>)=>{
  
    try{
        const response = await 
        fetch(`${baseURL}${webStateApiEndPoint}`);
        const responseJSON = await response.json();
        setStates(responseJSON.data);
        
       }catch(err){
       }
}


export const getCity = async(state:string,setCity:
    React.Dispatch<React.SetStateAction<Array<string>>>)=>{
  
    try{
        const response = await 
        fetch(`${baseURL}/profile/locations?state=${state}`);
        const responseJSON = await response.json();
        setCity(responseJSON.data);
        
       }catch(err){
       }
}

export const validator = async(setEmailRegex:
    React.Dispatch<React.SetStateAction<null | RegExp>>)=>{
    const response=await fetch(`${baseURL}${validationApiEndPoint}`)
    const  responseJSON = await response.json();
    setEmailRegex(new RegExp(responseJSON.data[0].email_validation_regexp));
}

export const registerAccount = async(data:IRegist,navigate:(loc:string)=>void)=>{
    let error='';
    const registerData = {
        data:{
        type: "sms_account",
        attributes: {
            full_phone_number: "91"+ data.full_phone_number,
            full_name: data.full_name,
            email: data.email,
            state: data.state,
            city: data.city,
            device_id: '',
            account_qualification_attributes: {
                qualification_id: data.qualification_id
            },
            account_interested_course_attributes: {
                interested_course_id: data.interested_course_id
            },
            other_qualification: data.other_qualification,
            other_interested_course: data.other_interested_course
        }
    }
    }
    return fetch(`${baseURL}${registerApiEndPoint}`, {
        method: "POST",
        body: JSON.stringify(registerData),
        headers: {
            "Content-type": "application/json"
        }
    })
    .then(response => response.json())
    .then(json =>{
        // if(json.data) {
        //     localStorage.setItem('userName', json.data.attributes.full_name);
        //     localStorage.setItem('email', json.data.attributes.email);
        //     localStorage.setItem('phoneNumber', json.data.attributes.phone_number);
        //     localStorage.setItem('loginId', json.data.id);
        // }
        if(json.errors){
        //   window.alert(json.errors[0].full_phone_number || "Please provide correct details");   
          error=json.errors[0] || "Please provide correct details";  
        }else if(json.error){
            // window.alert('Something went wrong.Please try again later');
            error='Something went wrong.Please try again later';
        }
        else{
        window.localStorage.setItem('authToken', json.meta.token);
        navigate('set-password')
        };
        return error;
    })
    .catch((err)=>{
        error='Something went wrong.Please try again later';
        return error;
    })
    
}
