export const MapImage= require("../assets/image_img.png");
export const FooterImage =require('../assets/image_img 28.png');
export const headPhones= require('../assets/image_img 20.png');
export const headPhonesBack= require('../assets/image_img 19.png');
export const emailImg= require('../assets/image_img 16.png');
export const emailImgBack= require('../assets/image_img 15.png');
export const phoneImg= require('../assets/image_img 12.png');
export const phoneImgBack= require('../assets/image_img 11.png');
export const cardBack= require('../assets/image_img 46.png');
export const emailcard=require('../assets/image_img 10.png');



