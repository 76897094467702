// @ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import SubjectAndTopicController, {
  Props,
  configJSON
} from "./SubjectAndTopicController.web"
import Footer from "../../../components/src/Footer.web"
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { withRouter } from "react-router";
import withSnackBar from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MyFooterWeb from "./MyFooter.web";
import HeaderWeb from "./Header.web";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import Dropzone from "react-dropzone";
// import UploadService from "./upload-files.service";
// import "bootstrap/dist/css/bootstrap.min.css";
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  },
]
const ourPrograms = [
  {
    image: 'expert.png',
    title: 'PREPERATION FOR GOVERNMENT JOBS',
    color: '#ff0000',
  },
  {
    image: 'expert.png',
    title: 'INTERVIEW PREPARATION',
    color: '#0000ff'
  },
  {
    image: 'expert.png',
    title: 'TEST SERIES (AS PER REAL BASE EXAM)',
    color: '#00ff00'
  },
]
const squareCircles = [
  { title: 'IELTS', bgColor: '#2E81F7', url: 'ielts' },
  { title: 'NCLEX RN', bgColor: '#0CBB80', url: 'nclex-rn' },
  { title: 'OET', bgColor: '#FFAE3B', url: 'oet' },
]
class SubjectAndTopic extends SubjectAndTopicController {
  // selectedFiles = [];

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    this.state = {
    };
  }

  async componentDidMount() {
    document.body.scrollTop = 0;
    this.getSubjectsAndTopics()
  }
  render() {
    const { navigation } = this.props;
    const _this = this;
    const { subjectsAndTopicsList } = this.state;
    const subjects = subjectsAndTopicsList
    const myColors = ['#ACCFF7', '#9649CB', '#FFAE3B', '#EB585E', '#4B35C4', '#2E81F7', '#607684', '#0DBD7F' ];
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="container">
          <div className="section-heading">
            <h3><span>Subject & Topic</span></h3>
          </div>
          <div className="subjects-topics">
            <ul className="square-course">
              {(subjects && subjects.length > 0) &&
                subjects.map((ele: any, ind) =>
                  <li onClick={()=>this.selectSubject(ele, ind)} key={ele?.id} className={(ele?.id == this.state.selectedSubjectId) ? 'active' : ''}>
                    <div className="square-content">
                      <span className='square-color-circle' style={{ backgroundColor: myColors[ind], boxShadow: '0 0 0 20px ' + myColors[ind] + '80' }}></span>
                      <h3>{ele?.attributes?.name}</h3>
                    </div>
                  </li>
                )}
            </ul>
            <div className="topic-section">
              <div className="square-content">
                <span className='square-color-circle' style={{ backgroundColor: myColors[this.state.subjectIndex], boxShadow: '0 0 0 20px ' + myColors[this.state.subjectIndex] + '80' }}></span>
                <div className="topic-section__header">
                  <h3>{this.state.selectedSubject}</h3>
                </div>
                <div className="topics">
                  {(subjects && subjects.length > 0) &&
                    subjects.map((ele: any) =>
                      <div key={ele.id} className="topic">
                        {(ele.id == this.state.selectedSubjectId) &&
                          // <FormGroup column>
                          //   {(ele?.attributes?.topics.data && ele?.attributes?.topics.data.length > 0) &&
                          //     ele?.attributes?.topics.data.map((ele, ind) =>
                          //       <FormControlLabel
                          //         control={
                          //           <Radio
                          //             // checked={state.checkedB}
                          //             onChange={(e)=>this.changeTopic(e, ele.attributes.id)}
                          //             name={ele?.attributes?.name}
                          //             color="primary"
                          //           />
                          //         }
                          //         label={ele?.attributes?.name}
                          //       />
                          //     )

                          //   }
                          // </FormGroup>
                          <RadioGroup 
                          onChange={(e)=>this.changeTopic(e, ele.attributes.id)}
                                      // name={this.state.selectedSubject + this.state.selectedSubjectId}
                                      >
                            {(ele?.attributes?.topics.data && ele?.attributes?.topics.data.length > 0) &&
                              ele?.attributes?.topics.data.map((ele, ind) =>
                                <FormControlLabel
                                key={ind}
                                value = {ele?.id}
                                  control={
                                    <Radio
                                      // checked={state.checkedB}
                                      
                                    />
                                  }
                                  label={ele?.attributes?.name}
                                />
                              )

                            }
                          </RadioGroup>
                        }
                      </div>
                    )}
                </div>
                <div className="topic-section__footer">
                  {/* <p>*Note: Exam duration is 25 minutes. Please do not refresh the page while exam is ongoing. Do not click back at any point of time during the exam.</p> */}
                  <Button className="btn btn-primary btn-curve" variant="contained" color="primary" onClick={()=>this.props.history.push('question-bank/'+this.state.selectedTopicId)} disabled={!this.state.isChecked} >START PRACTICE</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
// const styles = StyleSheet.create({
//   scrollView: {
//     flexGrow: 1,
//     height: Platform.OS === "web" ? '100vh' : 'auto',
//     backgroundColor: "#F5FCFF"
//   },
//   container: {
//     flex: 1
//   },
//   welcome: {
//     fontSize: 20,
//     textAlign: "center",
//     fontWeight: "bold",
//     color: "white"
//   },
//   instructions: {
//     textAlign: "center",
//     color: "#6200EE",
//     marginBottom: 5,
//     fontWeight: 'bold',
//     fontSize: 16,

//     padding: 10
//   },
//   button: {
//     backgroundColor: '#6200EE',
//     padding: 15,
//     color: 'white',
//     fontSize: 16,
//     fontWeight: 'bold'
//   },
//   header: {
//     backgroundColor: '#6200EE',
//     padding: 15,
//     color: 'white',
//     fontSize: 16,
//     fontWeight: 'bold'
//   },
//   item: {
//     backgroundColor: '#00000000',
//     padding: 18,
//     color: '#6200EE',
//     fontSize: 16,
//     fontWeight: 'normal'
//   }
// });
// Customizable Area End
// export default GlobalStudyCourse;
const SubjectAndTopicToast = withSnackBar(SubjectAndTopic);
const SubjectAndTopicWithLoader = withLoader(SubjectAndTopicToast);
export default withRouter(SubjectAndTopicWithLoader);
