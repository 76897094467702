import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

import { RouterProps } from "react-router";
export type Props = RouterProps & {
  navigation: any;
  id: string;
  // Customizable Area Start
  showToast: any;
  showLoader : any;
  hideLoader : any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  recordsList: any[];
  vacancyDetails: any;
  myCourses: any[];
  openModel: boolean;
  iframeUrl: string;
  filteredRecord: any;
  childrenArray: any;
  childrenArray2: any;
  recordingsArray: any;
  breadcrumbArray: any;
  arrayData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HomeScreenController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  meetingRecordingsGetApiCallId: string = "";
  myCoursesGetApiCallId: string = "";
  recordsList: any[];
  vacancyDetails: any;
  myCourses: any[];
  openModel: boolean;
  iframeUrl: string;
  filteredRecord: any;
  childrenArray: any;
  childrenArray2: any;
  recordingsArray: any;
  breadcrumbArray: any;
  arrayData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.SessionResponseMessage),
      // getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      recordsList: [],
      vacancyDetails: {},
      myCourses: [],
      openModel: false,
      iframeUrl: '',
      filteredRecord: [],
      childrenArray: [],
      childrenArray2: [],
      recordingsArray: [],
      breadcrumbArray: [],
      arrayData: []
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.meetingRecordingsGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            
            this.recordsList = responseJson.data;
            this.setState({
              recordsList: this.recordsList
            });
            
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token > 0 
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            }
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 
            ) {
              this.props.showToast({type : 'error', message : 'Meetings not found'});
              this.props.history.push('/my-courses')
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.myCoursesGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data
          ) {
            //On User Profile Success

            this.myCourses = responseJson.data;
            let _id = window.location.pathname.split('/').pop()
            this.recordsList = this.myCourses.filter(ele => ele?.attributes?.id == _id)
            this.setState({
              myCourses: this.myCourses,
              recordsList: this.recordsList,
            }, ()=>{
              this.renderData()
            });
            this.props.hideLoader();
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.props.showToast({type : 'error', message : 'Session expired login again.'});
              this.props.history.push('/login')
              localStorage.clear()
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getMeetingRecordings() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.meetingRecordingsGetApiCallId = requestMessage.messageId;
    let _id = window.location.pathname.split('/').pop()
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetMeetingRecordings + _id + '/meeting_recordings'
    );

    const header = {
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetMeetingRecordings
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getMyCourses() {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.myCoursesGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetMyCourses
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetMyCourses,
      token: localStorage.getItem('authToken')
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetMyCourses
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOpen = (url: any) => {
    this.setState({openModel:true, iframeUrl: url});
  };
  handleClose = (url: any) => {
    this.setState({openModel:false});
  };

  renderData = () => {

    if(this.state.myCourses && this.state.myCourses.length>0) {
      let filteredRecord = this.state.myCourses.filter(ele => ele.id == window.location.pathname.split('/').pop())
      let arrayData = filteredRecord[0]?.attributes?.course?.data?.attributes?.recordings
      let childrenArray = filteredRecord[0]?.attributes?.course?.data?.attributes?.recordings
      let recordingsArray = filteredRecord[0]?.attributes?.course?.data?.attributes?.recording_folders?.data?.attributes?.recordings?.data
      let brdcrmbObj = {
          title: 'Home',
          unit_data: filteredRecord[0]?.attributes?.course?.data?.attributes?.recordings
      }
      this.setState({
      filteredRecord: filteredRecord,
      arrayData: arrayData,
      childrenArray: childrenArray,
      childrenArray2: childrenArray,
      recordingsArray: recordingsArray,
      breadcrumbArray: [brdcrmbObj]
    })
    }
  }
  // Customizable Area End
  
}
