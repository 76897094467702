//@ts-nocheck 
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import RecordedVideosController, {
  Props,
  configJSON
} from "./RecordedVideosController.web";
import Footer from "../../../components/src/Footer.web";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HeaderProfileWeb from "../../../components/src/HeaderProfile.web";
import MyFooterWeb from "./MyFooter.web";
import { NavLink } from 'react-router-dom'
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import HeaderWeb from "./Header.web";
import VideocamIcon from '@material-ui/icons/Videocam';
import Modal from '@material-ui/core/Modal';
import { EditAttributes } from "@material-ui/icons";
import RecordedData from "./RecordedData.web";
import HomeIcon from '@material-ui/icons/Home';
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});
const scoreCardList = [
  {
      img: 'course-study.jpeg',
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "PASS",
      soredMarks: 70,
      actualMarks: 100
  },
  {
      courseName: "Fundamentals of Nursing",
      courseType: "Nutrition",
      courseDate: "07/06/2021 - 2022",
      courseStatus: "FAIL",
      soredMarks: 20,
      actualMarks: 100
  },
]
const jsonArr = [
  {"children":["New1","New2","New3","New4"]},
  {"children1":
    ["New11","New12","New13","New14"],"children2":
    ["New21","New22","New23","New24"]},
  {"children2":
    ["New21","New22","New23","New24"]}
];
class RecordedVideos extends RecordedVideosController {

  static instance: RecordedVideos;

  constructor(props: Props) {
    super(props);
    // HomeScreen.instance = this;
    // this.state = {
    //   courses: []
    // }
  }
  async componentDidMount () {
    document.body.scrollTop = 0;
    // this.getMeetingRecordings()
    this.getMyCourses()
  }
  getIst = (startDate:any) => {
    var s = new Date(startDate).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
    return s
  }
  getDuration = (startDate:any, endDate:any) => {
    var _endDate:any = new Date(endDate);
    var _startDate:any = new Date(startDate);
    var seconds = Math.floor((_endDate - _startDate)/1000);
    var minutes = Math.floor(seconds/60);
    var hours = Math.floor(minutes/60);
    var days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
    var result = hours + 'h ' + minutes + 'm ' + seconds + 's'
    return result
  }
  getRecord = (videoUrl) => {
    window.open(videoUrl, '_blank');
  }
  getVimeo = (videoUrl) => {
   let video_url =  videoUrl.split('/').pop();
    return video_url
  }


	callRecFunc = (obj) => {
		obj.children.forEach(o => {
			if(o != undefined && o instanceof Array){
				callRecFunc(o);
			}
		});
	}

  selectFolder = (ele) => {
    let breadcrumbArry: any[] = this.state.breadcrumbArray
    let childrenArray;
    let arrayData;
    let recordingsArray;
    if((ele && ele.unit_data && ele.unit_data.length>0) ||
       (ele && ele.chapter_data && ele.chapter_data.length>0) ||
       (ele && ele.recordings_data && ele.recordings_data.data && ele.recordings_data.data.length>0)
    ) {
      breadcrumbArry.push(ele)
      arrayData = ele.unit_data || ele.chapter_data || ele.recordings_data.data;
      this.setState({
        // childrenArray: childrenArray,
        breadcrumbArray: breadcrumbArry
      })
    }
    if(ele && ele.recordings_data && ele.recordings_data.data && ele.recordings_data.data.length>0) {
      // breadcrumbArry.push(ele)
      recordingsArray = ele.recordings_data.data;
      this.setState({
        // recordingsArray: recordingsArray,
        breadcrumbArray: breadcrumbArry
      })
    }
    this.setState({
      arrayData: arrayData,
      breadcrumbArray: breadcrumbArry,
      recordingsArray: recordingsArray
    })
  }
  selectBrcmb = (e, ele, ind) => {
    if(this.state.breadcrumbArray.length-1 === ind) {
      e.preventDefault();
    } else {
      var arraySlice = this.state.breadcrumbArray.slice(0, ind+1);
      let childrenArray;
      let arrayData;
      let recordingsArray;
      if((ele && ele.unit_data) || (ele && ele.chapter_data) || (ele && ele.recordings_data && ele.recordings_data.data)) {
        childrenArray = ele.unit_data || ele.chapter_data || ele.recordings_data.data;
        arrayData = ele.unit_data || ele.chapter_data || ele.recordings_data.data;
      }
      this.setState({
        arrayData: arrayData,
        childrenArray: childrenArray,
        recordingsArray: recordingsArray,
        breadcrumbArray: arraySlice
      })
    }
  }
  render() {
    const recordsList = this.state.recordsList[0]?.attributes?.course?.data?.attributes?.recordings?.data;
    const myCourses = this.state.myCourses;
    const { navigation } = this.props;
    const _this = this;
    return (
      <div className="top-gap">
        {/* <HeaderProfileWeb /> */}
        <HeaderWeb />
        <div className="container">
          <div className="section-heading">
            <h3><span>Recorded Videos</span></h3>
          </div>
          <div className="recorded-videos">
          <ul className="breadcrumb-data">
            {
              (this.state.breadcrumbArray.length>0 && this.state.childrenArray2 ) &&
              this.state.breadcrumbArray.map((ele, ind)=> 
              <li key={ind} onClick={(e)=>this.selectBrcmb(e, ele, ind)}>
                { ind == 0 && <HomeIcon />}
                { ind !== 0 && <span className="divider">/</span>}
                {ele?.title} 
              </li>
              
              )
            }
        </ul>
            {/* { (jsonArr && jsonArr.length>0)
            ? jsonArr.map((ele, ind)=>
            {
              return <div key={ind}>return</div>
            })
            : <div>no data</div>
            } */}
            {/* {
              ((this.state.childrenArray && this.state.childrenArray.length > 0) || (this.state.recordingsArray && this.state.recordingsArray.length>0))
              ? <RecordedData brdcrumbData={this.state.breadcrumbArray} choseFolder={this.selectFolder} data={this.state.childrenArray} recordingsData={this.state.recordingsArray}/>
              : <h3 className="text-center w-100">No Records found</h3>
            } */}
            {
              (this.state.arrayData && this.state.arrayData.length > 0)
              ? <RecordedData brdcrumbData={this.state.breadcrumbArray} choseFolder={this.selectFolder} data={this.state.arrayData} recordingsData={this.state.recordingsArray} />
              : <h3 className="text-center w-100">No Records found</h3>
            }
            <div>
                {/* {ele?.attributes?.topic + '/' + ele?.attributes?.meeting_details?.password} */}
              {/* <ul>
            {(recordsList && recordsList.length>0)
                  ?  recordsList.map((ele, ind) => 
                      <li key={ind} onClick={()=>this.handleOpen(ele?.attributes?.recording_url)}>
                          <NavLink to="#" className="record-content">
                            <span className="record-img">
                              {
                                ele?.attributes?.thumbnail_url 
                                ? <img src={ele?.attributes?.thumbnail_url} />
                                : <VideocamIcon />
                              }
                            </span>
                            <div className="record-info">
                                <h3>{ele?.attributes?.title}</h3>
                                <div className="record-date">Recorded on: {ele?.attributes?.display_recorded_on}</div>
                                <span className="record-time"><AccessTimeIcon /> {ele?.attributes?.display_duration}</span>
                            </div>
                          </NavLink>
                        </li>
                        ) : <h3 className="text-center w-100">No Records found</h3>
                      }
                      </ul> */}
            </div>
          </div>
        </div>
        <Modal
              open={this.state.openModel}
              onClose={this.handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              className="modal-data"
            >
              <div >
                {
                  this.state.iframeUrl.includes('vimeo')  
                    ?  <iframe className="success-story-iframe" src={`https://player.vimeo.com/video/` + this.getVimeo(this.state.iframeUrl) + '?autoplay=1'}  allow='autoplay' width="420" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen="true"></iframe>
                    : <iframe className="success-story-iframe" width="420" height="315"
                    src={`https://www.youtube.com/embed/${this.state.iframeUrl.split('=').pop()}?autoplay=1`} allow='autoplay' allowFullScreen="true">
                  </iframe>
                }

                          
                      </div>
            </Modal>
          <MyFooterWeb />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default RecordedVideos;
const RecordedVideosToast = withSnackBar(RecordedVideos);
const RecordedVideosWithLoader = withLoader(RecordedVideosToast);
export default withRouter(RecordedVideosWithLoader);