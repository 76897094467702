// @ts-nocheck
import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Button
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import AlertBlock from '../../alert/src/AlertBlock';
import CustomTextItem from "../../../components/src/CustomTextItem";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../adapters/src/OtpToNewPasswordAdapter";
import HeaderController, {
  Props,
  configJSON
} from "./HeaderController.web";
// import './Home.css';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withRouter } from "react-router";
import withSnackBar  from "../../../components/src/withSnackBar.Web";
import { withLoader } from "../../../components/src/withLoader.Web";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

// interface Props {
//   navigation: any;
//   id: string;
//   // Customizable Area Start
//   // Customizable Area End
// }

// Customizable Area Start
// interface S { }

// interface SS { }
const courses = [
  {
    courseType: 'Classroom Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#66CEA9',
    count: 38
  },
  {
    courseType: 'Online Courses',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#2E81F7',
    count: 39
  },
  {
    courseType: 'Digital Nursing Library',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#EB585E',
    count: 40
  },
  {
    courseType: 'Question Bank',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#9649CB',
    count: 38
  },
  {
    courseType: 'Nursing officer',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#FFAE3B',
    count: 20
  },
  {
    courseType: 'Entrance Exam',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#4B35C4',
    count: 10
  },
  {
    courseType: 'Global Study Package',
    courseDescription: 'updated & revisd exam pattern study material covering syllabus of all Nursing and non nursing subjects as per the exam requirements.',
    color: '#607684',
    count: 5
  },
]
const whyNursingExperts = [
  { name: 'Multiple Course Options' },
  { name: 'Highly Qualified Faculty' },
  { name: 'Study Materials' },
  { name: 'Placement Support' },
  { name: 'Online Exams' },
  { name: 'Proved Performance' }
]
// type MyProps = { 
//   open?:boolean,
//   anchorRef?:any,
//   prevOpen?:any,
//   location?:any
// };

// const menuList = [
//   {
//     name: 'Apply Now',
//     url: 'apply-now'
//   },
//   {
//     name: 'Question Bank',
//     url: 'question-bank'
//   },
//   {
//     name: 'Vacancies',
//     url: 'vacancy'
//   }
// ]
class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorRef: React.createRef(),
      prevOpen: React.createRef(),
      openDialog: false
    }
  }
  componentDidMount() {
    if(localStorage.getItem('authToken')) {
      this.getValidateSession()
      setInterval(()=>{
        this.getValidateSession()
      }, 30000)
    }
    this.getSettingsData()
    this.setState({showMenu:true})
    if(window.innerWidth<1024){
      this.setState({showMenu:false})
    }
  }
  setAnchorEl = (event:any) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  setAnchorElPrograms = (event:any) => {
    this.setState({ anchorElPrograms: event.currentTarget })
  }
  handleClick = (event:any) => {
    this.setAnchorEl(event);
  };
  handlePrograms = (event:any) => {
    this.setAnchorElPrograms(event);
  };
  handleClose = (event:any) => {
    this.setState({ anchorEl: false })
  };
  handleClosePrograms = (event:any) => {
    this.setState({ anchorElPrograms: false })
  };

  logOut = () => {
    this.setState({openDialog: true});
  }

  toggleMenu = () => {
    this.setState({showMenu: !this.state.showMenu})
  }
  toggleSubMenu = () => {
    this.setState({showSubMenu: !this.state.showSubMenu})
  }
  // handleClickOpen = () => {
  //   this.setState({openDialog: true});
  // };

  handleCloseDialog = () => {
    this.setState({openDialog: false});
  };
  render() {
    const sessionData = this.state.sessionData
    const settingsData = this.state?.settings
    const demo_live = this.state?.demoLinkObj?.attributes?.demo_live
    let isDemo = ((demo_live && demo_live !== null) || (sessionData && sessionData?.attributes && sessionData?.attributes?.demo_live !== null));
    const userName = localStorage.getItem("userName");
    const avatar = localStorage.getItem("avatar");
    // const userName = sessionData?.attributes?.full_name;
    // const avatar = sessionData?.attributes?.avatar;
    if(!localStorage.getItem('liveClass')) {
      localStorage.setItem('liveClass', sessionData?.attributes?.live_class)
    }
    const liveClass = localStorage.getItem('liveClass')
    let settings = [];
    settings = JSON.parse(localStorage.getItem("settings"));
    let tollFreeData;
    let tollFree;
    if(settingsData && settingsData.length > 0) {
      for(let i = 0; i<=settingsData.length; i++) {
        if(settingsData[i]?.attributes?.name == 'Toll Free Number') {
          tollFree = settingsData[i].attributes?.description
        }
      }
    }
    let classes = this.state.showSubMenu ? 'open' : '';
    return (
      <>
 <header>
    <div className="header"> 
       <div className="header-left" onClick={() => this.props.history.push('/')}>
        <a><img src={require('./logo.png')} /></a>
      </div>
      {this.state.showMenu &&
        <div className={"header-middle"} >
        <div className = "menu-widget">
        {window.innerWidth<1024 &&
          <NavLink to={`tel:`+tollFree} className="btn-number1" variant="contained" color="primary">{tollFree}</NavLink>
        }
        <div className="sub-menu-widget">
          <a className="submenu-link" onClick={()=>this.toggleSubMenu()}>Programs <ArrowDropDownIcon /></a>
          <ul className={classes}>
          <li><NavLink to="/classroom-courses">Class Room Course</NavLink></li>
          <li onClick={()=>this.props.history.push('/course/online-courses')}><a>Online Course Packages</a></li>
          <li onClick={()=>this.props.history.push('/course/test-series-package')}><a>Test Series Package</a></li>
          <li onClick={()=>this.props.history.push('/study-material')}><a>Study Material</a></li>
          <li><NavLink to="./course/neet-bsc">NEET & B.SC Nursing Entrance</NavLink></li>
          <li><NavLink to="/global-study-package">Global Study Package</NavLink></li>
          <li><NavLink to="/digital-nursing-library">Digital Nursing Library</NavLink></li>
          <li><NavLink to="/previous-year-question-paper">Previous Year Question Papers</NavLink></li>
          </ul>
        </div>
        <NavLink to={'/our-programs'} className="programs-web" aria-controls="simple-menu" aria-haspopup="true">
        <span onMouseOver={this.handlePrograms} >Programs</span>
          <ArrowDropDownIcon />
        </NavLink>
        <Menu className="programs-menu dropdown-menu"
          id="simple-menu"
          anchorEl={this.state.anchorElPrograms}
          keepMounted
          open={Boolean(this.state.anchorElPrograms)}
          onClose={this.handleClosePrograms}
        >
          <MenuItem onClick={this.handleClose}><NavLink to="/classroom-courses">Class Room Course</NavLink></MenuItem>
          <MenuItem onClick={()=>this.props.history.push('/course/online-courses')}>Online Course Packages</MenuItem>
          <MenuItem onClick={()=>this.props.history.push('/course/test-series-package')}>Test Series Package</MenuItem>
          <MenuItem onClick={()=>this.props.history.push('/study-material')}>Study Material</MenuItem>
          <MenuItem onClick={this.handleClosePrograms}><NavLink to="/course/neet-bsc">NEET & B.SC Nursing Entrance</NavLink></MenuItem>
          <MenuItem onClick={this.handleClosePrograms}><NavLink to="/global-study-package">Global Study Package</NavLink></MenuItem>
          <MenuItem onClick={this.handleClosePrograms}><NavLink to="/digital-nursing-library">Digital Nursing Library</NavLink></MenuItem>
          <MenuItem onClick={this.handleClosePrograms}><NavLink to="/previous-year-question-paper">Previous Year Question Papers</NavLink></MenuItem>
        </Menu>
        {/* {menuList
          ? menuList.map((ele, ind) =>
            <Link key={ele.name} href={ele.url} >{ele.name}</Link>
          ) : null
        } */}
        <Link onClick={() => this.props.history.push('/apply-now')}>Apply Now</Link>
        {!localStorage.getItem('userName')  
        ? <Link onClick={() => this.props.history.push('/login')}>Question Bank</Link>
        : <Link onClick={() => this.props.history.push('/subjects-topics')}>Question Bank</Link>
        }

        <Link onClick={() => this.props.history.push('/vacancies')}>Vacancies</Link>
      </div>
        </div>
      }
    <div className="header-right">
    {window.innerWidth>1024 && 
    <>
        {!localStorage.getItem('userName')  ?
        <>
          <Button disabled={!isDemo} className="btn-curve btn-login live-btn" variant="contained" aria-controls="Live" aria-haspopup="true" onClick={() => this.props.history.push('/zoom/'+(sessionData?.attributes?.demo_live ? (sessionData?.attributes?.demo_live?.meeting_id_zoom+'/'+sessionData?.attributes?.demo_live?.password) : (demo_live?.meeting_id_zoom+'/'+ demo_live?.password)))}>
            LIVE</Button>
          <Button className="btn-curve btn-login" variant="outlined" aria-controls="login" aria-haspopup="true" onClick={event => this.props.history.push('/login')}>
            Login</Button> 
        </> :
          <>
          <Button disabled={!isDemo} className="btn-curve btn-login live-btn" variant="contained" aria-controls="Live" aria-haspopup="true" onClick={() => this.props.history.push('/zoom/'+(sessionData?.attributes?.demo_live ? (sessionData?.attributes?.demo_live?.meeting_id_zoom+'/'+sessionData?.attributes?.demo_live?.password) : (demo_live?.meeting_id_zoom+'/'+ demo_live?.password)))}>
            LIVE</Button>
            <Button className={(sessionData?.attributes?.live_class) ? "btn-profile active" : "btn-profile"} title={userName} variant="contained" aria-controls="simple-menu1" aria-haspopup="true" onClick={this.handleClick}>
            {
                avatar && avatar != 'null'
                ? <img src={avatar} />
                : <img src={require('./default-user.png')} />
              }
              <span className="user-name" >{userName}</span>
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="simple-menu1"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
              className="profile-dropdown"
            >
              <MenuItem><NavLink to={'/profile'}>Edit Profile</NavLink></MenuItem>
              <MenuItem><NavLink to={'/change-password'}>Change Password</NavLink></MenuItem>
              <MenuItem><NavLink to={'/my-courses'}>My Courses 
              {sessionData?.attributes?.live_class &&
                <span className="live">LIVE</span>
              }
              </NavLink></MenuItem>
              <MenuItem><NavLink to={'/score-card'}>My Scorecard </NavLink></MenuItem>
              <MenuItem onClick={() => this.logOut()}>Logout</MenuItem>
            </Menu>
            <Dialog
              open={this.state.openDialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <span className="dialog-heading">Are you Sure you want to Logout?</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-footer">
                <Button variant="contained" color="primary" className="btn-cancel btn-curve" onClick={this.handleCloseDialog} color="primary">
                  CANCEL
                </Button>
                <Button variant="contained" color="secondary" className="btn-logout btn-curve" onClick={this.goToLogin} color="primary" autoFocus>
                  LOGOUT
                </Button>
              </DialogActions>
            </Dialog>
          </>
        }
         {/* <Button className="btn-number" variant="contained" color="primary">{(localStorage.getItem('toll-free')) ? localStorage.getItem('toll-free') : '+1800 212 1710'}</Button> */}
         <a className="notifications-count" onClick={() => (this.state.notificationCount>0) ? this.props.history.push('/notifications') : ''}>
           <span className="notifications-num">{(this.state.notificationCount>0) ? this.state.notificationCount : 0}</span>
            <NotificationsNoneIcon />
         </a>
         <Button className="btn-number btn-no-mobile" variant="contained" color="primary">
          {tollFree}
         </Button>
         </>
         }
         {window.innerWidth<1024 &&
         <>
         {!localStorage.getItem('userName')  ?
         <>
         <Button disabled={!isDemo} className="btn-curve btn-login live-btn" variant="contained" aria-controls="Live" aria-haspopup="true" onClick={() => this.props.history.push('/zoom/'+(sessionData?.attributes?.demo_live ? (sessionData?.attributes?.demo_live?.meeting_id_zoom+'/'+sessionData?.attributes?.demo_live?.password) : (demo_live?.meeting_id_zoom+'/'+ demo_live?.password)))}>
            LIVE</Button>
          <Button className="btn-curve btn-white-outline mr-10 btn-login-mobile" variant="outlined" aria-controls="login" aria-haspopup="true" onClick={event => this.props.history.push('/login')}>
            Login</Button>
         </>
            : <>
            <Button disabled={!isDemo} className="btn-curve btn-login live-btn" variant="contained" aria-controls="Live" aria-haspopup="true" onClick={() => this.props.history.push('/zoom/'+(sessionData?.attributes?.demo_live ? (sessionData?.attributes?.demo_live?.meeting_id_zoom+'/'+sessionData?.attributes?.demo_live?.password) : (demo_live?.meeting_id_zoom+'/'+ demo_live?.password)))}>
            LIVE</Button>
            <Button className={(sessionData?.attributes?.live_class) ? "btn-profile btn-mobile-profile active" : "btn-profile btn-mobile-profile"} title={userName} variant="contained" aria-controls="simple-menu1" aria-haspopup="true" onClick={this.handleClick}>
            {
                avatar && avatar != 'null'
                ? <img src={avatar} />
                : <img src={require('./default-user.png')} />
              }
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="simple-menu1"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
              className="profile-dropdown"
            >
              <MenuItem className="user_name">{userName}</MenuItem>
              <MenuItem><NavLink to={'/profile'}>Edit Profile</NavLink></MenuItem>
              <MenuItem><NavLink to={'/change-password'}>Change Password</NavLink></MenuItem>
              <MenuItem><NavLink to={'/my-courses'}>My Courses 
              {sessionData?.attributes?.live_class &&
                <span className="live">LIVE</span>
              }
              </NavLink></MenuItem>
              <MenuItem><NavLink to={'/score-card'}>My Scorecard </NavLink></MenuItem>
              <MenuItem onClick={() => this.logOut()}>Logout</MenuItem>
            </Menu>
            <Dialog
              open={this.state.openDialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <span className="dialog-heading">Are you Sure you want to Logout?</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialog-footer">
                <Button variant="contained" color="primary" className="btn-cancel btn-curve" onClick={this.handleCloseDialog} color="primary">
                  CANCEL
                </Button>
                <Button variant="contained" color="secondary" className="btn-logout btn-curve" onClick={this.goToLogin} color="primary" autoFocus>
                  LOGOUT
                </Button>
              </DialogActions>
            </Dialog>
            <a className="notifications-count notifications-count-mobile" onClick={() => (this.state.notificationCount>0) ? this.props.history.push('/notifications') : ''}>
           <span className="notifications-num">{(this.state.notificationCount>0) ? this.state.notificationCount : 0}</span>
            <NotificationsNoneIcon />
         </a>
          </>
          }
            <span className="btn-toggle" onClick={()=>this.toggleMenu()}><MenuIcon /></span>
         </>
              }
        </div>
        </div>
        </header>
      </>
    )
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
// export default Header;
const HeaderToast = withSnackBar(Header);
const HeaderWithLoader = withLoader(HeaderToast);
export default withRouter(HeaderWithLoader);